<template>
  <div>
    <div class="row" v-if="loading">
      <div
        v-if="loading"
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        align="center"
        style="min-height: calc(var(--vh, 1vh) * 80); display: flex"
      >
        <div style="margin: auto; text-align: center">
          <i style="font-size: 50px" class="fas fa-spinner fa-pulse"></i>

          <p style="font-size: 16px; font-weight: bold">Comprobando usuario</p>
        </div>
      </div>
    </div>

    <div class="row" v-else>
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="card" style="border-radius: 0px">
          <ul class="nav nav-pills" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                @click="ResumenLoad"
                class="nav-link"
                :class="activoPanel === 4 ? 'active' : ''"
                id="pills-ventas-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-ventas"
                type="button"
                role="tab"
                aria-controls="pills-ventas"
                aria-selected="true"
              >
                <i
                  style="font-size: 14px; margin-right: 5px"
                  class="fa fa-gauge text-success"
                ></i>
                Resumen
              </button>
            </li>
            <li v-if="isAdmin" class="nav-item" role="presentation">
              <button
                @click="LeadsLoad"
                class="nav-link"
                :class="activoPanel === 7 ? 'active' : ''"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                <i
                  style="font-size: 14px; margin-right: 5px"
                  class="fa fa-person-circle-question text-success"
                ></i>
                Leads
              </button>
            </li>
            <li v-if="isAdmin" class="nav-item" role="presentation">
              <button
                @click="SolicitudesLoad"
                class="nav-link"
                :class="activoPanel === 5 ? 'active' : ''"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                <i
                  style="font-size: 14px; margin-right: 5px"
                  class="fa fa-bell text-success"
                ></i>
                Solicitudes
              </button>
            </li>
            <li v-if="isAdmin" class="nav-item" role="presentation">
              <button
                @click="VentasLoad"
                class="nav-link"
                :class="activoPanel === 0 ? 'active' : ''"
                id="pills-ventas-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-ventas"
                type="button"
                role="tab"
                aria-controls="pills-ventas"
                aria-selected="true"
              >
                <i
                  style="font-size: 14px; margin-right: 5px"
                  class="fa fa-shopping-cart text-success"
                ></i>
                Ventas
              </button>
            </li>
            <li v-if="isAdmin" class="nav-item" role="presentation">
              <button
                @click="RetirosLoad"
                class="nav-link"
                :class="activoPanel === 1 ? 'active' : ''"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                <i
                  style="font-size: 14px; margin-right: 5px"
                  class="fa fa-money-bill text-success"
                ></i>
                Retiros
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                @click="UsuariosLoad"
                class="nav-link"
                :class="activoPanel === 2 ? 'active' : ''"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                <i
                  style="font-size: 14px; margin-right: 5px"
                  class="fa fa-diagram-project text-success"
                ></i>
                Usuarios
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                @click="UsersListLoad"
                class="nav-link"
                :class="activoPanel === 6 ? 'active' : ''"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                <i
                  style="font-size: 14px; margin-right: 5px"
                  class="fa fa-diagram-project text-success"
                ></i>
                Lista Usuarios
              </button>
            </li>
            <li v-if="isAdmin" class="nav-item" role="presentation">
              <button
                @click="TreeLoad"
                class="nav-link"
                :class="activoPanel === 3 ? 'active' : ''"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                <i
                  style="font-size: 14px; margin-right: 5px"
                  class="fa fa-diagram-project text-success"
                ></i>
                Comprimido
              </button>
            </li>
            <li v-if="isAdmin" class="nav-item" role="presentation">
              <button
                @click="RecursosLoad"
                class="nav-link"
                :class="activoPanel === 8 ? 'active' : ''"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                <i
                  style="font-size: 14px; margin-right: 5px"
                  class="fa fa-circle-up text-success"
                ></i>
                Recursos
              </button>
            </li>
          </ul>
        </div>
      </div>

      <div
        class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
        style="margin-top: 15px"
        :class="activoPanel === 6 ? 'scroll-x' : ''"
      >
        <div class="tab-content" id="pills-tabContent">
          <div
            class="tab-pane fade"
            :class="activoPanel === 0 ? 'show active' : ''"
            id="pills-ventas"
            role="tabpanel"
            aria-labelledby="pills-ventas-tab"
            tabindex="0"
          >
            <listado-ventas
              v-if="ifVentas"
              :key="componentKeyVentas"
              :user-id="userId"
              :token-auth="tokenAuth"
              :id-admin="idAdmin"
            />
          </div>
          <div
            class="tab-pane fade"
            :class="activoPanel === 1 ? 'show active' : ''"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
            tabindex="0"
          >
            <listado-retiros
              v-if="ifRetiros"
              :key="componentKeyRetiros"
              :user-id="userId"
              :token-auth="tokenAuth"
              :id-admin="idAdmin"
            />
          </div>
          <div
            class="tab-pane fade"
            :class="activoPanel === 2 ? 'show active' : ''"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
            tabindex="0"
          >
            <div>
              <ConsultantTree />
            </div>
          </div>
          <div
            class="tab-pane fade"
            :class="activoPanel === 3 ? 'show active' : ''"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
            tabindex="0"
          >
            <div>
              <FullTree />
            </div>
          </div>
          <div
            class="tab-pane fade"
            :class="activoPanel === 4 ? 'show active' : ''"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
            tabindex="0"
          >
            <div>
              <Resumen :token-auth="tokenAuth" />
            </div>
          </div>
          <div
            class="tab-pane fade"
            :class="activoPanel === 5 ? 'show active' : ''"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
            tabindex="0"
          >
            <div>
              <solicitudes />
            </div>
          </div>
          <div
            class="tab-pane fade"
            :class="activoPanel === 6 ? 'show active' : ''"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
            tabindex="0"
          >
            <UsersList />
          </div>
          <div
            class="tab-pane fade"
            :class="activoPanel === 7 ? 'show active' : ''"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
            tabindex="0"
          >
            <div class="container" style="margin-bottom: 10px">
              <Skeletor
                v-if="loadingLeads"
                :shimmer="true"
                height="100vh"
                width="100%"
                style="margin-bottom: 0px"
              />
              <div v-else style="display: flex; flex-direction: column">
                <div style="display: flex; justify-content: space-between">
                  <p style="font-size: 28px; font-weight: 600; color: #333333">
                    Listado de Leads
                  </p>
                  <button
                    class="btn btn-primary"
                    style="align-self: flex-end; margin-bottom: 12px !important"
                    id="btn_refresh"
                    @click="getLeads"
                  >
                    Refrescar <i class="fas fa-arrows-rotate"></i>
                  </button>
                </div>
                <leads
                  v-for="(lead, index) in leads"
                  :tokenAuth="tokenAuth"
                  :lead="lead"
                  :key="index"
                  :plans="plans"
                />
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            :class="activoPanel === 8 ? 'show active' : ''"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
            tabindex="0"
          >
            <!-- <listado-ventas-instaladas
              :key="componentKeyInstaladas"
              :user-id="userId"
              :token-auth="tokenAuth"
              :id-admin="idAdmin"
              /> -->
            <recursos-admin v-if="ifRecursos" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable */
import "bootstrap"; //no eliminar, este le da el evento al nav pills
import ListadoVentas from "./ListadoVentas.vue";
import ListadoRetiros from "./ListadoRetiros.vue";
import FullTree from "./FullTree.vue";
import ConsultantTree from "./ConsultantTree.vue";
import Resumen from "./Resumen.vue";
import Solicitudes from "./Solicitudes.vue";
import Leads from "./Leads.vue";
import UsersList from "./UsersList.vue";
import RecursosAdmin from "./RecursosAdmin.vue";

export default {
  components: {
    ListadoVentas,
    ListadoRetiros,
    FullTree,
    ConsultantTree,
    Resumen,
    Solicitudes,
    UsersList,
    Leads,
    RecursosAdmin,
  },
  data() {
    let datos = JSON.parse(this.$store.state.user);

    return {
      isMobile: this.$isMobile,
      userId: datos.userId,
      tokenAuth: datos.tokenAuth,
      rol: datos.rol,
      idAdmin: "",
      loading: false,
      loadingLeads: false,
      componentKey: 0,
      activoPanel: 0,
      componentKeyVentas: 100,
      ifVentas: false,
      ifRetiros: false,
      ifUsuarios: false,
      ifTree: false,
      ifResumen: false,
      ifSolicitudes: false,
      ifUsersList: false,
      ifLeads: false,
      ifRecursos: false,
      componentKeyRetiros: 200,
      leads: null,
      plans: null,
    };
  },
  watch: {
    $route(to, from) {
      var hash = to.hash.substring(1); //Puts hash in variable, and removes the # character
      switch (hash) {
        case "ventas":
          this.activoPanel = 0;
          this.ifVentas = true;
          break;
        case "retiros":
          this.activoPanel = 1;
          this.ifRetiros = true;
          break;
        case "usuarios":
          this.activoPanel = 2;
          this.ifUsuarios = true;
          break;
        case "usuarios2":
          this.activoPanel = 3;
          this.ifTree = true;
          break;
        case "resumen":
          this.activoPanel = 4;
          this.ifResumen = true;
          break;
        case "solicitudes":
          this.activoPanel = 5;
          this.ifSolicitudes = true;
          break;
        case "usersList":
          this.activoPanel = 6;
          this.ifUsersList = true;
          break;
        case "leads":
          this.activoPanel = 7;
          this.ifLeads = true;
          break;
        case "recursos":
          this.activoPanel = 8;
          this.ifRecursos = true;
          break;
        default:
          this.activoPanel = 0;
          this.ifVentas = true;
      }
    },
  },
  created() {},
  activated() {
    if (this.isMobile) {
      this.$eventBus.emit("inactiveShowMenu");
    }
  },
  mounted() {
    this.$eventBus.on("reiniciarListadoVentasAdmin", () => {
      this.componentKeyVentas += 1;
    });

    this.$eventBus.on("refreshLeads", () => {
      this.getLeads();
    });

    this.$eventBus.on("reiniciarAdmin", () => {
      this.componentKey += 1;
    });

    document.title = "XTRIMPRO - Administración";

    this.init();
  },
  computed: {
    isAdmin() {
      return this.rol === "admin";
    },
  },
  methods: {
    init() {
      if (this.rol !== "usuario") {
        if (this.rol === "admin") {
          this.ifResumen = true;
          this.activoPanel = 4;
          this.getLeads();
          this.fetchPlans();
        } else {
          this.ifResumen = true;
          this.activoPanel = 4;
        }
        this.loading = false;
      } else {
        this.loading = true;
      }
    },
    VentasLoad() {
      this.ifVentas = true;
      this.ifUsuarios = false;
      this.ifRetiros = false;
      this.ifTree = false;
      this.ifResumen = false;
      this.ifSolicitudes = false;
      this.ifUsersList = false;
      this.ifLeads = false;
      this.ifRecursos = false;
      this.activoPanel = 0;
    },
    RetirosLoad() {
      this.ifRetiros = true;
      this.ifUsuarios = false;
      this.ifVentas = false;
      this.ifTree = false;
      this.ifResumen = false;
      this.ifSolicitudes = false;
      this.ifUsersList = false;
      this.ifLeads = false;
      this.ifRecursos = false;
      this.activoPanel = 1;
    },
    UsuariosLoad() {
      this.ifUsuarios = true;
      this.ifVentas = false;
      this.ifRetiros = false;
      this.ifTree = false;
      this.ifResumen = false;
      this.ifSolicitudes = false;
      this.ifUsersList = false;
      this.ifLeads = false;
      this.ifRecursos = false;
      this.activoPanel = 2;
    },
    TreeLoad() {
      this.ifTree = true;
      this.ifUsuarios = false;
      this.ifVentas = false;
      this.ifRetiros = false;
      this.ifResumen = false;
      this.ifSolicitudes = false;
      this.ifUsersList = false;
      this.ifLeads = false;
      this.ifRecursos = false;
      this.activoPanel = 3;
    },
    ResumenLoad() {
      this.ifResumen = true;
      this.ifTree = false;
      this.ifUsuarios = false;
      this.ifVentas = false;
      this.ifRetiros = false;
      this.ifSolicitudes = false;
      this.ifUsersList = false;
      this.ifLeads = false;
      this.ifRecursos = false;
      this.activoPanel = 4;
    },
    SolicitudesLoad() {
      this.ifSolicitudes = true;
      this.ifResumen = false;
      this.ifTree = false;
      this.ifUsuarios = false;
      this.ifVentas = false;
      this.ifRetiros = false;
      this.ifUsersList = false;
      this.ifLeads = false;
      this.ifRecursos = false;
      this.activoPanel = 5;
    },
    UsersListLoad() {
      this.ifUsersList = true;
      this.ifSolicitudes = false;
      this.ifResumen = false;
      this.ifTree = false;
      this.ifUsuarios = false;
      this.ifVentas = false;
      this.ifRetiros = false;
      this.ifLeads = false;
      this.ifRecursos = false;
      this.activoPanel = 6;
    },
    LeadsLoad() {
      this.ifLeads = true;
      this.ifUsersList = false;
      this.ifSolicitudes = false;
      this.ifResumen = false;
      this.ifTree = false;
      this.ifUsuarios = false;
      this.ifVentas = false;
      this.ifRetiros = false;
      this.ifRecursos = false;
      this.activoPanel = 7;
    },
    RecursosLoad() {
      this.ifRecursos = true;
      this.ifLeads = false;
      this.ifUsersList = false;
      this.ifSolicitudes = false;
      this.ifResumen = false;
      this.ifTree = false;
      this.ifUsuarios = false;
      this.ifVentas = false;
      this.ifRetiros = false;
      this.activoPanel = 8;
    },
    getLeads() {
      this.loadingLeads = true;
      this.$https
        .post("/ventas/getLoadLeadMasivos", { tokenAuth: this.tokenAuth })
        .then((response) => {
          if (response.data.code === 200) {
            console.log(response.data.leads);
            this.leads = response.data.leads;
            this.loadingLeads = false;
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          this.$toast.error("Ocurrió un error", {
            position: "top-right",
            max: 10,
          });
          this.loadingLeads = false;
        });
    },
    fetchPlans() {
      this.isLoading = true;
      this.$https
        .post(`/planes/loadAllPlanes`)
        .then((response) => {
          if (response.data.code == 200) {
            this.plans = response.data.planes.sort((a, b) => {
              return parseInt(a.price) - parseInt(b.price);
            });
          } else {
            this.plans = [];
          }
        })
        .catch((error) => {
          this.$toast.error("No encontramos planes disponibles", {
            position: "top-right",
            max: 10,
          });
          console.log(error);
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss"></style>
