<template>
  <div class="solicitudCard" :class="{ expanded: selectedIndex === index }">
    <div class="cardTitle" @click="toggleDetails(index)">
      <p
        style="margin: 0; font-weight: 600; color: #333333"
        class="solicitudCard"
      >
        {{ lead.displayNameReferral }}
      </p>
      <div style="display: flex; flex-direction: column">
        <p style="margin: 0; font-weight: 600; color: rgb(127 126 126)">
          {{ lead.dateFormated }}
        </p>
        <p
          v-if="lead.converted"
          style="margin: 0; font-weight: 600; color: #19b251"
        >
          Convertido
        </p>
        <p v-else style="margin: 0; font-weight: 600; color: #7d0c7e">Nuevo</p>
      </div>
    </div>
    <div
      v-if="selectedIndex === index && !lead.converted"
      class="solicitudCard"
    >
      <form
        @submit.prevent="onSubmit(lead)"
        autocomplete="off"
        class="row formContainer"
      >
        <div
          class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
          style="margin-bottom: 10px"
        >
          <label
            for="example-text-input"
            class="form-control-label"
            style="font-weight: bold; font-size: 12px"
            >Quien Refiere:</label
          >
          <p style="margin: 0" class="form-control">
            {{ lead.displayNamePromoter }}
          </p>
        </div>
        <br />
        <br />
        <br />
        <br />

        <div
          class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
          style="margin-bottom: 10px"
        >
          <label
            for="example-text-input"
            class="form-control-label"
            style="font-weight: bold; font-size: 12px"
            >Teléfono de Quien Refiere:</label
          >
          <p
            style="margin: 0"
            class="form-control"
            @click="copyField(solicitud.refererId)"
          >
            {{ lead.phonePromoter }}
          </p>
        </div>
        <div
          class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
          style="margin-bottom: 10px"
        >
          <label
            for="example-text-input"
            class="form-control-label"
            style="font-weight: bold; font-size: 12px"
            >Nombres y apellidos</label
          >
          <p class="form-control" @click="copyField(solicitud.displayName)">
            {{ lead.displayNameReferral }}
          </p>
        </div>

        <div
          class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
          style="margin-bottom: 10px"
        >
          <label
            for="example-text-input"
            class="form-control-label"
            style="font-weight: bold; font-size: 12px"
            >Teléfono del Referido :</label
          >

          <p
            style="margin: 0"
            class="form-control"
            @click="copyField(lead.phoneReferral.replace(/^\+593/, '0'))"
          >
            {{ lead.phoneReferral }}
          </p>
        </div>
        <div
          class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
          style="margin-bottom: 10px"
        >
          <label
            for="example-text-input"
            class="form-control-label"
            style="font-weight: bold; font-size: 12px"
            >Cédula :</label
          >

          <input
            style="margin: 0"
            class="form-control"
            v-model="dni"
            required
            @input="onInputChange()"
            type="text"
            autocomplete="off"
            maxlength="10"
          />
        </div>
        <div
          class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
          style="margin-bottom: 10px"
        >
          <label
            for="example-text-input"
            class="form-control-label"
            style="font-weight: bold; font-size: 12px"
            >Código Dactilar :</label
          >

          <input
            style="margin: 0"
            class="form-control"
            v-model="dactilar"
            required
            maxlength="10"
          />
        </div>

        <div
          class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
          style="margin-bottom: 10px"
        >
          <label
            for="example-text-input"
            class="form-control-label"
            style="font-weight: bold; font-size: 12px"
            >Ingrese su email :</label
          >
          <input
            style="margin: 0"
            class="form-control"
            v-model="email"
            required
          />
        </div>

        <div
          class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
          style="margin-bottom: 10px"
        >
          <label
            for="example-text-input"
            class="form-control-label"
            style="font-weight: bold; font-size: 12px"
            >Dirección :</label
          >
          <input
            style="margin: 0"
            class="form-control"
            v-model="address"
            required
          />
        </div>

        <div
          class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
          style="margin-bottom: 10px"
        >
          <label
            for="example-text-input"
            class="form-control-label"
            style="font-weight: bold; font-size: 12px"
            >Latitud y Longitud :</label
          >
          <p
            class="form-control"
            @click="copyField(`${lead.latitude}, ${lead.longitude}`)"
          >
            {{ `${lead.latitude}, ${lead.longitude}` }}
          </p>
        </div>

        <div
          class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
          style="margin-bottom: 10px"
        >
          <label
            for="example-text-input"
            class="form-control-label"
            style="font-weight: bold; font-size: 12px"
            >Calle Principal :</label
          >
          <input class="form-control" v-model="callePrincipal" required />
        </div>

        <div
          class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
          style="margin-bottom: 10px"
        >
          <label
            for="example-text-input"
            class="form-control-label"
            style="font-weight: bold; font-size: 12px"
            >Calle Secundaria :</label
          >
          <input class="form-control" v-model="calleSecundaria" required />
        </div>

        <div
          class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
          style="margin-bottom: 10px"
        >
          <label
            for="example-text-input"
            class="form-control-label"
            style="font-weight: bold; font-size: 12px"
            >Manzana :</label
          >
          <input
            style="margin: 0"
            class="form-control"
            v-model="manzana"
            required
          />
        </div>

        <div
          class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
          style="margin-bottom: 10px"
        >
          <label
            for="example-text-input"
            class="form-control-label"
            style="font-weight: bold; font-size: 12px"
            >Villa :</label
          >
          <input
            style="margin: 0"
            class="form-control"
            v-model="villa"
            required
          />
        </div>

        <div
          class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
          style="margin-bottom: 10px"
        >
          <label
            for="example-text-input"
            class="form-control-label"
            style="font-weight: bold; font-size: 12px"
            >Referencia :</label
          >
          <input
            style="margin: 0"
            class="form-control"
            v-model="referencia"
            required
          />
        </div>
        <div
          style="display: flex; flex-direction: column"
          class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
        >
          <label>Seleccionar Plan Vendido: </label>
          <select
            v-model="selectedPlan"
            class="form-select"
            placeholder="Seleccionar plan"
            required
          >
            <option
              v-for="(plan, index) in plans"
              :key="index"
              selected
              :value="plan.planId"
            >
              {{
                plan.name +
                " " +
                (plan.isGponAvailable ? "GPON" : "HFC") +
                " " +
                plan.description +
                " - $" +
                plan.price.toFixed(2)
              }}
            </option>
          </select>
        </div>
        <div
          class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
          style="padding: 0; display: flex; justify-content: end"
        >
          <button
            class="btn btn-primary"
            style="align-self: flex-end"
            id="btn_submit"
            :disabled="isDisabled"
          >
            Guardar como Solicitud
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "Leads",
  props: ["tokenAuth", "lead", "plans"],
  data() {
    return {
      loading: true,
      selectedIndex: null,
      contractNumber: null,
      isLoading: false,
      isDisabled: false,

      dni: "",
      dactilar: "",
      email: "",
      address: "",
      callePrincipal: "",
      calleSecundaria: "",
      manzana: "",
      villa: "",
      referencia: "",
      selectedPlan: null,
    };
  },
  computed: {},
  watch: {
    dactilar() {
      this.dactilar = this.dactilar.toUpperCase();
    },
  },
  async mounted() {},
  methods: {
    toggleDetails(index) {
      this.selectedIndex = this.selectedIndex === index ? null : index;
      this.contractNumber = null;
    },
    copyField(fieldValue) {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(fieldValue)
          .then(() => {
            this.$toast.success(`${fieldValue} copiado exitosamente.`, {
              position: "top-right",
              max: 10,
            });
          })
          .catch((err) => {
            console.error("No se pudo copiar el campo: ", err);
            this.$toast.error("Falla al copiar el campo.", {
              position: "top-right",
              max: 10,
            });
          });
      } else {
        console.error("Clipboard API not available.");
        this.$toast.error("Clipboard functionality not available.", {
          position: "top-right",
          max: 10,
        });
      }
    },
    onSubmit(lead) {
      document.getElementById("btn_submit").innerHTML =
        '<i class="fas fa-spinner fa-pulse"></i> Guardando...';

      this.isDisabled = true;

      const infoDelPlan = this.plans.filter((plan) => {
        return this.selectedPlan === plan.planId;
      })[0];

      const jsonData = {
        address: this.address,
        callePrincipal: this.callePrincipal,
        calleSecundaria: this.calleSecundaria,
        dactilar: this.dactilar,
        displayName: lead.displayNameReferral,
        dni: this.dni,
        email: this.email,
        lat: lead.latitude,
        lng: lead.longitude,
        manzana: this.manzana,
        phone: lead.phoneReferral,
        referencia: this.referencia,
        referer: lead.displayNamePromoter,
        villa: this.villa,
        refererId: lead.dniPromoter,
        plan: infoDelPlan,
        userId: lead.userId,
        canal: "Link Referidos",
      };

      console.log(jsonData);

      this.$https
        .post("/ventas/getAddSolicitudVentas", jsonData)
        .then((response) => {
          // document.getElementById("btn_submit").innerHTML =
          //   "Solicitar servicio";
          this.isDisabled = false;
          if (response.data.code == 200) {
            this.$https
              .post("/ventas/updateLeadState", { leadId: lead.leadId })
              .then((response) => {
                console.log("Estado actualizado", response);
                this.$toast.success("Lead guardado como Solicitud", {
                  position: "top-right",
                  max: 10,
                });
                this.$eventBus.emit("refreshLeads");
                document.getElementById("btn_submit").innerHTML =
                  "Guardar como Solicitud";
              })
              .catch((error) => {
                document.getElementById("btn_submit").innerHTML =
                  "Guardar como Solicitud";
                this.$toast.error(error, {
                  position: "top-right",
                  max: 10,
                });
              });
          } else {
            this.$toast.error(response.data.message, {
              position: "top-right",
              max: 10,
            });
            document.getElementById("btn_submit").innerHTML =
              "Guardar como Solicitud";
            this.isDisabled = false;

            if (response.data.code == 401) {
              localStorage.removeItem("userData");
              this.$store.state.user = "";

              this.$router.push({ name: "auth-login" });
            }
          }
        })
        .catch((error) => {
          document.getElementById("btn_submit").innerHTML =
            "Guardar como Solicitud";
          this.isDisabled = false;

          this.$toast.error("Ocurrió un error inesperado" + error, {
            position: "top-right",
            max: 10,
          });
        });
    },
    onInputChange() {
      this.dni = this.dni.replace(/\D/g, "").slice(0, 10);
      console.log("El dni", this.dni);
    },
  },
};
</script>

<style lang="scss">
@import "datatables.net-dt";
@import "datatables.net-responsive-dt";

.solicitudCard {
  //   border: 1px solid #ccc;
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.3s;
}

.solicitudCard.expanded {
  height: auto;
}

.solicitudCard-header {
  font-weight: bold;
  padding: 0;
  margin: 0;
}

.solicitudCard-body {
  display: block;
}

.form-control {
  background-color: #f7f7f7; /* Light grey background on hover */
}

.form-control:hover {
  background-color: #f0f0f0; /* Light grey background on hover */
  color: #888; /* Lighter text color on hover */
}

.form-control:active {
  background-color: #cccccc; /* Light grey background on hover */
  color: #6e6e6e; /* Lighter text color on hover */
}

.form-control-label {
  font-size: 14px !important;
}
.cardTitle {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>
