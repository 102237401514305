<template>
  <div class="card" style="margin-bottom: 10px">
    <div v-if="loading" align="center">
      <i
        style="font-size: 50px; margin-bottom: 15px; margin-top: 15px"
        class="fas fa-spinner fa-spin"
      ></i>
      <p style="text-align: center; font-size: 16px; font-weight: bold">
        Generando gráfico, por favor espere
      </p>
    </div>

    <div v-else-if="empty" align="center">
      <i
        style="font-size: 50px; margin-bottom: 15px; margin-top: 15px"
        class="far fa-sad-cry"
      ></i>

      <p style="text-align: center; font-size: 16px; font-weight: bold">
        No se encontraron datos
      </p>
    </div>
    <GChart
      v-else
      type="PieChart"
      :events="chartEvents"
      :resizeDebounce="500"
      :options="options"
      :data="data"
    />
  </div>
</template>
<script>
/* eslint-disable */

import { GChart } from "vue-google-charts";

export default {
  components: {
    GChart,
  },

  data() {
    let user = JSON.parse(localStorage.getItem("userData"));

    return {
      isMobile: this.$isMobile,
      loading: true,
      userId: "fQFV4l3eXhRWOVwOgAnQMLhhbcn2",
      tokenAuth: user.tokenAuth,
      data: [],
      options: {
        width: "100%",
        height: 600,
        title: "Mis Ventas Directas",
        legend: { position: "bottom" },
      },
      chartEvents: {
        select: () => {
          // handle event here
          const selection = getSelection();

          this.chartEvents2(selection);
        },
      },
    };
  },
  activated() {
    if (this.isMobile) {
      this.$eventBus.emit("inactiveShowMenu");
    }
  },

  methods: {
    chartEvents2(selection) {
      console.log(selection);
    },

    load() {
      this.loading = true;

      this.empty = false;
      this.$https
        .post("/referidos/estadisticas/", {
          tokenAuth: this.tokenAuth,
          userId: this.userId,
        })
        .then((response) => {
          if (response.data.code == 200) {
            this.data = response.data.data;

            this.loading = false;
          } else {
            if (response.data.code === 401) {
              this.$toast.error(response.data.message, {
                position: "top-right",
                max: 10,
              });

              localStorage.removeItem("userData");
              this.$store.state.user = "";

              // Redirect to login page
              this.$router.push({ name: "login" });
            } else {
              if (response.data.code === 800) {
                this.loading = false;
                this.empty = true;
              } else {
                this.load();
              }
            }
          }
        })
        .catch((error) => {
          this.load();
        });
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<style lang="scss"></style>
