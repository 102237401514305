<template>
  <div>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <!--<listado  :key="componentKeyListadoReferidos" :user-id="userId" :token-auth="tokenAuth" />-->
        <Skeletor
          v-if="loading"
          :shimmer="true"
          height="100vh"
          width="100%"
          style="margin-bottom: 0px"
        />
        <organization-chart
          :datasource="ds"
          :pan="true"
          :zoom="true"
          :admin="false"
          @node-click="nodeClick"
          v-else
          :style="{
            width: '100%',
            maxHeight: this.$isMobile
              ? 'calc( 100vh - 127px)'
              : 'calc( 100vh - 70px)',
          }"
        ></organization-chart>
        <div
          class="sidebar sidebarBody sidebarScheme"
          :style="{
            maxHeight: this.$isMobile
              ? 'calc( 100vh - 110px)'
              : 'calc( 100vh - 45px)',
          }"
          style="overflow: auto"
          :class="{ active: isSidebarActive }"
        >
          <div class="sidebarHeader">
            <p style="font-weight: 600; font-size: 18px">
              {{ this.data.title }}
            </p>
            <div @click="closeSidebar" class="close_modal">
              <i class="fas fa-times-square close_modal"></i>
            </div>
          </div>
          <img
            :src="this.photoSet"
            class="userPicture"
            :style="
              this.data.nivel === 1
                ? 'border: 3px solid #2A93D5'
                : this.data.nivel === 2
                ? 'border: 3px solid #F3CC23'
                : this.data.nivel === 3
                ? 'border: 3px solid #EF5350'
                : this.data.nivel === 4
                ? 'border: 3px solid #02B875'
                : 'border: 3px solid #920071'
            "
          />

          <div class="fieldContainer">
            <p style="font-weight: 600; margin: 0">Nombre:</p>
            <p class="treeDetailField">{{ this.data.displayName }}</p>
          </div>
          <div class="fieldContainer">
            <p style="font-weight: 600; margin: 0">Código Multinivel:</p>
            <p class="treeDetailField">XP{{ this.multiLevelNumberFormat }}</p>
          </div>
          <!-- <p>{{ this.data.nivel }}</p> -->
          <div class="fieldContainer">
            <p style="font-weight: 600; margin: 0">Teléfono:</p>
            <p class="treeDetailField">{{ this.data.phone }}</p>
          </div>
          <div class="fieldContainer">
            <p style="font-weight: 600; margin: 0">Email:</p>
            <p class="treeDetailField">{{ this.data.email }}</p>
          </div>
          <div class="fieldContainer">
            <p style="font-weight: 600; margin: 0">Ventas:</p>
            <p class="treeDetailField">{{ this.data.ventasPeriodo }}</p>
          </div>
          <div class="fieldContainer">
            <p style="font-weight: 600; margin: 0">UserId:</p>
            <p class="treeDetailField">{{ this.data.userId }}</p>
          </div>
          <div class="fieldContainer">
            <p style="font-weight: 600; margin: 0">userIdFirstLevel:</p>
            <p class="treeDetailField">
              {{
                this.data.userIdFirstLevel
                  ? this.data.userIdFirstLevel
                  : "No tiene"
              }}
            </p>
          </div>
          <div class="fieldContainer">
            <p style="font-weight: 600; margin: 0">userIdSecondLevel:</p>
            <p class="treeDetailField">
              {{
                this.data.userIdSecondLevel
                  ? this.data.userIdSecondLevel
                  : "No tiene"
              }}
            </p>
          </div>
          <div class="fieldContainer">
            <p style="font-weight: 600; margin: 0">userIdThirdLevel:</p>
            <p class="treeDetailField">
              {{
                this.data.userIdThirdLevel
                  ? this.data.userIdThirdLevel
                  : "No tiene"
              }}
            </p>
          </div>
          <div class="fieldContainer">
            <p style="font-weight: 600; margin: 0">userIdFourthLevel:</p>
            <p class="treeDetailField">
              {{
                this.data.userIdFourthLevel
                  ? this.data.userIdFourthLevel
                  : "No tiene"
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable */

import OrganizationChart from "../../components/OrganizationChartContainer.vue";

export default {
  name: "FullTree",
  components: {
    OrganizationChart,
  },

  created() {
    this.chartData = {};
  },

  data() {
    let datos = JSON.parse(this.$store.state.user);
    return {
      componentKeyListadoReferidos: 0,
      userId: datos.userId,
      tokenAuth: datos.tokenAuth,
      isSidebarActive: false,
      isMobile: this.$isMobile,
      loading: true,
      data: {
        title: "",
        nivel: null,
        displayName: "",
        multiLevelNumber: null,
        phone: "",
        email: "",
        photo: "",
        ventasPeriodo: 0,
        userId: "",
        userIdFirstLevel: "",
        userIdSecondLevel: "",
        userIdThirdLevel: "",
        userIdFourthLevel: "",
      },
      ds: {},
    };
  },
  activated() {
    if (this.isMobile) {
      this.$eventBus.emit("activeShowMenu");
    }
  },
  methods: {
    nodeClick(data) {
      this.isSidebarActive = true; // Open the sidebar when a slot is clicked
      if (this.data.multiLevelNumber !== data.multiLevelNumber) {
        this.data = {
          title: data.title,
          nivel: data.nivel,
          displayName: data.displayName,
          multiLevelNumber: data.multiLevelNumber,
          phone: data.phone,
          email: data.email,
          photo: data.photo,
          ventasPeriodo: data.ventasPeriodo,
          userId: data.userId,
          userIdFirstLevel: data.userIdFirstLevel,
          userIdSecondLevel: data.userIdSecondLevel,
          userIdThirdLevel: data.userIdThirdLevel,
          userIdFourthLevel: data.userIdFourthLevel,
        };
      }
      console.log(data);
    },

    closeSidebar() {
      this.isSidebarActive = false; // Close the sidebar when the close button is clicked
    },

    getTotalTree() {
      this.$https
        .post(`/team/getCleansedTree`, {
          tokenAuth: this.tokenAuth,
          userId: this.userId,
        })
        .then((response) => {
          if (response.data.code == 200) {
            // this.$toast.success(response.data.message, {
            //   position: "top-right",
            //   max: 10,
            // });

            this.loading = false;

            this.ds = response.data.data;
          } else {
            this.$toast.error(response.data.message, {
              position: "top-right",
              max: 10,
            });

            if (response.data.code == 401) {
              localStorage.removeItem("userData");
              this.$store.state.user = "";

              // Redirect to login page
              this.$router.push({ name: "login" });
            }
          }
        })
        .catch((error) => {
          this.$toast.error("Ocurrió un error inesperado", {
            position: "top-right",
            max: 10,
          });
        });
    },
  },
  computed: {
    photoSet() {
      return this.data.photo !== "" ? this.data.photo : "/img/userDark.svg";
    },
    multiLevelNumberFormat() {
      const numberAsString = this.data.multiLevelNumber
        ? this.data.multiLevelNumber.toString()
        : "";
      return numberAsString.padStart(8, "0");
    },
  },
  mounted() {
    this.$eventBus.on("reiniciarListadoReferidos", () => {
      this.componentKeyListadoReferidos += 1;
    });
    this.getTotalTree();
  },
};
</script>

<style scoped>
@import "datatables.net-dt";
@import "datatables.net-responsive-dt";
.sidebar {
  position: fixed;
  bottom: 0;
  right: -300px; /* Start with the sidebar hidden */
  width: 300px; /* Set your desired width */
  height: calc(100vh - 47px);
  background-color: #fff; /* Set your desired background color */
  box-shadow: -2px 0 4px rgba(0, 0, 0, 0.2); /* Add a shadow */
  transition: right 0.3s; /* Add smooth transition effect */
  z-index: 999; /* Ensure it's above other content */
  padding: 20px;
}

.sidebar.active {
  right: 0; /* Show the sidebar */
}

/* Add styles for sidebar content */
.sidebar-content {
  padding: 20px;
  /* Add additional styles as needed */
}
.userPicture {
  width: 60px;
  height: 60px;
  border-radius: 100%;
}
.sidebarHeader {
  display: flex;
  justify-content: space-between;
  align-items: start;
}
.sidebarBody {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.fieldContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
