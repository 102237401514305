<template>
  <component :is="layout">
    <router-view />
  </component>
</template>
<script>
/* eslint-disable */

import { defineAsyncComponent } from "vue";
const Horizontal = defineAsyncComponent(() =>
  import("@/views/Layouts/Horizontal.vue")
);
const Full = defineAsyncComponent(() => import("@/views/Layouts/Full.vue"));

export default {
  name: "App",
  components: {
    Full,
    Horizontal,
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    layout() {
      if (
        this.$route.meta.layout === "full" ||
        this.$route.meta.layout === undefined
      ) {
        return "full";
      } else {
        return `horizontal`;
      }
    },
  },
  beforeMount() {
    //this.$store.state.isTransparent = "bg-transparent";
  },
  mounted() {
    document.body.classList.remove("dark-version");
  },
};
</script>
<style scoped></style>
