<template>
  <div class="card" style="margin-bottom: 10px">
    <div class="card-body">
      <div class="row">
        <div
          class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12"
          style="
            margin-bottom: 10px;
            justify-content: space-between;
            display: flex;
          "
        >
          <h5
            class="card-title mb-0 text-uppercase font-weight-bold"
            style="font-size: 12px"
          >
            MI EQUIPO 4TO NIVEL
          </h5>
          <div
            v-if="!loading"
            @click="refreshTeam"
            style="cursor: pointer; position: relative; top: -5px"
          >
            <i
              style="font-size: 6px"
              class="fas fa-sync text-sm opacity-10"
            ></i>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-12">
          <div class="teamInfoContainer">
            <div class="teamReturns">
              <!-- <p style="font-size: 14px; font-weight: 600; margin: 0">$</p> -->
              <Skeletor
                v-if="loading"
                :shimmer="true"
                height="24"
                width="20"
                style="margin: 16px 0"
              />
              <div v-else class="amountValue">
                <p class="font-weight-bolder priceCard">
                  <!-- {{ cantidad.toFixed(2) }} -->
                  ${{ integerPart }}
                </p>
                <p class="decimals">{{ decimalPart }}</p>
              </div>
            </div>
            <div class="teamCount">
              <Skeletor
                v-if="loading"
                :shimmer="true"
                height="16"
                width="16"
                style="margin-bottom: 0px"
              />
              <p
                v-else
                class="w-auto teamNumber"
                style="
                  font-size: 20px;
                  font-weight: 600;
                  margin: 0;
                  padding-right: 4px;
                "
              >
                {{ equipo }}
              </p>
              <i
                class="fa-solid fa-people-group teamNumber"
                style="height: 18px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  components: {},
  props: [
    "userId",
    "tokenAuth",
    "loading",
    "refreshTeam",
    "levelFourTeam",
    "levelFourAmount",
  ],
  data() {},
  computed: {
    equipo() {
      return this.levelFourTeam;
    },
    cantidad() {
      return this.levelFourAmount;
    },
    integerPart() {
      return Math.trunc(this.cantidad).toString();
    },
    decimalPart() {
      let decimal = this.cantidad.toFixed(2).split(".")[1];
      return decimal;
    },
  },
  watch: {},

  methods: {},
};
</script>

<style lang="scss"></style>
