<template>
  <h5 class="mt-3" style="text-align: center; margin-bottom: 15px">
    Ingreso al sistema
  </h5>

  <form role="form" autocomplete="off" @submit.prevent="autenticacion">
    <div style="margin-bottom: 15px">
      <input
        required
        placeholder="Ingrese su cédula"
        @input="onInputChange"
        class="form-control col-lg-12"
        type="text"
        autocomplete="off"
        v-model="user.dni"
      />
    </div>

    <div style="margin-bottom: 15px">
      <input
        required
        placeholder="Ingrese su contraseña"
        class="form-control col-lg-12"
        type="password"
        autocomplete="off"
        v-model="user.contrasena"
      />
    </div>

    <div class="text-center" style="margin-bottom: 15px">
      <argon-button
        class="mt-1"
        id="btn_ingreso"
        variant="gradient"
        color="success"
        style="width: 100%"
        type="submit"
        size="lg"
        >Iniciar Sesión</argon-button
      >
    </div>
  </form>

  <!-- <p
    class="mt-3"
    style="
      cursor: pointer;
      text-align: center;
      margin-bottom: 5px;
      font-size: 12px;
    "
    @click="toggleForm"
  >
    ¿Aún no tienes una cuenta? ¡Regístrate!
  </p> -->

  <p
    class="mt-3"
    style="
      cursor: pointer;
      text-align: center;
      margin-bottom: 5px;
      font-size: 12px;
    "
    @click="toggleForm"
  >
    ¿Olvidaste tu contraseña? Haz Click aquí.
  </p>
</template>

<script>
/*eslint-disable */
import ArgonButton from "@/components/ArgonButton.vue";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/app-check";
import { VueFinalModal } from "vue-final-modal";
import "vue-final-modal/style.css";

export default {
  name: "formulario",
  components: {
    ArgonButton,
    VueFinalModal,
  },
  props: ["promoCode", "textoButton", "isLogin"],
  data() {
    return {
      user: {
        contrasena: "",
        dni: "",
      },
      isDisabled: false,
      showTerminos: false,
      countryCode: "",
      countryCode: "",
      userData: null,
      tokenAuth: null,
      token: null,
      installEvent: null,
      appCheck: null,
      isActive: true,
    };
  },
  watch: {},
  computed: {},
  async mounted() {
    this.user.contrasena = "";
    this.user.dni = "";

    firebase.auth().useDeviceLanguage();

    this.appCheck = firebase.appCheck();

    this.appCheck.activate(
      "6LcmjzApAAAAALWdBKUfCTTv_cwg2KXPqGESp5TD",

      // Optional argument. If true, the SDK automatically refreshes App Check
      // tokens as needed.
      true
    );

    let tokenAux = await this.appCheck.getToken();

    this.token = tokenAux.token;

    console.log("Token firebase");

    console.log(this.token);
    this.tokenAuth = this.token;

    this.appCheck.setTokenAutoRefreshEnabled(true);
  },
  methods: {
    onInputChange() {
      this.user.dni = this.user.dni.replace(/\D/g, "").slice(0, 10);
    },

    contrasena() {
      this.isDisabled = false;
      this.password = "";
      this.showModal = false;
      this.showModalContraseña = true;
    },

    closed() {
      this.showModalContraseña = false;
    },

    confirmar() {},

    autenticacion() {
      const userEncripted = this.$encryptBackoffice.encrypt(
        this.user.contrasena
      );

      let datos = {
        dni: this.user.dni,
        password: userEncripted,
        tokenAuth: this.tokenAuth,
      };

      document.getElementById("btn_ingreso").innerHTML =
        '<i class="fas fa-spinner fa-pulse"></i> Espere...';

      this.$https
        .post("/acceso/login", datos)
        .then((response) => {
          console.log("Los el response de los datos", response);
          let sessionType;
          if (response.data.code !== 404) {
            sessionType = this.$encryptBackoffice.encrypt(
              response.data.user.rol
            );
          }
          const user = response.data.user;
          const bankUser = response.data.bankUser;

          if (response.data.code === 201) {
            console.log("Ingresó al 201");
            let userData = {
              displayName: user.displayName,
              dni: this.user.dni,
              promoCode: user.promoCode,
              userId: user.userId,
              phone: user.phone,
              photo: user.photo,
              email: user.email,
              tokenAuth: this.tokenAuth,
              multiLevelNumber: user.multiLevelNumber,
              bankUser: bankUser,
              rol: user.rol,
            };
            //let userData2 = this.$encryptBackoffice.encrypt(userData);
            localStorage.setItem("userData", JSON.stringify(userData));
            localStorage.setItem("authorization", this.tokenAuth);
            this.$store.state.user = JSON.stringify(userData);
            localStorage.setItem("session", sessionType);
            this.$router.push({ name: "password" });
            this.showTerminos = true;
          }

          if (response.data.code === 200) {
            console.log("response.data", response.data);
            let userData = {
              displayName: user.displayName,
              dni: this.user.dni,
              promoCode: user.promoCode,
              userId: user.userId,
              phone: user.phone,
              photo: user.photo,
              email: user.email,
              tokenAuth: this.tokenAuth,
              multiLevelNumber: user.multiLevelNumber,
              bankUser: bankUser,
              rol: user.rol,
            };
            localStorage.setItem("session", sessionType);
            localStorage.setItem("userData", JSON.stringify(userData));
            localStorage.setItem("authorization", this.tokenAuth);
            this.$store.state.user = JSON.stringify(userData);
            console.log("Ingresa al 200");
            this.$router.push({ name: "Dashboard" });
          }
          if (response.data.code === 204) {
            this.$toast.error("Contraseña incorrecta", {
              position: "top-right",
              max: 10,
            });
            document.getElementById("btn_ingreso").innerHTML = "Iniciar Sesión";
            console.log("Ingresa al 204");
          } else if (response.data.code === 404) {
            this.$toast.error(response.data.message, {
              position: "top-right",
              max: 10,
            });
          }
        })
        .catch((error) => {
          console.log("Ocurre un error", error);
          document.getElementById("btn_ingreso").innerHTML = "Iniciar Sesión";
          this.$toast.error("Ocurrió un error inesperado", {
            position: "top-right",
            max: 10,
          });
        });
    },

    toggleForm() {
      this.$emit("toggle-form");
    },
  },

  created() {},
  beforeUnmount() {},
};
</script>

<style scoped>
.bg-gradient-success {
  background-image: linear-gradient(310deg, #591361 0%, #7d0c7e 100%);
}

.m-input.--md .m-input-input,
.m-input.--md .m-input-label {
  font-size: 12px !important;
  line-height: 1.5rem;
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  cursor: not-allowed;
  pointer-events: auto;
}
</style>
