<template>

    <div class="row" style="    width: 100%;margin: 0px;">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" align="center" style="margin-bottom: 10px;margin-top:15px">
    
            <router-link :to="{ name: 'Dashboard'}" target="_blank"><img src="../../src/assets/img/Xtrim/xtrim_logo_morado.svg" style="width: 200px; " /></router-link>
    
       
        
        </div>
    
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" align="center" style="margin-top:105px">
    
            <img src="/img/sad.svg" style="width: 250px;" />
    
            <h2 class="mb-1" style="font-weight: bold;margin-top:25px">
                Usuario no encontrado 🕵🏻‍♀️
            </h2>
            <p class="mb-2" style="font-size:16px; ">
                Oops! 😖 No encontramos a un usuario con el código de promotor proporcionado.
            </p>
    
            <router-link
             class="btn btn-primary"
              :to="{path:'/dashboard'}"
            >
              Ir al dashboard
                </router-link>
    
            </div>
    
    </div>
    
    </template>
    
    <script>
    /*eslint-disable */
    

    export default {
        name: "NotFound",
        data() {
            return {
               
            }
        },
        methods: {
    
    
        },  created() {
    
 

            },
            beforeUnmount() {
    
            },
    }    
    </script>
    
    
    <style>
    
    </style>