<template>
  <VueFinalModal v-model="showTerminos" :esc-to-close="false" :click-to-close="false" :prevent-click="false"
    class="modal-container" content-class="modal-content">
    <span class="modal__title">Términos y condiciones de uso</span>
    <div class="modal__content">

      <p style="font-size:12px;text-align: justify;">
        En mi calidad de usuario (en adelante “el usuario” o “usuario”) de esta plataforma (en adelante plataforma
        “PLANPRO”) de titularidad de PLANPROCAD S.A., reconozco expresamente que al utilizarla debo sujetarme en todo
        momento a lo aquí establecido. Al acceder o utilizar esta plataforma web, <b>acepto expresa, libre y
          voluntariamente los siguientes Términos y Condiciones de Uso.</b>
      </p>
      <p style="font-size:12px;text-align: justify;">
        Reconozco y acepto que la plataforma PLANPRO en la persona de su administrador o titular, se reserva el derecho de
        negarme el acceso a la misma en cualquier caso que considere apropiado, en particular si yo como usuario:
      </p>

      <p style="font-size:12px;text-align: justify;">
      <ol>
        <li>Proporciono <b>datos falsos</b></li>
        <li><b>Incumplo</b> estos Términos y Condiciones de Uso de cualquier forma; y,</li>
        <li>Incumplo <b>cualquier normativa legal</b> aplicable respecto del acceso o el uso de la presente plataforma.
        </li>

      </ol>
      </p>

      <p style="font-size:12px;text-align: justify;">
        Acepta libre, voluntaria y expresamente que soy el único responsable del contenido que cargue, publique, envíe por
        correo electrónico, transmita o de cualquier forma ponga a disposición a través de esta plataforma.
      </p>

      <p style="font-size:12px;text-align: justify;">

        Adicionalmente me obligo expresa, libre y voluntariamente a <b>no utilizar la presente plataforma en forma alguna
          que sirva directa o indirectamente para:</b>
      </p>

      <p style="font-size:12px;text-align: justify;">
      <ul>
        <li>Dañar a otras personas o animales de cualquier forma; </li>
        <li>Realizar declaraciones falsas; </li>
        <li>Difundir de cualquier forma contenido que viole un derecho de propiedad intelectual de terceros, incluyendo
          pero no limitado a marcas, derechos de autor, secretos empresariales, patentes y diseños industriales; y, </li>
        <li>Violar cualquier ley o norma jurídica nacional o internacional. </li>
      </ul>
      </p>

      <p style="font-size:12px;text-align: justify;">

        Reconozco y acepto que la plataforma PLANPRO en la persona de su administrador o titular se reserva el derecho de
        modificar a su discreción los presentes términos y condiciones, comunicándome el particular, siempre de acuerdo
        con la ley ecuatoriana.

      </p>

      <p style="font-size:12px;text-align: justify;">

        <b>Datos Personales:</b> Autorizo expresamente a PLANPROCAD S.A. a utilizar mis datos personales entregados o
        generados por medio de mi utilización de la plataforma PLANPRO. Esta autorización incluye los siguientes usos:
      </p>

      <p style="font-size:12px;text-align: justify;">
      <ul>
        <li>Acceso a datos; </li>
        <li>Consulta de buró crediticio; </li>
      </ul>
      </p>


    </div>
    <div class="modal__action">

      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" align="center">

          <button v-if="!isLoading" type="button" class="btn btn-primary" @click="registrarUser" style="width: 100%;">
            Confirmar
          </button>

          <div v-else  style="display: flex; justify-content: center; align-items: center;" class="btn btn-primary">
            <i class="fas fa-spinner fa-pulse" style="margin-right: 8px;"></i>
            <p  style="margin: 0; color: white; font-size: 14px; font-weight: 700;">Espere...</p>
          </div>


        </div>


      </div>

    </div>
  </VueFinalModal>
  
    <h5 class="mt-3" style="text-align: center;margin-bottom: 15px;">Ingresa tu cédula</h5>
  
    <form role="form" autocomplete="off" @submit.prevent="submitForm">
      <div style="margin-bottom:15px">
        <input required placeholder="Ingrese su cédula" @input="onInputChange" class="form-control col-lg-12" type="text"
          autocomplete="off" v-model="user.dni" :disabled="isFetched"/>
      </div>
      <div style="margin-bottom:15px" v-if="user && user.dataGeneral && user.dataGeneral.nombres   ">
        <input required  @input="onInputChange" class="form-control col-lg-12" type="text" disabled="true"
          autocomplete="off" v-model="user.dataGeneral.nombres" />
      </div>
      <div style="margin-bottom:15px" v-if="user && user.dataGeneral && user.dataGeneral.nacimiento &&!isRegister ">
        <input required  @input="onInputChange" class="form-control col-lg-12" type="text" disabled="true"
          autocomplete="off" v-model="user.dataGeneral.nacimiento" />
      </div>
      <div style="margin-bottom:15px" v-if="user && user.dataGeneral && user.dataGeneral.sexo &&!isRegister ">
        <input required  @input="onInputChange" class="form-control col-lg-12" type="text" disabled="true"
          autocomplete="off" v-model="sexo" />
      </div>
      <div style="margin-bottom:15px" v-if="user && user.dataGeneral && user.dataGeneral.estadocivil &&!isRegister ">
        <input required  @input="onInputChange" class="form-control col-lg-12" type="text" disabled="true"
          autocomplete="off" v-model="user.dataGeneral.estadocivil" />
      </div>
      <MazPhoneNumberInput  v-if="isFetched" type="tel" default-country-code="EC" style="width: 100%; margin-bottom:15px" no-flags no-example required show-code-on-list
            size="sm" v-model="user.dataGeneral.telefono"  @update="results = $event" :translations="{
            countrySelector: {
                placeholder: 'Código',
                error: 'Elija un pais',
            },
            phoneInput: {
                placeholder: 'Nro. celular',
                example: 'Ejemplo:',
            },
            }" />

      <div style="margin-bottom:15px"   v-if="isFetched">
        <input required  placeholder="Ingrese su correo electrónico"  @input="onInputChange" class="form-control col-lg-12" type="email"
           v-model="user.dataGeneral.email" />
      </div>


      
  
      <div class="text-center" style="margin-bottom:15px" v-if="!isFetched">
        <argon-button v-if="!lengthCedula" class="mt-1" variant="gradient" color="success" style="width: 100%;" type="submit" size="lg" disabled >
          <p  style="margin: 0; color: white; font-size: 14px; font-weight: 700;" >Consultar</p>
        </argon-button>
        <argon-button v-else class="mt-1" variant="gradient" color="success" style="width: 100%;" type="submit" size="lg"  >
          <div v-if="isLoading"  style="display: flex; justify-content: center; align-items: center;">
            <i class="fas fa-spinner fa-pulse" style="margin-right: 8px;"></i>
            <p  style="margin: 0; color: white; font-size: 14px; font-weight: 700;">Espere...</p>
          </div>
          <p v-else style="margin: 0; color: white; font-size: 14px; font-weight: 700;" >Consultar</p>
        </argon-button>
      </div>
      <div class="text-center" style="margin-bottom:15px" v-if="isFetched">
        <argon-button v-if="!emailValid" class="mt-1" variant="gradient" color="success" style="width: 100%;" type="submit" size="lg" disabled>
          <p style="margin: 0; color: white; font-size: 14px; font-weight: 700;">Registrarme</p>  
        </argon-button>
        <argon-button v-else class="mt-1" variant="gradient" color="success" style="width: 100%;" type="submit" size="lg">
          <div v-if="isLoading"  style="display: flex; justify-content: center; align-items: center;">
            <i class="fas fa-spinner fa-pulse" style="margin-right: 8px;"></i>
            <p  style="margin: 0; color: white; font-size: 14px; font-weight: 700;">Espere...</p>
          </div>
          <p v-else style="margin: 0; color: white; font-size: 14px; font-weight: 700;">Registrarme</p>  
        </argon-button>
      </div>
    </form>
  
  
  
    <p class="mt-3" style="cursor: pointer; text-align: center;margin-bottom: 5px;font-size: 12px;" v-if="!isRegister" @click="toggleForm">¿Ya tienes una cuenta? ¡Inicia sesión!</p>
    <p class="mt-3" style="cursor: pointer; text-align: center;margin-bottom: 5px;font-size: 12px;" v-else @click="navigateLogin">¿Ya tienes una cuenta? ¡Inicia sesión!</p>
  </template>
    
    
  <script>
  /*eslint-disable */
  import ArgonButton from "@/components/ArgonButton.vue";
  import firebase from 'firebase/compat/app'
  import 'firebase/compat/auth'
  import 'firebase/compat/app-check'
  import { VueFinalModal } from 'vue-final-modal'
  import 'vue-final-modal/style.css'
  
  export default {
    name: "formularioRegistro",
    components: {
      ArgonButton, VueFinalModal
    },
    props: ['promoCode', 'textoButton', 'isLogin', 'isRegister'],
    data() {
      return {
        user: {
          dni: '',
          dataGeneral: {}
        },
        isDisabled: false,
        showTerminos: false,
        countryCode: '',
        countryCode: "",
        userData: null,
        tokenAuth: null,
        token: null,
        installEvent: null,
        appCheck: null,
        isActive: true,
        isFetched: false,
        isLoading: false,
        showTerminos: false,
        acceptedLaw: false,
      }
    },
    watch: {
  
  
  
    },
  
    computed: {
        sexo() {
            if (this.user && this.user.dataGeneral && this.user.dataGeneral.sexo) {
                return this.user.dataGeneral.sexo === "1" ? "Masculino" : "Femenino";
            }
            return ""; // Return a default or empty string if user or user.dataGeneral is not defined
        },
        lengthCedula(){
          return this.user.dni.length === 10
        },
        emailValid() {
          return this.isValidEmail(this.user.dataGeneral.email)
        },
    },
  
    
    async mounted() {
      firebase.auth().useDeviceLanguage();
  
      this.appCheck = firebase.appCheck()
  
  
      this.appCheck.activate(
        '6LcmjzApAAAAALWdBKUfCTTv_cwg2KXPqGESp5TD',
  
        // Optional argument. If true, the SDK automatically refreshes App Check
        // tokens as needed.
        true,
      )
  
  
      let tokenAux = await this.appCheck.getToken();
  
      this.token = tokenAux.token;
  
      console.log('Token firebase');
  
      console.log(this.token);
      this.tokenAuth = this.token
  
      this.appCheck.setTokenAutoRefreshEnabled(true)

      if(this.promoCode){
      console.log('esto es para referir: ' + this.promoCode  );
      return
    }else{
      console.log('PromoCode vacio');
    }

  
    },
    methods: {

      isValidEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email.toLowerCase());
      },

      navigateLogin(){
        this.$router.push({ name: 'Dashboard' })
      },
  
      onInputChange() {
        this.user.dni = this.user.dni.replace(/\D/g, '').slice(0, 10);
      },
  
      contrasena() {
  
        this.isDisabled = false;
        this.password = "";
        this.showModal = false;
        this.showModalContraseña = true;
  
      },
  
  
  
      closed() {
        this.showModalContraseña = false;
  
  
      },
  
      registrar(){
        this.showTerminos = true
      },
  
      autenticacion() {
  
        const userEncripted = this.$encryptBackoffice.encrypt(this.user.contrasena)
  
  
        let datos = {
          dni: this.user.dni,
          password: userEncripted,
          tokenAuth: this.tokenAuth
        }
  
        this.$https.post('/acceso/login', datos).then(response => {
  
          console.log(response);
  
        }).catch(error => {
  
          this.$toast.error("Ocurrió un error inesperado", {
            position: "top-right",
            max: 10
          })
  
        })
  
      },

      buscarDatos(){
        const url = 'https://us-central1-xtrim-referidos.cloudfunctions.net/consulDatos/api/v1/getCedulaDetalladaXtrim';
        const body = {
          dni: this.user.dni // Replace with dynamic value as needed
        };
        this.isLoading = true
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body)
        })
        .then(async response => {
          if (!response.ok) {
            this.isLoading = false
            throw new Error('Network response was not ok ' + response.statusText);
          }else{
            return response.json();
          }
        })
        .then(data => {
         if(data.code === 204){
            throw new Error(data.message);
          }else{
            this.isFetched = true
            this.isLoading = false
            this.user.dataGeneral = {...data.dataGeneral, email:'', telefono:''}; // Store the data in the component's data property
        }})
        .catch(error => {
          this.isLoading = false
          this.$toast.error(error, {
            position: "top-right",
            max: 10,
          });
          console.error('There has been a problem with your fetch operation:', error);
          // Handle the error
        });
      
      },


      registrarUser(){
        const url = 'https://us-central1-xtrimpro-mlm.cloudfunctions.net/consultas/api/v1/acceso/getRegistrarUser';
        
        this.isLoading = true
        const body = {
            "promoCode": this.promoCode ?? "",
            "dni": this.user.dni,
            "displayName": this.user.dataGeneral.nombres,
            "email": this.user.dataGeneral.email,
            "estadoCivil": this.user.dataGeneral.estadocivil,
            "nacimiento": this.user.dataGeneral.nacimiento,
            "phone": this.user.dataGeneral.telefono,
            "tokenAuth": this.tokenAuth
        };

        console.log(body);

        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body)
        })
        .then(response => {
          if (!response.ok) {
            this.isLoading = false
            throw new Error('Network response was not ok ' + response.statusText);
          }
          return response.json();
        })
        .then(data => {
            if(data?.code === 200){
              this.toggleForm()
              this.acceptedLaw = true
              this.showTerminos = false
              if(this.isRegister){
                setTimeout(() => {
                  this.navigateLogin()
                }, 1000);
              }
              this.$toast.success("Te has registrado exitosamente, inicia sesión con tu clave temporal", {
                position: "top-right",
                duration: 10000,
                max: 10,
              })
            }else{
              this.$toast.error(data.message, {
              position: "top-right",
              max: 10,
            })
            }
            this.isLoading = false

        })
        .catch(error => {
          console.error('There has been a problem with your fetch operation:', error);
          // Handle the error
          this.isLoading = false

        });
      },

      submitForm() {
        if(this.isFetched){
          this.registrar();
        }else{
          this.buscarDatos();
        }
      },
  
      toggleForm() {
        console.log("Entrando")
        this.$emit('toggle-form');
      },
  
    },

    

    created() {
  
  
    },
    beforeUnmount() {
  
    },
  };
  </script>
    
  <style scoped>
  .bg-gradient-success {
    background-image: linear-gradient(310deg, #591361 0%, #7d0c7e 100%);
  
  }
  
  
  
  
  .m-input.--md .m-input-input,
  .m-input.--md .m-input-label {
    font-size: 12px !important;
    line-height: 1.5rem;
  }
  
  .btn:disabled,
  .btn.disabled,
  fieldset:disabled .btn {
    cursor: not-allowed;
    pointer-events: auto;
  }
  </style>
    