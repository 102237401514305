<template>
  <VueFinalModal
    v-model="showInfoModal"
    :esc-to-close="true"
    :click-to-close="true"
    :prevent-click="false"
    class="modal-container"
    content-class="modal-content"
  >
    <div style="padding: 20px">
      <p
        style="
          font-size: 16px;
          font-weight: 600;
          color: #7d0c7e;
          margin: 10px 0;
        "
      >
        {{ list.displayName }}
      </p>

      <p
        style="
          font-size: 14px;
          font-weight: 600;
          color: #67748e;
          margin: 10px 0;
        "
      >
        Código: {{ list.multiLevelNumber }}
      </p>
      <p
        style="
          font-size: 14px;
          font-weight: 600;
          color: #67748e;
          margin: 10px 0;
        "
      >
        Teléfono: {{ list.phone }}
      </p>
      <p
        style="
          font-size: 14px;
          font-weight: 600;
          color: #67748e;
          margin: 10px 0;
        "
      >
        Correo: {{ list.email }}
      </p>
      <p
        style="
          font-size: 14px;
          font-weight: 600;
          color: #67748e;
          margin: 10px 0;
        "
      >
        Ventas Período Actual: {{ list.ventasPeriodo }}
      </p>
    </div>
  </VueFinalModal>
  <div style="display: flex; margin-top: 20px; width: max-content">
    <div
      style="
        width: 20px;
        height: 20px;
        border-left: 1px solid #c2c2c2;
        border-bottom: 1px solid #c2c2c2;
      "
    ></div>
    <div style="cursor: pointer" class="modal-content4">
      <div style="display: flex; align-items: center" @click="toggle">
        <span v-if="list.children && list.children.length > 0">
          <i
            :class="{ 'fas fa-folder-open': isOpen, 'fas fa-folder': !isOpen }"
          ></i>
        </span>
        <p style="margin: 0; color: #67748e; font-size: 14px">
          {{ list.displayName }}
        </p>
      </div>
      <div
        v-if="list.ventasPeriodo > 0"
        style="
          font-size: 15px;
          width: 20px;
          height: 20px;
          background-color: #7d0c7e;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100%;
          margin-left: 12px;
        "
      >
        <span style="color: white; font-weight: 600">{{
          list.ventasPeriodo
        }}</span>
      </div>
      <div style="font-size: 20px; margin: 0 12px" @click="toggleInfoModal">
        <i class="fa-solid fa-circle-info"></i>
      </div>
    </div>

    <div v-if="isOpen">
      <PromoterList
        v-for="child in list.children"
        :key="child.userId"
        :list="child"
      />
    </div>
  </div>
</template>

<script>
import { VueFinalModal } from "vue-final-modal";
import "vue-final-modal/style.css";

export default {
  name: "PromoterList",
  components: { VueFinalModal },
  props: {
    list: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      showInfoModal: false,
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    toggleInfoModal() {
      this.showInfoModal = !this.showInfoModal;
    },
  },
  mounted() {},
};
</script>

<style scoped>
.fas {
  margin-right: 5px;
}
</style>
