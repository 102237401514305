import { createApp } from "vue";

import "maz-ui/css/main.css";

import "vue-select/dist/vue-select.css";

import App from "./App.vue";
import store from "./store";
import keys from "@/store/keys";
import router from "./router";
import API from "@/store/axios";
import VueGoogleMaps from "@fawmi/vue-google-maps";

import PinInput from "v-pin-input";
import ArgonDashboard from "./argon-dashboard";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import LoadScript from "vue-plugin-load-script";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import Toaster from "@meforma/vue-toaster";
import { createVfm } from "vue-final-modal";
const vfm = createVfm();
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import "vue3-organization-chart/dist/orgchart.css";
import { JSEncrypt } from "jsencrypt";
import { Skeletor } from "vue-skeletor";
import ReadMore from "vue-read-more";
import VLazyImage from "v-lazy-image";
import vSelect from "vue-select";
import "./registerServiceWorker";
import VueCleave from "vue-cleave-component";
import Emittery from "emittery";

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

const appFirebase = firebase.initializeApp({
  apiKey: "AIzaSyA8olDYKiEr2k0VALt-X5j1c_XMEhZ8nbA",
  authDomain: "xtrimpro-mlm.firebaseapp.com",
  projectId: "xtrimpro-mlm",
  storageBucket: "xtrimpro-mlm.appspot.com",
  messagingSenderId: "692581581501",
  appId: "1:692581581501:web:fa14c3b3949f035d3badd4",
});
const auth = firebase.auth();
auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

const encryptBackoffice = new JSEncrypt();
encryptBackoffice.setPublicKey(keys.publicKeyBackoffice);

const decryptBackoffice = new JSEncrypt();
decryptBackoffice.setPublicKey(keys.publicKeyBackoffice2);

const ua = navigator.userAgent;

let isMobile = false;

if (
  /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
    ua
  )
) {
  //es mobile
  isMobile = true;
} else {
  //es destopk
  isMobile = false;
}

const appInstance = createApp(App);
appInstance.component("MazPhoneNumberInput", MazPhoneNumberInput);
appInstance.config.globalProperties.$appFirebase = appFirebase;

appInstance.config.globalProperties.$encryptBackoffice = encryptBackoffice;
appInstance.config.globalProperties.$decryptBackoffice = decryptBackoffice;
appInstance.config.globalProperties.$https = API;
appInstance.config.globalProperties.$isMobile = isMobile;
appInstance.config.globalProperties.$eventBus = new Emittery();
appInstance.use(store);
appInstance.use(router);
appInstance.use(ReadMore);

appInstance.use(VueCleave);
appInstance.use(LoadScript);
appInstance.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyD57jTsIwzvDmNZ5I2zh1YkqKfOm7HWwpA",
    libraries: "places",
    async: true,
    defer: true,
    v: "weekly",
  },
});

appInstance.component("v-select", vSelect);
appInstance.component("v-lazy-image", VLazyImage);
appInstance.component(Skeletor.name, Skeletor);
appInstance.component("VueDatePicker", VueDatePicker);
appInstance.use(VueSweetalert2);
appInstance.use(Toaster);
appInstance.use(vfm);
appInstance.use(PinInput);

appInstance.use(ArgonDashboard);
appInstance.mount("#app");
