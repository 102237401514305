<template>
  <h5 class="mt-3" style="text-align: center; margin-bottom: 15px">
    Completar Perfil
  </h5>

  <p class="text-center" style="font-size: 16px; margin-bottom: 16px">
    Actualiza y confirma tu Contraseña
  </p>

  <form role="form" autocomplete="off" @submit.prevent="registerPassword">
    <div style="margin-bottom: 15px">
      <argon-input
        type="password"
        placeholder="Nueva contraseña"
        :value="password"
        @input="(event) => (localPassword = event.target.value)"
      />
    </div>

    <div style="margin-bottom: 15px">
      <argon-input
        type="password"
        placeholder="Confirmar nueva contraseña"
        :value="confirmPassword"
        @input="(event) => (localConfirmPassword = event.target.value)"
      />
    </div>

    <p
      style="
        color: red;
        font-weight: 600;
        margin-bottom: 16px;
        text-align: start;
      "
      v-if="!passwordValid"
    >
      La Contraseña no coincide
    </p>
    <p
      style="
        color: green;
        font-weight: 600;
        margin-bottom: 16px;
        text-align: start;
      "
      v-if="passwordValidNoEmpty"
    >
      La Contraseña está confirmada
    </p>

    <div class="text-center" style="margin-bottom: 15px">
      <argon-button
        :disabled="!passwordsMatch"
        class="btn"
        id="btn_ingreso"
        variant="gradient"
        color="success"
        style="width: 100%"
        type="submit"
        size="lg"
        >Cambiar Contraseña</argon-button
      >
    </div>
  </form>

  <!-- <p
    class="mt-3"
    style="
      cursor: pointer;
      text-align: center;
      margin-bottom: 5px;
      font-size: 12px;
    "
    @click="toggleForm"
  >
    ¿Aún no tienes una cuenta? ¡Regístrate!
  </p> -->
</template>

<script>
/*eslint-disable */
import ArgonButton from "@/components/ArgonButton.vue";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/app-check";
import { VueFinalModal } from "vue-final-modal";
import "vue-final-modal/style.css";
import ArgonInput from "@/components/ArgonInput.vue";

export default {
  name: "formularioUpdate",
  components: {
    ArgonButton,
    VueFinalModal,
    ArgonInput,
  },
  props: [
    "promoCode",
    "textoButton",
    "isLogin",
    "userId",
    "password",
    "confirmPassword",
  ],
  data() {
    return {
      localPassword: this.password,
      localConfirmPassword: this.confirmPassword,
      isDisabled: false,
      countryCode: "",
      countryCode: "",
      userData: null,
      tokenAuth: null,
      token: null,
      installEvent: null,
      appCheck: null,
      isActive: true,
    };
  },
  watch: {},
  computed: {
    passwordsMatch() {
      return (
        this.localPassword !== "" &&
        this.localPassword === this.localConfirmPassword
      );
    },
    passwordValid() {
      return this.localPassword === this.localConfirmPassword;
    },
    passwordValidNoEmpty() {
      return (
        this.localPassword === this.localConfirmPassword &&
        this.localPassword.length > 0
      );
    },
  },
  async mounted() {
    firebase.auth().useDeviceLanguage();

    this.appCheck = firebase.appCheck();

    this.appCheck.activate(
      "6LcmjzApAAAAALWdBKUfCTTv_cwg2KXPqGESp5TD",

      // Optional argument. If true, the SDK automatically refreshes App Check
      // tokens as needed.
      true
    );

    let tokenAux = await this.appCheck.getToken();

    this.token = tokenAux.token;
    this.tokenAuth = this.token;

    this.appCheck.setTokenAutoRefreshEnabled(true);
  },
  methods: {
    onInputChange() {
      this.user.dni = this.user.dni.replace(/\D/g, "").slice(0, 10);
    },

    contrasena() {
      this.isDisabled = false;
      this.password = "";
      this.showModal = false;
      this.showModalContraseña = true;
    },

    closed() {
      this.showModalContraseña = false;
    },

    registerPassword(event) {
      event.preventDefault();

      // Define the user data for the POST request
      const userId_json = { idUser: this.userId, password: this.localPassword };
      const user_string = JSON.stringify(userId_json);
      const userEncripted = this.$encryptBackoffice.encrypt(user_string);

      // Change button content to show loading state
      document.getElementById("btn_ingreso").innerHTML =
        '<i class="fas fa-spinner fa-pulse"></i> Espere...';

      // Execute the POST request
      this.$https
        .post("/acceso/loginPassword/", {
          tokenAuth: this.tokenAuth,
          datos: userEncripted,
        })
        .then((response) => {
          // Handle successful response
          // Example: Show a success message or redirect the user

          // Reload the page after a delay
          this.$toast.success("Contraseña cambiada exitosamente", {
            position: "top-right",
            max: 10,
          });
          document.getElementById("btn_ingreso").innerHTML =
            "Cambiar contraseña";
          setTimeout(() => {
            this.$router.push({ name: "Dashboard" });
          }, 500);
        })
        .catch((error) => {
          this.isDisabled = false;
          document.getElementById("btn_ingreso").innerHTML =
            "Cambiar contraseña";
          // Handle error response
          // Example: Show an error message
          this.$toast.error(error, {
            position: "top-right",
            max: 10,
          });
        });
    },

    toggleForm() {
      this.$emit("toggle-form");
    },
  },

  created() {},
  beforeUnmount() {},
};
</script>

<style scoped>
.bg-gradient-success {
  background-image: linear-gradient(310deg, #591361 0%, #7d0c7e 100%);
}

.m-input.--md .m-input-input,
.m-input.--md .m-input-label {
  font-size: 12px !important;
  line-height: 1.5rem;
}
.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
