<template>
  <div class="card" style="margin-bottom: 10px">
    <div class="card-body">
      <div class="row">
        <div class="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11">
          <p style="margin: 10px; font-weight: bold">
            <i style="margin-right: 10px" class="fas fa-list"></i> Mis
            Prospectos
          </p>
        </div>

        <div
          class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1"
          align="center"
          style="display: flex"
        >
          <Skeletor
            v-if="loading"
            :shimmer="true"
            height="20"
            width="100%"
            style="margin: auto"
          />
          <div v-else @click="refresh">
            <i
              style="cursor: pointer; margin: auto"
              class="fas fa-sync text-sm opacity-10"
            ></i>
          </div>
        </div>
      </div>

      <div
        v-if="loading"
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        style="padding: 0px"
      >
        <div class="row">
          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <Skeletor :shimmer="true" height="40" width="50%" />
          </div>

          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <Skeletor
              :shimmer="true"
              height="10"
              pill
              width="30%"
              style="margin-bottom: 10px"
            />
            <Skeletor :shimmer="true" height="40" width="100%" />
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <table class="table tablesorter">
              <thead class="thead-light">
                <tr>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                </tr>
                <tr>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                </tr>
                <tr>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
            <Skeletor :shimmer="true" height="10" pill width="80%" />
          </div>

          <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <div class="row">
              <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <Skeletor :shimmer="true" height="10" width="100%" />
              </div>

              <div
                class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"
                align="center"
              >
                <Skeletor :shimmer="true" circle size="30" />
              </div>

              <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <Skeletor :shimmer="true" height="10" width="100%" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <DataTable
        v-else
        :columns="columns"
        :options="options"
        :data="leads"
        class="table tablesorter"
        width="100%"
      >
        <thead>
          <tr>
            <th data-priority="3">Fecha/Hora</th>
            <th data-priority="1">Nombre Completo</th>
            <th>Celular</th>
            <th data-priority="2">Estado</th>
            <!-- <th data-priority="2">Acción</th> -->
          </tr>
        </thead>
      </DataTable>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-bs5";
import "datatables.net-responsive";
import $ from "jquery";

DataTable.use(DataTablesCore);

export default {
  components: {
    DataTable,
  },
  props: ["userId", "tokenAuth"],
  data() {
    return {
      loading: true,
      leads: [],
      geocoder: null,
      idLead: "",
      showModal: false,
      columns: [
        { data: "dateFormated" },
        { data: "displayNameReferral" },
        { data: "phoneReferral" },
        {
          data: null,
          render: function (data) {
            return `<p style="font-size: 12px; font-weight: 600; margin: 0">${
              !data.converted
                ? '<span style="color: #7d0c7e;">En Proceso</span>'
                : '<span style="color: #19b251;">Convertido</span>'
            }</p>`;
          },
        },
      ],
      options: {
        responsive: true,
        ordering: false,
        lengthMenu: [
          [5, 15, 25, 35, 50, -1],
          [5, 15, 25, 35, 50, "All"],
        ],
        pageLength: 5,
        language: {
          search: "Buscar:",
          sInfo:
            "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
          sInfoEmpty:
            "Mostrando registros del 0 al 0 de un total de 0 registros",
          searchPlaceholder: "Ingrese algún dato",
          zeroRecords: "Lo sentimos, no se encontraron resultados",
          processing: "Buscando. Espere por favor...",
          sLengthMenu: "Mostrar _MENU_ registros",
          oPaginate: {
            sFirst: "Primero",
            sLast: "Último",
            sNext: "Siguiente",
            sPrevious: "Anterior",
          },
        },
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getListado();
  },
  methods: {
    refresh() {
      $(document).off("click", ".consultar");
      this.$eventBus.emit("reiniciarListadoLeads");
    },

    getListado() {
      console.log("el body", {
        tokenAuth: this.tokenAuth,
        userId: this.userId,
      });
      this.$https
        .post("/ventas/getUserLeads", {
          tokenAuth: this.tokenAuth,
          userId: this.userId,
        })
        .then((response) => {
          if (response.data.code == 200) {
            this.leads = response.data.leads;
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log("error", error);
          if (error.response.data.code === 401) {
            localStorage.removeItem("userData");
            this.$store.state.user = "";
            console.log("Entró al error ", response.data.code);
            // Redirect to login page
            this.$router.push({ name: "login" });
          }
          // this.getListado();
          console.log("Entró al error ", error);
        });
    },
  },
};
</script>

<style lang="scss">
@import "datatables.net-dt";
@import "datatables.net-responsive-dt";
</style>
