<template>
  <div class="row" style="padding-left: 10px; padding-right: 10px">
    <div class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12" align="center">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12 text-center">
          <h6
            style="
              font-weight: bold;
              margin-bottom: 12px;
              line-height: 15px;
              font-size: 16px;
              color: black;
            "
            class="text-center"
          >
            Emite tu factura de la siguiente forma
          </h6>
          <h5
            style="
              font-weight: bold;
              margin-bottom: 20px;
              line-height: 15px;
              font-size: 12px;
              color: grey;
            "
          >
            Fecha máxima de facturación
            {{ this.maxBillingDate }}
          </h5>
          <h5
            style="
              font-weight: bold;
              margin-bottom: 20px;
              line-height: 15px;
              font-size: 12px;
              color: #333333;
            "
          >
            Haz click en los
            <span class="field" style="font-weight: 400">campos</span> para
            copiarlos
          </h5>
        </div>
        <div class="invoice-container">
          <div class="invoice-header">
            <div class="company-info">
              <div class="input-group">
                <p style="margin: 0; font-weight: 600">
                  Razón social/Persona natural
                </p>

                <p style="margin: 0">RUC: XXXXXXXX001</p>
              </div>
            </div>
            <div class="invoice-info">
              <h6 style="margin-bottom: 0; font-size: 12px">Factura</h6>
              <p
                style="
                  font-size: 10px;
                  margin-bottom: 0;
                  text-wrap: nowrap;
                  padding-left: 8px;
                  padding-right: 8px;
                "
              >
                No. 001-001-XXXXXXXX
              </p>
            </div>
          </div>

          <div class="client-info">
            <div class="company-data-2-fields">
              <p style="margin: 0" @click="copyField(invoiceDetails.name)">
                <strong>Cliente:</strong>
                <span class="field">{{ invoiceDetails.name }}</span>
              </p>

              <p style="margin: 0" @click="copyField(invoiceDetails.ruc)">
                <strong>RUC:</strong>
                <span class="field">{{ invoiceDetails.ruc }}</span>
              </p>
            </div>
            <div class="address">
              <p style="margin: 0"><strong>Dirección:</strong></p>
              <p
                style="margin: 0; text-align: start; margin-left: 6px"
                @click="copyField(invoiceDetails.address)"
                class="field"
              >
                {{ invoiceDetails.address }}
              </p>
            </div>
            <div class="company-data-2-fields">
              <p
                style="margin-bottom: 0"
                @click="copyField(invoiceDetails.email)"
              >
                <strong>Correo:</strong>
                <span class="field">{{ invoiceDetails.email }}</span>
              </p>
              <p
                style="margin-bottom: 0"
                @click="copyField(invoiceDetails.phone)"
              >
                <strong>Teléfono:</strong
                ><span class="field">{{ invoiceDetails.phone }}</span>
              </p>
            </div>
          </div>

          <div class="commissions-title">
            <p style="font-weight: 600; margin: 0 0 4px 0">Descripción</p>
            <p style="font-weight: 600; margin: 0 0 4px 0">Valor</p>
          </div>
          <div class="commissions">
            <p style="margin: 0; height: fit-content">Comisiones</p>
            <p class="field" @click="copyField(availableAmount.toFixed(2))">
              ${{ availableAmount.toFixed(2) }}
            </p>
          </div>

          <div class="totals" style="margin: 8px 0">
            <div class="value">
              <p style="margin: 0"><strong>Subtotal:</strong></p>
              <p style="margin: 0">${{ availableAmount.toFixed(2) }}</p>
            </div>
            <div class="value">
              <p style="margin: 0"><strong>IVA:</strong></p>
              <p style="margin: 0">${{ availableAmountTax.toFixed(2) }}</p>
            </div>
            <div class="value">
              <p style="margin: 0"><strong>Total:</strong></p>
              <p style="margin: 0">${{ totalInvoiceValue.toFixed(2) }}</p>
            </div>
          </div>
        </div>
        <div
          class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12 text-center"
          style="padding-left: 0; padding-right: 0"
        >
          <button
            class="btn btn-primary btn-sm"
            style="width: 100%"
            @click="executeCloseMethod"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Saldo from "./Saldo.vue";

export default {
  components: {
    Saldo,
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
    tokenAuth: {
      type: String,
      required: true,
    },
    closeMethod: Function, // Adding a new prop for the close method
    availableAmount: {
      type: Number,
    },
  },
  data() {
    return {
      isDisable: true,
      loading: true,
      retiroCash: "",
      cashDisponible: "0",
      number: {
        prefix: "$",
        numeral: true,
        numeralPositiveOnly: true,
        noImmediatePrefix: true,
        rawValueTrimPrefix: true,
        numeralIntegerScale: 9,
        numeralDecimalScale: 2,
      },
      invoiceDetails: {
        name: "Planprocad S.A.",
        ruc: "0993371324001",
        address:
          "Av. de las Americas C.C. Terminal Terrestre bloque B Planta Baja local 19",
        email: "info@planproec.com",
        phone: "0978704579",
      },
      maxBillingDate: null,
    };
  },
  computed: {
    availableAmountTax() {
      return this.availableAmount * 0.15;
    },
    totalInvoiceValue() {
      return this.availableAmount * 1.15;
    },
  },
  watch: {},
  mounted() {
    this.$eventBus.on("reiniciarSaldos", () => {
      this.componentKeySaldos += 1;
    });
    this.calculateMaxBillingDate();
    this.getSaldo();
  },
  methods: {
    executeCloseMethod() {
      if (typeof this.closeMethod === "function") {
        this.closeMethod(); // Execute the passed method
      }
    },
    copyField(fieldValue) {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(fieldValue)
          .then(() => {
            this.$toast.success(`${fieldValue} copiado exitosamente.`, {
              position: "top-right",
              max: 10,
            });
          })
          .catch((err) => {
            console.error("No se pudo copiar el campo: ", err);
            this.$toast.error("Falla al copiar el campo.", {
              position: "top-right",
              max: 10,
            });
          });
      } else {
        console.error("Clipboard API not available.");
        this.$toast.error("Clipboard functionality not available.", {
          position: "top-right",
          max: 10,
        });
      }
    },
    // calculateMaxBillingDate() {
    //   // Get current date
    //   const currentDate = new Date();

    //   // Get next month
    //   const nextMonth = currentDate.getMonth() + 1;

    //   // Get year for next month
    //   const year =
    //     nextMonth === 12
    //       ? currentDate.getFullYear() + 1
    //       : currentDate.getFullYear();

    //   // Maximum billing date for next month (05th day of next month)
    //   const maxBillingDate = new Date(year, nextMonth, 5);

    //   // Format the date as DD/MM/YYYY
    //   this.maxBillingDate = `${maxBillingDate
    //     .getDate()
    //     .toString()
    //     .padStart(2, "0")}/${(maxBillingDate.getMonth() + 1)
    //     .toString()
    //     .padStart(2, "0")}/${maxBillingDate.getFullYear()}`;
    // },

    calculateMaxBillingDate() {
      // Get current date
      const currentDate = new Date();
      const currentDay = currentDate.getDate();

      // Check if today is after the 5th of the month
      if (currentDay > 5) {
        // Get next month
        const nextMonth = currentDate.getMonth() + 1;
        // Get year for next month
        const year =
          nextMonth === 12
            ? currentDate.getFullYear() + 1
            : currentDate.getFullYear();
        // Maximum billing date for next month (05th day of next month)
        const maxBillingDate = new Date(year, nextMonth, 5);
        // Format the date as DD/MM/YYYY
        this.maxBillingDate = `${maxBillingDate
          .getDate()
          .toString()
          .padStart(2, "0")}/${(maxBillingDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}/${maxBillingDate.getFullYear()}`;
      } else {
        // Maximum billing date for current month (05th day of current month)
        const maxBillingDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          5
        );
        // Format the date as DD/MM/YYYY
        this.maxBillingDate = `${maxBillingDate
          .getDate()
          .toString()
          .padStart(2, "0")}/${(maxBillingDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}/${maxBillingDate.getFullYear()}`;
      }
    },

    // copyField(fieldValue) {
    //   if (navigator.clipboard) {
    //     navigator.clipboard
    //       .writeText(fieldValue)
    //       .then(() => {
    //         // Success message
    //         alert(`${fieldValue} copied to clipboard.`); // Replace with your toast or alert system
    //       })
    //       .catch((err) => {
    //         // Handle errors if the copy fails
    //         console.error("Could not copy text: ", err);
    //         alert("Failed to copy."); // Replace with your toast or alert system
    //       });
    //   } else {
    //     // Clipboard API not available
    //     console.error("Clipboard API not available.");
    //     alert("Clipboard functionality not available."); // Replace with your toast or alert system
    //   }
    // },
    // onBlurMount() {
    //   console.log(this.retiroCash);

    //   if (
    //     this.retiroCash == null ||
    //     this.retiroCash == "null" ||
    //     this.retiroCash == ""
    //   ) {
    //     this.retiroCash = "";
    //   } else {
    //     if (Number(this.retiroCash) === Number("0")) {
    //       this.retiroCash = "";

    //       this.$toast.error("El monto del retiro no puede ser igual a cero", {
    //         position: "top-right",
    //         max: 10,
    //       });
    //     }
    //   }
    // },

    getSaldo() {
      const userIdEncripted = this.$encryptBackoffice.encrypt(this.userId);

      // this.$https
      //   .post("/", { tokenAuth: this.tokenAuth, userId: userIdEncripted })
      //   .then((response) => {
      //     // this.$https.post('/profile/getBalance/', { tokenAuth: this.tokenAuth, userId: userIdEncripted }).then(response => {

      //     if (response.data.code == 200) {
      //       this.loading = false;
      //       (this.isDisable = false),
      //         (this.cashDisponible = response.data.balance);
      //     } else {
      //       if (response.data.code == 401) {
      //         localStorage.removeItem("userData");
      //         this.$store.state.user = "";

      //         // Redirect to login page
      //         this.$router.push({ name: "login" });
      //       } else {
      //         this.getSaldo();
      //       }
      //     }
      //   })
      //   .catch((error) => {
      //     this.getSaldo();
      //   });
    },
    // sendCash(event) {
    //   event.preventDefault();

    //   if (this.retiroCash === "") {
    //     this.$toast.error("El monto de retiro no puede estar vacío", {
    //       position: "top-right",
    //       max: 10,
    //     });
    //     this.retiroCash = "";
    //   } else {
    //     if (Number(this.retiroCash) > Number(this.cashDisponible)) {
    //       this.$toast.error(
    //         "Lo sentimos, el monto que desea retirar supera a su saldo disponible",
    //         {
    //           position: "top-right",
    //           max: 10,
    //         }
    //       );
    //       this.retiroCash = "";
    //     } else {
    //       this.$swal({
    //         title:
    //           "¿Seguro que deseas hacer un retiro por $" +
    //           this.retiroCash +
    //           "?",
    //         icon: "warning",
    //         width: "400px",
    //         showCancelButton: true,
    //         confirmButtonText: "Confirmar",
    //         cancelButtonText: "Cancelar",
    //         customClass: {
    //           confirmButton: "btn btn-primary",
    //           cancelButton: "btn btn-outline-secondary ml-1",
    //         },
    //         buttonsStyling: false,
    //         showLoaderOnConfirm: true,
    //         preConfirm: () => {
    //           this.isDisable = true;

    //           const userId_json = {
    //             userId: this.userId,
    //             retiroCash: this.retiroCash,
    //           };
    //           const user_string = JSON.stringify(userId_json);

    //           const dataEncripted =
    //             this.$encryptBackoffice.encrypt(user_string);

    //           return this.$https
    //             .post("/banks/retirarCash/", {
    //               tokenAuth: this.tokenAuth,
    //               datos: dataEncripted,
    //             })
    //             .then((response) => response.data)
    //             .catch((error) => {
    //               this.$swal.showValidationMessage(error);
    //             });
    //         },
    //         allowOutsideClick: () => !this.$swal.isLoading(),
    //       }).then((result) => {
    //         this.$swal.close();

    //         if (result.isConfirmed) {
    //           this.isDisable = false;

    //           if (result.value.code == 200) {
    //             this.$toast.success(result.value.message, {
    //               position: "top-right",
    //               max: 10,
    //             });

    //             this.$eventBus.emit("closeModalRetiro");
    //             this.$eventBus.emit("reiniciarSaldos");
    //           } else {
    //             if (result.value.code == 401) {
    //               localStorage.removeItem("userData");
    //               this.$store.state.user = "";

    //               // Redirect to login page
    //               this.$router.push({ name: "login" });
    //             } else {
    //               this.$toast.error(result.value.message, {
    //                 position: "top-right",
    //                 max: 10,
    //               });

    //               if (result.value.code == 600) {
    //                 this.retiroCash = "";
    //               }
    //             }
    //           }
    //         }
    //       });
    //     }
    //   }
    // },
  },
};
</script>

<style scoped>
.invoice-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  margin-bottom: 20px;
}

.invoice-header {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 12px; */
}

/* .company-info, */

.commissions,
.invoice-info,
.totals {
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  padding: 4px;
  margin-bottom: 10px;
}

.client-info {
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.invoice-info {
  text-align: center;
  height: fit-content;
}

.input-group {
  display: flex;
  flex-direction: column;
}

.input-group p .input-group h6 {
  font-size: 12px;
  font-weight: 600;
  display: block;
}

.commissions,
.totals,
.commissions-title {
  display: flex;
  padding: 0 8px;
}

.commissions {
  justify-content: space-between;
  align-items: center;
}
.commissions-title {
  justify-content: space-between;
}

.totals {
  text-align: right;
  flex-direction: column;
  width: fit-content;
  align-self: flex-end;
  gap: 4px;
}

.value {
  display: flex;
  gap: 6px;
  width: 100%;
  justify-content: space-between;
}

.address {
  display: flex;
}

.company-data-2-fields {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  @media (max-width: 400px) {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
