<template>
  <div>
    <modal-photo
      v-if="showModalPhoto"
      :key="componentKeyModalPhoto"
      :user-id="userId"
      :token-auth="tokenAuth"
      :image="image"
      :photo="photo"
      :email="email"
      :phone="phone"
    />
    <div class="row">
      <div class="col-12 col-sm-12 col-md-2 col-lg-3 col-xl-3"></div>
      <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">
        <div class="card">
          <div class="card-body">
            <div class="row" style="margin-bottom: 15px">
              <div
                style="position: relative"
                class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12"
                align="center"
              >
                <v-lazy-image
                  :src="photo"
                  class="rounded-circle img_profile"
                  style="border: 4px solid white; width: 100px"
                />

                <input
                  type="file"
                  id="upload"
                  ref="profilePhoto"
                  accept="image/jpeg, image/jpg"
                  @change="loadImage($event)"
                  hidden
                />
                <label for="upload" class="camera_photo">
                  <i style="margin: auto" class="fas fa-camera"></i
                ></label>
              </div>

              <div
                class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12"
                align="center"
              >
                <p
                  style="
                    margin-bottom: 0px;
                    margin-top: 15px;
                    line-height: 18px;
                    text-align: center;
                    font-size: 15px;
                    font-weight: bold;
                  "
                >
                  {{ displayName }}
                </p>
                <p
                  style="
                    margin-bottom: 15px;
                    text-align: center;
                    font-size: 13px;
                  "
                >
                  {{ dni }}
                </p>

                <form role="form" autocomplete="off" @submit.prevent="onSubmit">
                  <div class="row">
                    <div
                      class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                      style="margin-bottom: 10px; text-align: left"
                    >
                      <label
                        for="example-text-input"
                        class="form-control-label"
                        style="
                          font-weight: bold;
                          font-size: 12px;
                          text-align: left;
                        "
                        >Teléfono:</label
                      >
                      <Skeletor
                        v-if="loading"
                        :shimmer="true"
                        height="40"
                        width="100%"
                      />

                      <MazPhoneNumberInput
                        type="tel"
                        country-code="EC"
                        style="width: 100%"
                        no-example
                        required
                        show-code-on-list
                        size="sm"
                        v-model="phone"
                        :noValidationSuccess="isDisabled"
                        :disabled="isDisabled"
                        :preferred-countries="['EC']"
                        @update="handleUpdate"
                        :translations="{
                          countrySelector: {
                            placeholder: '',
                            error: 'Elija un pais',
                          },
                          phoneInput: {
                            placeholder: '',
                            example: 'Ejemplo:',
                          },
                        }"
                      />
                    </div>
                    <div
                      class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                      style="margin-bottom: 10px; text-align: left"
                    >
                      <label
                        for="example-text-input"
                        class="form-control-label"
                        style="
                          font-weight: bold;
                          font-size: 12px;
                          text-align: left;
                        "
                        >Email (*):</label
                      >
                      <Skeletor
                        v-if="loading"
                        :shimmer="true"
                        height="40"
                        width="100%"
                      />
                      <input
                        :disabled="isDisabled"
                        placeholder="Ingrese su email"
                        class="form-control col-lg-12"
                        required
                        type="email"
                        autocomplete="off"
                        v-model="email"
                      />
                    </div>
                    <!-- <div
                      class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8"
                    ></div> -->

                    <div
                      class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 w-100"
                    >
                      <Skeletor
                        v-if="loading"
                        :shimmer="true"
                        height="40"
                        width="100%"
                      />
                      <div
                        class="row justify-content-end"
                        style="padding-left: 16px; padding-right: 16px"
                        v-else
                      >
                        <div
                          v-if="isEdited"
                          class="row w-100 justify-content-end gap-1"
                        >
                          <button
                            id="btn_guardar"
                            type="submit"
                            class="btn btn-primary w-auto"
                            style="
                              margin-top: 15px;
                              padding-left: 16px;
                              padding-right: 16px;
                            "
                            :disabled="btnDisabled"
                          >
                            Guardar
                          </button>
                          <button
                            type="submit"
                            class="btn btn-cancel w-auto"
                            style="
                              margin-top: 15px;
                              padding-left: 16px;
                              padding-right: 16px;
                            "
                            @click="handleCancel"
                          >
                            Cancelar
                          </button>
                        </div>
                        <div
                          class="row w-100 justify-content-end"
                          v-else-if="!isEdited"
                        >
                          <button
                            type="button"
                            class="btn btn-primary w-auto"
                            style="
                              margin-top: 15px;
                              padding-left: 24px;
                              padding-right: 24px;
                            "
                            @click="handleIsEdited"
                          >
                            Editar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-12 col-md-2 col-lg-3 col-xl-3"></div>
    </div>
  </div>
</template>

<script>
/*eslint-disable */
import ModalPhoto from "./ModalPhoto.vue";
export default {
  name: "EdicionPerfil",
  components: {
    ModalPhoto,
  },
  data() {
    let datos = JSON.parse(this.$store.state.user);
    let optionsGeneros = [
      { id: "Masculino", value: "Masculino" },
      { id: "Femenino", value: "Femenino" },
      { id: "Transgenero", value: "Transgénero" },
    ];

    let optionsEstadoCivil = [
      { id: "Soltero/a", value: "Soltero/a" },
      { id: "Divorciado/a", value: "Divorciado/a" },
      { id: "Casado/a", value: "Casado/a" },
      { id: "Viuda/o", value: "Viuda/o" },
    ];

    let photo = "";

    if (datos.photo === "") {
      photo = "/img/user.svg";
    } else {
      photo = datos.photo;
    }

    return {
      originalData: {
        email: datos.email,
        phone: datos.phone,
        photo: datos.photo, // other fields...
      },
      userId: datos.userId,
      tokenAuth: datos.tokenAuth,
      permitirEmail: false,
      dni: datos.dni,
      displayName: datos.displayName,
      photo: datos.photo,
      ciudad: "",
      showModalPhoto: false,
      optionsGeneros: optionsGeneros,
      optionsEstadoCivil: optionsEstadoCivil,
      genero: "",
      loading: false,
      estadoCivil: "",
      isDisabled: true,
      email: datos.email,
      phone: datos.phone,
      componentKeyModalPhoto: 100,
      type: "",
      image: {
        src: null,
        type: null,
      },
      results: {},
      isEdited: false,
    };
  },
  activated() {
    if (this.isMobile) {
      this.$eventBus.emit("inactiveShowMenu");
    }
  },
  methods: {
    handleIsEdited() {
      this.isEdited = !this.isEdited;
      this.isDisabled = !this.isDisabled;
    },
    handleCancel() {
      this.isEdited = !this.isEdited;
      this.isDisabled = !this.isDisabled;
      this.photo = this.originalData.photo;
      this.phone = this.originalData.phone;
      this.email = this.originalData.email;
    },
    handleUpdate(event) {
      console.log(event); // Inspect the structure of the 'event' object
      this.results = event;
    },
    getProfile() {
      //   this.loading=true;
      this.loading = false;

      //         this.$https.post('/profile/getProfile/', {tokenAuth: this.tokenAuth, userId: this.userId }).then(response => {

      //           if (response.data.code == 200) {

      //             this.email=response.data.email;
      //             this.ciudad=response.data.ciudad;
      //             this.estadoCivil=response.data.estadoCivil;
      //             this.genero=response.data.sexo;
      //             this.permitirEmail=response.data.permitirEmail;

      //               this.loading=false;

      //           } else {

      //               if (response.data.code == 401) {

      //                 localStorage.removeItem('userData')
      //                     this.$store.state.user="";

      //                 // Redirect to login page
      //                 this.$router.push({ name: 'login' })

      //               }else{
      //                 this.getProfile();

      //               }

      //           }
      //         }).catch(error => {
      //              this.getProfile();
      //         })
      //
    },
    onChange2(event) {
      this.genero = event.target.value;
    },

    onChange1(event) {
      this.estadoCivil = event.target.value;
    },

    onSubmit(event) {
      event.preventDefault();
      this.isDisabled = true;

      document.getElementById("btn_guardar").innerHTML =
        '<i class="fas fa-circle-notch fa-spin"></i> Guardando...';

      this.$https
        .post(
          "/user/getUpdatePerfilUser",
          {
            tokenAuth: this.tokenAuth,
            userId: this.userId,
            phone: this.phone,
            email: this.email,
            photo: this.photo,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.tokenAuth}`, // Replace `yourAuthToken` with your actual token
              // Add other headers here as needed
            },
          }
        )
        .then((response) => {
          document.getElementById("btn_guardar").innerHTML = "Guardar";
          this.isDisabled = false;
          if (response.data.code == 200) {
            this.$toast.success(
              "Tu imagen de perfil se ha cambiado exitosamente",
              {
                position: "top-right",
                max: 10,
              }
            );
            this.isEdited = false;
          } else {
            this.$toast.error(response.data.messag, {
              position: "top-right",
              max: 10,
            });

            if (response.data.code == 401) {
              localStorage.removeItem("userData");
              this.$store.state.user = "";

              // Redirect to login page
              this.$router.push({ name: "login" });
            }
          }
        })
        .catch((error) => {
          this.$toast.error(error, {
            position: "top-right",
            max: 10,
          });
        });
    },

    loadImage(event) {
      if (event.target.files[0].type === "image/jpeg") {
        this.type = "image/jpeg";

        const FileSizeBanner = event.target.files[0].size / 1024 / 1024;
        // let foto=event.target.files[0];

        if (FileSizeBanner > 5) {
          this.$refs.profilePhoto.value = null;

          this.$toast.error("La imagen debe pesar menos de 5mb", {
            position: "bottom-right",
            max: 10,
          });
        } else {
          // Reference to the DOM input element
          const { files } = event.target;
          // Ensure that you have a file before attempting to read it
          if (files && files[0]) {
            // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
            if (this.image.src) {
              URL.revokeObjectURL(this.image.src);
            }
            // 2. Create the blob link to the file to optimize performance:
            const blob = URL.createObjectURL(files[0]);

            // 3. The steps below are designated to determine a file mime type to use it during the
            // getting of a cropped image from the canvas. You can replace it them by the following string,
            // but the type will be derived from the extension and it can lead to an incorrect result:
            //
            // this.image = {
            //    src: blob;
            //    type: files[0].type
            // }

            // Create a new FileReader to read this image binary data
            const reader = new FileReader();
            // Define a callback function to run, when FileReader finishes its job
            reader.onload = (e) => {
              // Note: arrow function used here, so that "this.image" refers to the image of Vue component
              this.image = {
                // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
                src: blob,
                // Determine the image type to preserve it during the extracting the image from canvas:
                type: this.type,
              };
            };
            // Start the reader job - read file as a data url (base64 format)
            reader.readAsArrayBuffer(files[0]);

            this.showModalPhoto = true;
          }
        }
      } else {
        this.$refs.profilePhoto.value = null;

        this.$toast.error("El archivo debe tener una extensión JPG/JPEG", {
          position: "bottom-right",
          max: 10,
        });
      }
    },
  },
  mounted() {
    this.getProfile();

    this.$eventBus.on("reiniciarModalPhoto", () => {
      this.$refs.profilePhoto.value = null;

      this.componentKeyModalPhoto += 1;
      this.showModalPhoto = false;
    });

    this.$eventBus.on("updateFotoPerfil", (url) => {
      this.photo = url;
      this.originalData.photo = url;
    });
  },
  computed: {
    btnDisabled() {
      return this.isDisabled || !this.results.isValid;
    },
  },
};
</script>

<style scoped></style>
