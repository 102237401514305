<template>
  <div class="card" style="margin-bottom: 10px">
    <form>
      <div class="card-body">
        <div class="commissionFormContainer">
          <p style="margin: 0; font-weight: 600">
            <i class="fas fa-money-bill-transfer" style="margin: 0 8px"></i
            >Pagar una Comisión
          </p>
          <div class="commissionForm">
            <div class="commissionField fieldDistributionUser">
              <label>Usuario: </label>
              <v-select
                :options="formattedUsers"
                v-model="selectedUser"
                label="formatUser"
                height="40"
                placeholder="Selecciona un usuario"
                @option:selected="setSelected"
              >
                <template #option="{ formatUser }">
                  <p style="margin: 0">{{ formatUser }}</p>
                </template>
              </v-select>
              <!-- <v-select
                :options="users"
                label="multiLevelNumberAndFullName"
                v-model="selectedUser"
                height="40"
                placeholder="Selecciona un usuario"
                @option:selected="setSelected"
              >
              </v-select> -->
            </div>
            <div class="commissionField fieldDistributionBalance">
              <label>Valor:</label>
              <p class="form-control">
                ${{ this.selectedUser?.balance?.toFixed(2) ?? "0.00" }}
              </p>
            </div>
            <div class="commissionField fieldDistributionBalance">
              <label>Valor con IVA:</label>
              <p class="form-control">
                ${{
                  (this.selectedUser?.balance
                    ? this.selectedUser.balance * 1.15
                    : 0
                  ).toFixed(2) ?? "0.00"
                }}
              </p>
            </div>
          </div>
          <div class="commissionField fieldDistribution">
            <label>Observaciones:</label>
            <input class="form-control" v-model="observations" />
          </div>
          <div class="column">
            <label style="width: auto; margin-left: 0">Datos Bancarios:</label>
            <div class="bankDataContainer" style="width: 100%">
              <div
                style="width: auto"
                class="bankInfo"
                v-if="selectedUser?.banco"
              >
                <div class="bankField">
                  <label>Banco:</label>
                  <p class="form-control">{{ this.selectedUser.banco.bank }}</p>
                </div>
                <div class="bankField">
                  <label>Tipo de Cuenta:</label>
                  <p class="form-control">{{ this.selectedUser.banco.type }}</p>
                </div>
                <div class="bankField">
                  <label>Número de Cuenta:</label>
                  <p
                    class="fieldBank"
                    @click="copyField(this.selectedUser.banco.numberAccount)"
                  >
                    {{ this.selectedUser.banco.numberAccount }}
                  </p>
                </div>
              </div>
              <div v-else-if="selectedUser">
                El usuario no ha registrado datos bancarios
              </div>
              <div v-else></div>
              <button
                class="btn btn-primary btn-sm btn-pay-commission"
                type="button"
                style="width: auto; padding: 12px 15px; height: fit-content"
                :disabled="isPagarComisionDisabled"
                @click="payCommission"
              >
                <!-- Pagar Comisión -->
                <span v-if="loadingCommission" style="white-space: nowrap">
                  <i class="fas fa-circle-notch fa-spin"></i> Realizando
                  Transacción...
                </span>
                <span v-else style="white-space: nowrap">Pagar Comisión</span>
              </button>
            </div>
          </div>
          <!-- <div class="commissionField fieldDistribution">
            <label>Detalle:</label>
          </div> -->
        </div>
      </div>
    </form>
  </div>
  <div class="card" style="margin-bottom: 10px" v-if="isUserSelected">
    <div class="card-body">
      <div class="row">
        <div class="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11">
          <p style="margin: 10px; font-weight: bold">
            <i style="margin-right: 10px" class="fas fa-list"></i> LISTADO DE
            TRANSACCIONES
          </p>
        </div>

        <div
          class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1"
          align="center"
          style="display: flex"
        >
          <Skeletor
            v-if="loading"
            :shimmer="true"
            height="20"
            width="100%"
            style="margin: auto"
          />
          <div v-else @click="refresh">
            <i
              style="cursor: pointer; margin: auto"
              class="fas fa-sync text-sm opacity-10"
            ></i>
          </div>
        </div>
      </div>

      <div
        v-if="loadingTransactions"
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        style="padding: 0px"
      >
        <div class="row">
          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <Skeletor :shimmer="true" height="40" width="50%" />
          </div>

          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <Skeletor
              :shimmer="true"
              height="10"
              pill
              width="30%"
              style="margin-bottom: 10px"
            />
            <Skeletor :shimmer="true" height="40" width="100%" />
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <table class="table tablesorter">
              <thead class="thead-light">
                <tr>
                  <th>
                    <Skeletor :shimmer="true" height="10" width="100%" />
                  </th>
                  <th>
                    <Skeletor :shimmer="true" height="10" width="100%" />
                  </th>
                  <th>
                    <Skeletor :shimmer="true" height="10" width="100%" />
                  </th>
                  <th>
                    <Skeletor :shimmer="true" height="10" width="100%" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
            <Skeletor :shimmer="true" height="10" pill width="80%" />
          </div>

          <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <div class="row">
              <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <Skeletor :shimmer="true" height="10" width="100%" />
              </div>

              <div
                class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"
                align="center"
              >
                <Skeletor :shimmer="true" circle size="30" />
              </div>

              <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <Skeletor :shimmer="true" height="10" width="100%" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <DataTable
        v-else
        :columns="columns"
        :options="options"
        :data="transactions"
        class="table tablesorter"
        width="100%"
        id="myTable"
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Fecha/Hora</th>
            <th data-priority="12000">Nombre</th>
            <th data-priority="12000">Observaciones</th>
            <th data-priority="1">Valor</th>
            <th data-priority="12000">Motivo</th>
            <th></th>
          </tr>
        </thead>
      </DataTable>
    </div>
  </div>
  <div class="card" v-if="transactionCommissions" style="margin-bottom: 10px">
    <div class="card-body">
      <div class="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11">
        <p style="margin: 10px; font-weight: bold">
          <i style="margin-right: 10px" class="fas fa-list"></i> COMISIONES
        </p>
      </div>
      <DataTable
        v-if="transactionCommissions"
        :columns="columnsCommissions"
        :options="optionsCommissions"
        :data="commissions"
        class="table tablesorter"
        width="100%"
        id="myTable"
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Promotor</th>
            <th>Nivel</th>
            <th># Ventas</th>
            <th>Valor</th>
          </tr>
        </thead>
      </DataTable>
    </div>
  </div>

  <VueFinalModal
    v-model="showModal"
    :esc-to-close="true"
    class="modal-container"
    content-class="modal-content"
  >
    <div class="modal__content">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <p style="text-align: center; font-weight: bold; margin: 0px">
            Datos del usuario
          </p>

          <p class="p_text">
            <span class="textLabel">Usuario: </span>{{ displayName }}
          </p>

          <p class="p_text"><span class="textLabel">DNI: </span>{{ dni }}</p>

          <p class="p_text">
            <span class="textLabel">Contacto: </span>{{ contact }}
          </p>
        </div>

        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <hr class="horizontal dark" />
          <p style="text-align: center; font-weight: bold; margin: 0px">
            Datos bancarios
          </p>

          <p class="p_text"><span class="textLabel">Banco: </span>{{ bank }}</p>

          <p class="p_text">
            <span class="textLabel">Nro. cuenta: </span>{{ numberaccount }}
          </p>

          <p class="p_text"><span class="textLabel">Tipo: </span>{{ type }}</p>
        </div>

        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <hr class="horizontal dark" />
          <p style="text-align: center; font-weight: bold; margin: 0px">
            Datos de transacción
          </p>

          <p class="p_text"><span class="textLabel">Fecha: </span>{{ date }}</p>

          <p class="p_text">
            <span class="textLabel">Referencia: </span>{{ ref }}
          </p>

          <p class="p_text">
            <span class="textLabel">Monto: </span>$ {{ mount }}
          </p>

          <p class="p_text">
            <span class="textLabel">Monto a transferir: </span>$
            {{ mounTransfer }}
          </p>
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<script>
/* eslint-disable */

import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-bs5";
import "datatables.net-responsive";
import $ from "jquery";
import { VueFinalModal } from "vue-final-modal";
import "vue-final-modal/style.css";

DataTable.use(DataTablesCore);

export default {
  components: {
    DataTable,
    VueFinalModal,
  },
  props: ["userId", "tokenAuth", "idAdmin"],
  data() {
    return {
      loading: true,
      showModal: false,
      transactions: [],
      notAllow: false,
      displayName: "",
      dni: "",
      contact: "",
      bank: "",
      numberaccount: "",
      type: "",
      date: "",
      ref: "",
      mount: "",
      mounTransfer: "",
      selectedUser: null,
      users: [],
      isMobile: this.$isMobile,
      transactions: [],
      mapComisiones: {},
      loadingTransactions: true,
      loadingCommission: false,
      transactionCommissions: "",
      observations: "",
      columns: [
        { data: "transactionCode" },
        { data: "dateRegister" },
        { data: "displayName" },
        { data: "observations" },
        { data: "amount" },
        { data: "type" },
        {
          // This will be the 'details control' column
          orderable: false,
          searchable: false,
          data: null,
          render: function (data) {
            return `<div class="details btn btn-primary" data-transactionId="${
              data.transactionId
            }" style="white-space: nowrap; display: flex; align-items: center; gap: 12px;font-size: 12px; padding: 5px 10px; justify-content: center; ${
              data.type === "Retiro" ? "display: none" : "display: flex"
            }">Ver Detalle</div>`;
          },
          width: "auto",
        },
      ],
      options: {
        responsive: true,
        ordering: false,
        select: true,

        columnDefs: [
          { responsivePriority: 1, targets: 0 },
          { responsivePriority: 2, targets: -1 },
        ],
        lengthMenu: [
          [5, 15, 25, 35, 50, -1],
          [5, 15, 25, 35, 50, "Todos"],
        ],
        pageLength: 5,
        language: {
          search: "Buscar:",
          sInfo:
            "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
          sInfoEmpty:
            "Mostrando registros del 0 al 0 de un total de 0 registros",
          searchPlaceholder: "Ingrese algún dato",
          zeroRecords: "Lo sentimos, no se encontraron resultados",
          processing: "Buscando. Espere por favor...",
          sLengthMenu: "Mostrar _MENU_ registros",
          oPaginate: {
            sFirst: "Primero",
            sLast: "Último",
            sNext: "Siguiente",
            sPrevious: "Anterior",
          },
        },
      },
      columnsCommissions: [
        { data: "commissionCode", width: "5%" },
        { data: "sellerName", width: "65%" },
        { data: "level", width: "10" },
        { data: "salesNumber", width: "10%" },
        { data: "amount", width: "10%" },
      ],
      optionsCommissions: {
        responsive: true,
        ordering: true,
        select: true,
        columnDefs: [
          { responsivePriority: 1, targets: 0 },
          { responsivePriority: 2, targets: -1 },
          { targets: [2], orderable: true }, // Here, 2 represents the index of the "level" column
        ],
        lengthMenu: [
          [15, 25, 35, 50, 75, -1],
          [15, 25, 35, 50, 75, "Todos"],
        ],
        pageLength: 15,
        language: {
          search: "Buscar:",
          sInfo:
            "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
          sInfoEmpty:
            "Mostrando registros del 0 al 0 de un total de 0 registros",
          searchPlaceholder: "Ingrese algún dato",
          zeroRecords: "Lo sentimos, no se encontraron resultados",
          processing: "Buscando. Espere por favor...",
          sLengthMenu: "Mostrar _MENU_ registros",
          oPaginate: {
            sFirst: "Primero",
            sLast: "Último",
            sNext: "Siguiente",
            sPrevious: "Anterior",
          },
        },
      },
    };
  },
  computed: {
    isPagarComisionDisabled() {
      return (
        !this.selectedUser ||
        this.selectedUser.balance <= 0 ||
        isNaN(this.selectedUser.balance)
      );
    },
    formattedUsers() {
      const multiLevelNumberFormat = (number) => {
        const numberAsString = number ? number.toString() : "";
        return numberAsString.padStart(8, "0");
      };
      return this.users.map((user, index) => {
        return {
          ...user,
          formatUser: `${user.dni} -
            ${multiLevelNumberFormat(user.multiLevelNumber)} -
            ${user.displayName}`,
        };
      });
    },

    commissions() {
      return this.mapComisiones[this.transactionCommissions];
    },
    isUserSelected() {
      // Example check - adapt according to your actual data structure
      return !!this.selectedUser && !!this.selectedUser.userId;
    },
  },

  created() {
    // Bind the click event handler here
    $(document).on("click", ".details", (evt) => {
      const transactionId = $(evt.target).data("transactionid");
      this.transactionCommissions = transactionId;
    });
  },
  watch: {
    selectedUser(newVal, oldVal) {
      if (newVal === null || typeof newVal === "undefined") {
        this.transactionCommissions = "";
      }
      console.log(newVal);
    },
  },
  mounted() {
    this.getListado();
  },
  methods: {
    copyField(fieldValue) {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(fieldValue)
          .then(() => {
            this.$toast.success(`${fieldValue} copiado exitosamente.`, {
              position: "top-right",
              max: 10,
            });
          })
          .catch((err) => {
            console.error("No se pudo copiar el campo: ", err);
            this.$toast.error("Falla al copiar el campo.", {
              position: "top-right",
              max: 10,
            });
          });
      } else {
        console.error("Clipboard API not available.");
        this.$toast.error("Clipboard functionality not available.", {
          position: "top-right",
          max: 10,
        });
      }
    },

    refresh() {
      this.loadTransactions();
    },

    formatUnixTimestamp(timestamp) {
      const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");

      return `${year}/${month}/${day}, ${hours}:${minutes}`;
    },

    getListado() {
      this.loading = true;
      this.$https
        .post("/administracion/getBalanceUserAll", {
          tokenAuth: this.tokenAuth,
        })
        .then((response) => {
          this.users = response.data.datos;
          console.log("this.users", this.users);
          this.loading = false;
        })
        .catch((error) => {
          if (error.response.data.code !== 500) {
            this.$toast.error("Ocurrió un error inesperado", {
              position: "top-right",
              max: 10,
            });
          }
          this.loading = false;
        });
    },

    loadTransactions() {
      const commissionsArray = [22, 3, 4, 2, 1.5];

      this.loadingTransactions = true;

      this.$https
        .post("/team/getConsulComisiones", {
          userId: this.selectedUser.userId,
          tokenAuth: this.tokenAuth,
        })
        .then((response) => {
          if (response.data.code == 200) {
            const userTransactions = response.data.transacciones;

            if (userTransactions) {
              //   this.transactions = userData;
              const fullData = userTransactions
                .map((transaction, index) => ({
                  ...transaction,
                  amount: `$${parseFloat(transaction.amount).toFixed(2)}`,
                  type: transaction.type === "pago" ? "Acreditación" : "Retiro",
                  transactionCode: index,
                  dateRegister: this.formatUnixTimestamp(
                    transaction.createdAt._seconds
                  ),
                }))
                .sort((a, b) => b.createdAt._seconds - a.createdAt._seconds);
              this.transactions = fullData;
              const commissionsObject = response.data.comisiones;

              // Create a new object to store the modified values
              const modifiedCommissionsObject = {};

              // Iterate over the keys of the original object
              Object.keys(commissionsObject).forEach((key) => {
                const commissions = commissionsObject[key];

                const modifiedCommissions = commissions.map(
                  (commission, index) => ({
                    ...commission,
                    amount: `$${parseFloat(commission.amount).toFixed(2)}`,
                    commissionCode: index,
                    salesNumber: commission.salesAmount
                      ? commission.salesAmount
                      : commission.amount / commissionsArray[commission.level],
                  })
                );

                // Add the modified array back to the new object
                modifiedCommissionsObject[key] = modifiedCommissions;
              });
              this.mapComisiones = modifiedCommissionsObject;
            } else {
              this.$toast.error("No posees transacciones", {
                position: "top-right",
                max: 10,
              });
            }
          }
          this.loadingTransactions = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadTransactions();
        });
    },

    payCommission() {
      this.loadingCommission = true;

      // document.getElementById("btn-pay-commission").innerHTML =
      //   '<i class="fas fa-circle-notch fa-spin"></i> Realizando Transacción...';

      this.$https
        .post("/administracion/getUpdateBalance", {
          userId: this.selectedUser.userId,
          tokenAuth: this.tokenAuth,
          balance: this.selectedUser.balance,
          observations: this.observations,
        })
        .then((response) => {
          if (response.data.code == 200) {
            this.$toast.success("Comisión pagada exitosamente", {
              position: "top-right",
              max: 10,
            });
            this.loadTransactions();
            this.selectedUser = { ...this.selectedUser, balance: 0 };
            this.getListado();
          } else {
            this.$toast.error("Ha ocurrido un error", {
              position: "top-right",
              max: 10,
            });
          }
          this.loadingCommission = false;
          // document.getElementById("btn-pay-commission").innerHTML =
          //   "Pagar Comisión";
        })

        .catch((error) => {
          console.log(error);
          this.$toast.error("Ha ocurrido un error", {
            position: "top-right",
            max: 10,
          });
          // this.loadTransactions();
        });
    },

    setSelected(value) {
      this.selectedUser = value;
      this.transactionCommissions = "";
      if (this.selectedUser) {
        this.loadTransactions();
      }
      console.log("el USER que se toma", value);
    },
  },
};
</script>

<style lang="scss">
@import "datatables.net-dt";
@import "datatables.net-responsive-dt";
</style>
