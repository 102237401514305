<template>
  <div v-if="passwordReset">
    <h5 style="margin: 20px 0 12px 0; color: #7d0c7e">
      ¡Contraseña reiniciada exitosamente!
    </h5>
    <h6 style="margin: 12px 0; font-size: 14px">
      Revisa tu
      <span style="color: #7d0c7e; text-decoration: underline"
        >Correo Electrónico</span
      >
      y/o
      <span style="color: #19b251; text-decoration: underline">WhatsApp</span>
      e Inicia Sesión <br />
      con la Contraseña Temporal, una vez iniciada la sesión <br />
      te pedirá que ingreses tu Nueva Contraseña
    </h6>
    <button
      class="btn btn-primary"
      style="margin-top: 12px"
      @click="toggleForm"
    >
      Iniciar Sesión
    </button>
  </div>
  <div v-else>
    <h5 class="mt-3" style="text-align: center; margin-bottom: 15px">
      Reinicia tu Contraseña
    </h5>
    <p
      class="mb-3"
      style="
        text-align: center;
        margin-bottom: 15px;
        font-size: 14px;
        color: #7d0c7e;
        font-weight: 600;
      "
    >
      Ingresa tu Cédula y Número Celular <br />
      <span style="color: #7b7b7b"
        >(Datos que registraste en la plataforma)</span
      >
    </p>
    <!-- <p class="mb-3" style="text-align: center; margin-bottom: 15px">
      Recuerda que son aquellos con los que registraste tu cuenta.
    </p> -->

    <form role="form" autocomplete="off" @submit.prevent="submitForm">
      <div style="margin-bottom: 15px">
        <input
          required
          placeholder="Ingrese su cédula"
          @input="onInputChange"
          class="form-control col-lg-12"
          type="text"
          autocomplete="off"
          v-model="user.dni"
        />
      </div>
      <MazPhoneNumberInput
        type="tel"
        default-country-code="EC"
        style="width: 100%; margin-bottom: 15px"
        no-flags
        no-example
        required
        show-code-on-list
        size="sm"
        v-model="user.phone"
        @update="handleUpdate"
        :translations="{
          countrySelector: {
            placeholder: 'Código',
            error: 'Elija un pais',
          },
          phoneInput: {
            placeholder: 'Nro. celular',
            example: 'Ejemplo:',
          },
        }"
      />
      <div class="text-center" style="margin-bottom: 15px">
        <button
          :disabled="!lengthDniPhone"
          class="mt-1 btn btn-primary"
          variant="gradient"
          color="success"
          style="width: 100%"
          type="submit"
          size="lg"
          id="btn-reset"
        >
          <p style="margin: 0; color: white; font-size: 14px; font-weight: 700">
            Enviar Correo de Reinicio
          </p>
        </button>
      </div>
    </form>

    <p
      class="mt-3"
      style="
        cursor: pointer;
        text-align: center;
        margin-bottom: 5px;
        font-size: 12px;
      "
      @click="toggleForm"
    >
      ¿Ya tienes una cuenta? ¡Inicia sesión!
    </p>
  </div>
</template>

<script>
/*eslint-disable */
import ArgonButton from "@/components/ArgonButton.vue";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/app-check";
import { VueFinalModal } from "vue-final-modal";
import "vue-final-modal/style.css";
import { ref } from "vue";

export default {
  name: "reinicioContrasena",
  emits: ["toggleForm"],
  components: {
    ArgonButton,
    VueFinalModal,
  },
  setup() {
    const phoneNumber = ref();
    const countryCode = ref("EC");
    const isValid = ref(false);

    const handleUpdate = (result) => {
      isValid.value = result.isValid;
    };

    return {
      phoneNumber,
      countryCode,
      isValid,
      handleUpdate,
    };
  },
  data() {
    return {
      user: {
        dni: "",
        phone: "",
      },
      passwordReset: false,
      tokenAuth: null,
      token: null,
      appCheck: null,
      isLoading: false,
    };
  },
  watch: {
    user: {
      deep: true,
      handler(newVal, oldVal) {
        console.log("User object changed:", newVal);
      },
    },
  },
  computed: {
    lengthDniPhone() {
      return this.user.dni.length === 10 && this.isValid;
    },
  },
  async mounted() {
    firebase.auth().useDeviceLanguage();

    this.appCheck = firebase.appCheck();

    this.appCheck.activate(
      "6LcmjzApAAAAALWdBKUfCTTv_cwg2KXPqGESp5TD",

      // Optional argument. If true, the SDK automatically refreshes App Check
      // tokens as needed.
      true
    );

    let tokenAux = await this.appCheck.getToken();

    this.token = tokenAux.token;

    console.log("Token firebase");

    console.log(this.token);
    this.tokenAuth = this.token;

    this.appCheck.setTokenAutoRefreshEnabled(true);

    if (this.promoCode) {
      console.log("esto es para referir: " + this.promoCode);
      return;
    } else {
      console.log("PromoCode vacio");
    }
  },
  methods: {
    navigateLogin() {
      this.$router.push({ name: "Dashboard" });
    },

    onInputChange() {
      this.user.dni = this.user.dni.replace(/\D/g, "").slice(0, 10);
    },

    closed() {
      this.showModalContraseña = false;
    },

    submitForm() {
      this.passwordReset = false;
      document.getElementById("btn-reset").innerHTML =
        '<i class="fas fa-spinner fa-pulse"></i> Espere...';
      this.$https
        .post("/acceso/getResetPassword", {
          dni: this.user.dni,
          phone: this.user.phone,
        })
        .then((response) => {
          console.log(response);
          this.$toast.success("Correo de Reinicio Enviado Exitosamente", {
            position: "top-right",
            max: 10,
          });
          document.getElementById(
            "btn-reset"
          ).innerHTML = `<p style='margin: 0; color: white; font-size: 14px; font-weight: 700'>
          Enviar Correo de Reinicio
        </p>`;
          this.passwordReset = true;
        })
        .catch((error) => {
          this.$toast.error("No se pudo enviar el Correo de Reincio", {
            position: "top-right",
            max: 10,
          });
          document.getElementById(
            "btn-reset"
          ).innerHTML = `<p style='margin: 0; color: white; font-size: 14px; font-weight: 700'>
          Enviar Correo de Reinicio
        </p>`;
        });
    },

    toggleForm() {
      console.log("Entrando");
      this.$emit("toggle-form");
    },
  },

  created() {},
  beforeUnmount() {},
};
</script>

<style scoped>
.bg-gradient-success {
  background-image: linear-gradient(310deg, #591361 0%, #7d0c7e 100%);
}

.m-input.--md .m-input-input,
.m-input.--md .m-input-label {
  font-size: 12px !important;
  line-height: 1.5rem;
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  cursor: not-allowed;
  pointer-events: auto;
}
</style>
