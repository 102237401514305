<template>
  <div>
    <div class="rowCredential">
      <div class="card credentialCard">
        <p
          class="mb-0 pb-3"
          style="text-align: center; font-weight: 600; font-size: 16px"
        >
          Descarga tu Credencial de Distribuidor
        </p>
        <div class="credential" id="credential">
          <div class="credentialHeader">
            <img
              :src="header"
              style="width: 100%; border-radius: 20px 20px 0 0"
            />
          </div>
          <div class="credentialInfo">
            <p
              style="
                color: #722079;
                font-weight: 600;
                font-size: 18px;
                padding: 16px 0 0 0;
                text-align: center;
                margin: 0;
              "
            >
              <span style="font-weight: 200">Código:</span>
              {{ this.multilevelNumber }}
            </p>
            <p
              style="
                color: #722079;
                font-weight: 700;
                font-size: 18px;
                padding: 16px 0;
                text-align: center;
                margin: 0;
              "
            >
              {{ this.nameFormat() }}
            </p>
            <p
              style="
                color: #722079;
                font-weight: 600;
                font-size: 16px;
                padding: 10px 20px 0 20px;
                text-align: center;
                margin: 0;
              "
            >
              ¡Te invito a formar parte de mi Equipo!
            </p>
            <p
              style="
                color: #722079;
                font-weight: 600;
                font-size: 14px;
                padding: 20px 0 0 0;
                text-align: center;
                margin: 0;
              "
            >
              Escanea mi Invitación
            </p>
            <div v-if="loading" style="padding: 20px 40px 20px 40px">
              <Skeletor
                :shimmer="true"
                height="170"
                width="185"
                style="margin-bottom: 0px"
              />
            </div>
            <img
              v-else
              style="width: 90%; padding: 0px 40px 20px 40px"
              :src="this.qrDistribuidor"
            />
          </div>
        </div>

        <div class="credential2" id="credential2">
          <div class="credentialHeader2">
            <img
              :src="header"
              style="width: 300px; border-radius: 20px 20px 0 0"
            />
          </div>
          <div class="credentialInfo2">
            <p
              style="
                color: #722079;
                font-weight: 600;
                font-size: 18px;
                padding: 16px 0 0 0;
                text-align: center;
                margin: 0;
              "
            >
              <span style="font-weight: 200">Código:</span>
              {{ this.multilevelNumber }}
            </p>
            <p
              style="
                color: #722079;
                font-weight: 700;
                font-size: 18px;
                padding: 16px 0;
                text-align: center;
                margin: 0;
              "
            >
              {{ this.nameFormat() }}
            </p>
            <p
              style="
                color: #722079;
                font-weight: 600;
                font-size: 16px;
                padding: 10px 20px 0 20px;
                text-align: center;
                margin: 0;
              "
            >
              ¡Te invito a formar parte de mi Equipo!
            </p>
            <p
              style="
                color: #722079;
                font-weight: 600;
                font-size: 14px;
                padding: 20px 0 0 0;
                text-align: center;
                margin: 0;
              "
            >
              Escanea mi Invitación
            </p>
            <img
              style="width: 90%; padding: 0px 40px 20px 40px"
              :src="this.qrDistribuidor"
            />
          </div>
        </div>

        <button
          @click="downloadImage"
          class="btn btn-primary"
          style="margin-top: 25px"
        >
          Descargar Credencial
        </button>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable */

import html2canvas from "html2canvas";
import header from "../../header.js";

export default {
  name: "detalleCredencial",
  data() {
    const number = JSON.parse(this.$store.state.user).multiLevelNumber;
    const name = JSON.parse(this.$store.state.user).displayName;
    const promoCode = JSON.parse(this.$store.state.user).promoCode;
    let datos = JSON.parse(this.$store.state.user);

    return {
      number: number,
      displayName: name,
      isDisable: false,
      userId: datos.userId,
      tokenAuth: datos.tokenAuth,
      isMobile: this.$isMobile,
      loading: true,
      datos: datos,
      link: "",
      promoCode: promoCode,
      qrDistribuidor: "",
      header: header,
    };
  },
  activated() {
    if (this.isMobile) {
      this.$eventBus.emit("activeShowMenu");
    }
  },
  methods: {
    nameFormat() {
      let displayName = this.displayName ? this.displayName.toLowerCase() : "";
      let array = displayName
        .trimEnd()
        .split(" ")
        .filter((name) => name !== "");

      // List of words to exclude
      const excludeWords = [
        "de los",
        "de las",
        "de",
        "la",
        "del",
        "las",
        "los",
        "de",
        "de la",
        "de las",
        "de los",
      ];

      // Remove excluded words
      array = array.filter(
        (part) => !excludeWords.includes(part.toLowerCase())
      );

      let formattedName = "";

      // Proceed with the rest of your formatting logic
      if (array.length === 1) {
        formattedName = array[0];
      } else if (array.length === 2) {
        formattedName = array[1] + " " + array[0];
      } else if (array.length === 3) {
        formattedName = array[2] + " " + array[0] + " " + array[1];
      } else if (array.length === 4) {
        formattedName =
          array[2] + " " + array[3] + " " + array[0] + " " + array[1];
      } else {
        formattedName =
          array[2] + " " + array[3] + " " + array[0] + " " + array[1];
      }

      array = formattedName.split(" ");
      formattedName = "";

      for (let i = 0; i < array.length; i++) {
        if (array[i]) {
          formattedName += array[i][0].toUpperCase() + array[i].slice(1) + " ";
        }
      }
      return formattedName.trim();
    },
    getQR(link) {
      this.$https
        .post("/codigo/getUserQr", { url: link })
        .then((response) => {
          this.qrDistribuidor = `data:image/png;base64,${response.data.qrCode}`;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    downloadImage() {
      const divToCapture = document.getElementById("credential2"); // Replace 'divId' with the ID of your div
      html2canvas(divToCapture, { useCORS: true }).then((canvas) => {
        const image = canvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");
        const link = document.createElement("a");
        link.download = "div_image.png";
        link.href = image;
        link.click();
      });
    },
  },
  mounted() {
    this.link = "https://xtrimproec.com" + "/invitacion/" + this.promoCode;
    this.getQR("https://xtrimproec.com" + "/invitacion/" + this.promoCode);
  },
  computed: {
    multilevelNumber() {
      const numberAsString = this.number ? this.number.toString() : "";
      return numberAsString.padStart(8, "0");
    },
  },
};
</script>

<style scoped>
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.vs__search {
  height: 30px;
}

.credentialCard {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 16px;
  border-radius: 10px;
  padding-bottom: 30px;
  position: relative;
}

.rowCredential {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}

.credentialBody {
  display: flex;
  width: 100%;
}

.credentialHeader {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #7d0c7e;
  border-radius: 20px 20px 0 0;
}

.credentialHeader2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #7d0c7e;
  /* border-radius: 20px 20px 0 0; */
}

.credentialInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 20px 20px;
}

.credentialInfo2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.credential {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  width: 300px;
}

.credential2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  /* box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05); */
  /* border-radius: 20px; */
  position: absolute;
  z-index: -1;
}

@media (max-width: 1200px) {
  .credentialCard {
    width: 70%;
  }
}

@media (max-width: 768px) {
  .credentialCard {
    width: 95%;
  }
}
</style>
