<template>
  <div
    v-if="existePromotor"
    class="d-flex flex-column"
    style="
      width: 100%;
      margin: 0px;
      justify-content: center;
      align-items: center;
    "
  >
    <div
      class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
      align="center"
      style="margin-bottom: 20px; margin-top: 15px"
    >
      <router-link :to="{ name: 'Dashboard' }" target="_blank"
        ><img
          src="../../src/assets/img/Xtrim/xtrim_logo_morado.svg"
          style="width: 200px"
      /></router-link>
    </div>

    <div class="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-4">
      <h1
        class="invitacion_txt"
        style="margin-bottom: 50px; text-align: center"
        v-if="isXtrimPro"
      >
        ¡Hola! soy
        <span
          style="color: #7d0c7e; font-weight: bold; text-transform: capitalize"
          >XtrimPro
        </span>
        y te invito a formar parte de mi Equipo
      </h1>
      <h1
        class="invitacion_txt"
        style="margin-bottom: 50px; text-align: center"
        v-else
      >
        ¡Hola! soy
        <span
          style="color: #7d0c7e; font-weight: bold; text-transform: capitalize"
          >{{ displayName }}
        </span>
        y te invito a formar parte de mi Equipo en XtrimPro
      </h1>

      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2"></div>

            <div
              class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8"
              align="center"
            >
              <Skeletor
                v-if="loading"
                circle
                size="80"
                style="margin-bottom: 10px; margin-top: -50px"
              />

              <v-lazy-image
                v-else
                :src="photo"
                class="rounded-circle"
                style="
                  background: white;
                  width: 80px;
                  margin-bottom: 10px;
                  margin-top: -50px;
                "
              />
            </div>

            <!-- <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2"></div> -->
          </div>

          <div v-if="loading">
            <Skeletor
              :shimmer="true"
              height="10"
              width="100%"
              pill
              style="margin-bottom: 10px"
            />
            <Skeletor
              :shimmer="true"
              height="10"
              width="80%"
              pill
              style="margin-bottom: 10px"
            />
            <Skeletor
              :shimmer="true"
              height="10"
              width="60%"
              pill
              style="margin-bottom: 10px"
            />
            <Skeletor
              :shimmer="true"
              height="10"
              width="30%"
              pill
              style="margin-bottom: 10px"
            />
          </div>

          <div v-else class="biografiatext">
            <p
              style="
                font-size: 14px;
                text-align: justify;
                margin-bottom: 0px;
                line-height: 20px;
              "
            >
              {{ description }}
            </p>
          </div>
        </div>
      </div>

      <div class="card" style="margin-top: 10px">
        <div class="card-body">
          <div v-if="loading" align="center">
            <Skeletor
              :shimmer="true"
              height="20"
              width="50%"
              pill
              style="margin-bottom: 15px"
            />
            <Skeletor
              :shimmer="true"
              height="40"
              width="100%"
              style="margin-bottom: 30px"
            />

            <Skeletor
              :shimmer="true"
              height="40"
              width="100%"
              style="margin-bottom: 10px"
            />

            <div class="text-center">
              <span>~ o ~</span>
            </div>

            <Skeletor
              :shimmer="true"
              height="40"
              width="100%"
              style="margin-bottom: 10px"
            />
          </div>

          <!-- <formulario
            v-else
            :is-login="isLogin"
            :promo-code="promoCode"
            :texto-button="textoButton"
          /> -->
          <formulario-registro
            v-else
            :is-login="isLogin"
            :promo-code="promoCode"
            :texto-button="textoButton"
            :is-register="true"
            @toggle-form="toggleRegisterForm"
          />
        </div>
      </div>
    </div>
    <!-- 
    <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2"></div>

    <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2"></div> -->

    <div
      class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 text-center align-items-center justify-content-xl-between"
      style="color: black !important; margin-top: 15px"
    >
      <hr class="horizontal dark" />

      <a
        target="_blank"
        title="Facebook de XTrimPro"
        href="https://www.facebook.com/profile.php?id=100090370913056"
      >
        <i class="fab fa-facebook-f redes" aria-hidden="true"></i>
      </a>
      <a
        target="_blank"
        title="Instagram de XTrimPro"
        href="https://www.instagram.com/xtrimpro.ec/"
      >
        <i class="fab fa-instagram redes" aria-hidden="true"></i>
      </a>
      <a
        target="_blank"
        title="Email de XTrimPro"
        href="mailto:hola@xtrimproec.com?Subject=Tengo%20una%20pregunta"
      >
        <i class="fas fa-envelope redes" aria-hidden="true"></i>
      </a>
      <a
        target="_blank"
        title="Tiktok de XTrimPro"
        href="https://www.tiktok.com/@xtrimproec"
      >
        <svg
          style="margin-bottom: 10px"
          xmlns="http://www.w3.org/2000/svg"
          fill="#7d0c7e"
          widht="10"
          height="22"
          viewBox="0 0 448 512"
        >
          <path
            d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"
          /></svg
      ></a>

      <p style="margin-top: 10px; margin-bottom: 0; font-size: 12px">
        <a
          href="https://firebasestorage.googleapis.com/v0/b/xtrimpro-mlm.appspot.com/o/legalDocuments%2Fterminos_final.pdf?alt=media&token=e822415d-b913-4b78-977d-97dd918ed54b"
          target="_blank"
          >Términos y condiciones</a
        >
        -
        <a
          href="https://firebasestorage.googleapis.com/v0/b/xtrimpro-mlm.appspot.com/o/legalDocuments%2Fpolitica-xtrim.pdf?alt=media&token=8bd4a1e6-a1e6-4903-a7d9-216420375b04"
          target="_blank"
          >Política de privacidad</a
        >
        -
        <a
          href="https://firebasestorage.googleapis.com/v0/b/xtrimpro-mlm.appspot.com/o/legalDocuments%2Faviso_legal_xtrimpro.pdf?alt=media&token=ee320100-198f-4fef-9872-eef9d6bd5739"
          target="_blank"
          >Aviso legal</a
        >-
        <a
          href="https://firebasestorage.googleapis.com/v0/b/xtrimpro-mlm.appspot.com/o/legalDocuments%2FDisclaimer-xtrim.pdf?alt=media&token=d313f2c4-7c0f-42ca-b6e2-fb0f7e1e2767"
          target="_blank"
          >Disclaimer</a
        >
      </p>

      <p style="margin-top: 0px; font-size: 12px; margin-bottom: 10px">
        Copyright &copy; {{ year }}
        <a href="https://xtrimproec.com" target="_blank">- XtrimPro</a>
      </p>
    </div>
  </div>

  <div v-else class="row" style="width: 100%; margin: 0px">
    <not-promotor />
  </div>
</template>

<script>
/*eslint-disable */

import NotPromotor from "./NotPromotor";
import Formulario from "./Formulario.vue";
import FormularioRegistro from "./FormularioRegistro.vue";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/app-check";

export default {
  name: "Invitacion",
  components: {
    NotPromotor,
    Formulario,
    FormularioRegistro,
  },
  data() {
    let promoCode = this.$route.params.promoCode;

    return {
      loading: true,
      isLogin: true,
      isRegister: true,
      existePromotor: true,
      isMobile: this.$isMobile,
      displayName: "",
      photo: "",
      description: "",
      promoCode: promoCode,
      year: new Date().getFullYear(),
      textoButton: "Registrarte con",
      link: "",
      linkCopy: "",
      appCheck: null,
      tokenAuth: null,
      token: null,
    };
  },
  methods: {
    getInfo() {
      this.$https
        .post(
          "https://us-central1-xtrimpro-mlm.cloudfunctions.net/consultas/api/v1/acceso/getJoinMyTeam",
          {
            promoCode: this.promoCode,
            tokenAuth: this.tokenAuth,
          }
        )
        .then((response) => {
          console.log("response.data.datos[0]", response.data.datos[0]);
          if (response.data.code == 200) {
            if (response.data.datos[0].photo === "") {
              this.photo = "/img/user.svg";
            } else {
              this.photo = response.data.datos[0].photo;
            }

            this.link = window.location.href;
            this.displayName = response.data.datos[0].displayName.toLowerCase();

            let array = this.displayName.trimEnd().split(" ");
            if (Number(array.length) === Number("2")) {
              this.displayName = array[1] + " " + array[0];
            } else {
              if (Number(array.length) === Number("3")) {
                this.displayName = array[2] + " " + array[0];
              } else {
                if (Number(array.length) === Number("4")) {
                  this.displayName = array[2] + " " + array[0];
                }
              }
            }

            this.linkCopy =
              "¡Hola! soy " +
              this.displayName +
              " y te invito a abrir tu billetera digital de XtrimPro. Regístrate aquí: " +
              this.link;

            document.title =
              "XtrimPro - Link de referido de " + this.displayName;
            this.description =
              "XtrimPro te permite ganar dinero recomendando el servicio de internet de XTRIM de una manera fácil y rápida. Puedes usar tu link de invitación para que todos tus amigos y familiares con solo registrarse comiencen a ganar y tú también ganes por las ventas que ellos hagan.";

            this.loading = false;
          } else {
            if (response.data.datos[0].code == 404) {
              this.$toast.error(response.data.message, {
                position: "top-right",
                max: 10,
              });

              this.existePromotor = false;
            }
          }
        })
        .catch((error) => {
          this.getInfo();
        });
    },
  },
  computed: {
    isXtrimPro() {
      return this.promoCode === "1234";
    },
  },
  async mounted() {
    document.title = "XtrimPro - Link de referido ";
    firebase.auth().useDeviceLanguage();

    this.appCheck = firebase.appCheck();

    this.appCheck.activate(
      "6LcmjzApAAAAALWdBKUfCTTv_cwg2KXPqGESp5TD",

      // Optional argument. If true, the SDK automatically refreshes App Check
      // tokens as needed.
      true
    );

    let tokenAux = await this.appCheck.getToken();

    this.token = tokenAux.token;

    console.log("Token firebase");

    console.log(this.token);
    this.tokenAuth = this.token;

    this.appCheck.setTokenAutoRefreshEnabled(true);
    this.getInfo();
  },
  created() {},
  beforeUnmount() {},
};
</script>

<style scoped></style>
