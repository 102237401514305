<template>
  <div class="container" style="margin-bottom: 10px">
    <Skeletor
      v-if="loading"
      :shimmer="true"
      height="100vh"
      width="100%"
      style="margin-bottom: 0px"
    />
    <div v-else style="display: flex; flex-direction: column">
      <div style="display: flex; justify-content: space-between">
        <p style="font-size: 28px; font-weight: 600; color: #333333">
          Listado de Solicitudes
        </p>
        <button
          class="btn btn-primary"
          style="align-self: flex-end; margin-bottom: 12px !important"
          id="btn_refresh_forms"
          @click="getSolicitudes"
        >
          Refrescar <i class="fas fa-arrows-rotate"></i>
        </button>
      </div>
      <div
        v-for="(solicitud, index) in solicitudes"
        :key="index"
        class="solicitudCard"
        :class="{ expanded: selectedIndex === index }"
      >
        <div class="cardTitle" @click="toggleDetails(index)">
          <p
            style="margin: 0; font-weight: 600; color: #333333"
            class="solicitudCard"
          >
            {{ solicitud.displayName }}
          </p>
          <div style="display: flex; flex-direction: column">
            <p style="margin: 0; font-weight: 600; color: rgb(127 126 126)">
              {{ solicitud.dateFormated }}
            </p>
            <p
              v-if="solicitud.canal === 'WhatsApp'"
              style="margin: 0; font-weight: 600; color: #19b251"
            >
              {{ solicitud.canal }}
            </p>
            <p v-else style="margin: 0; font-weight: 600; color: #7d0c7e">
              {{ solicitud.canal }}
            </p>
          </div>
        </div>
        <div v-if="selectedIndex === index" class="solicitudCard">
          <div class="row formContainer">
            <div
              class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4"
              style="margin-bottom: 10px"
            >
              <label
                for="example-text-input"
                class="form-control-label"
                style="font-weight: bold; font-size: 12px"
                >Quien Refiere:</label
              >
              <p style="margin: 0" class="form-control">
                {{ solicitud.referer }}
              </p>
            </div>
            <br />
            <br />
            <br />
            <br />

            <div
              class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4"
              style="margin-bottom: 10px"
            >
              <label
                for="example-text-input"
                class="form-control-label"
                style="font-weight: bold; font-size: 12px"
                >Cédula de Quien Refiere:</label
              >
              <p
                style="margin: 0"
                class="form-control"
                @click="copyField(solicitud.refererId)"
              >
                {{ solicitud.refererId }}
              </p>
            </div>
            <div
              class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4"
              style="margin-bottom: 10px"
            >
              <label
                for="example-text-input"
                class="form-control-label"
                style="font-weight: bold; font-size: 12px"
                >Contraseña de Quien Refiere:</label
              >
              <p
                class="form-control"
                @click="copyPassword(solicitud.refererPassword)"
                style="
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                "
              >
                {{ solicitud.refererPassword }}
              </p>
            </div>
            <div
              class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
              style="margin-bottom: 10px"
            >
              <label
                for="example-text-input"
                class="form-control-label"
                style="font-weight: bold; font-size: 12px"
                >Cédula :</label
              >

              <p
                style="margin: 0"
                class="form-control"
                @click="copyField(solicitud.dni)"
              >
                {{ solicitud.dni }}
              </p>
            </div>
            <div
              class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
              style="margin-bottom: 10px"
            >
              <label
                for="example-text-input"
                class="form-control-label"
                style="font-weight: bold; font-size: 12px"
                >Código Dactilar :</label
              >

              <p
                style="margin: 0"
                class="form-control"
                @click="copyField(solicitud.dactilar)"
              >
                {{ solicitud.dactilar }}
              </p>
            </div>

            <div
              class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
              style="margin-bottom: 10px"
            >
              <label
                for="example-text-input"
                class="form-control-label"
                style="font-weight: bold; font-size: 12px"
                >Nombres y apellidos</label
              >
              <p class="form-control" @click="copyField(solicitud.displayName)">
                {{ solicitud.displayName }}
              </p>
            </div>

            <div
              class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
              style="margin-bottom: 10px"
            >
              <label
                for="example-text-input"
                class="form-control-label"
                style="font-weight: bold; font-size: 12px"
                >Ingrese su número celular :</label
              >

              <p
                style="margin: 0"
                class="form-control"
                @click="copyField(solicitud.phone.replace(/^\+593/, '0'))"
              >
                {{ solicitud.phone.replace(/^\+593/, "0") }}
              </p>
            </div>

            <div
              class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
              style="margin-bottom: 10px"
            >
              <label
                for="example-text-input"
                class="form-control-label"
                style="font-weight: bold; font-size: 12px"
                >Ingrese su email :</label
              >
              <p
                style="margin: 0"
                class="form-control"
                @click="copyField(solicitud.email)"
              >
                {{ solicitud.email }}
              </p>
            </div>

            <div
              class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
              style="margin-bottom: 10px"
            >
              <label
                for="example-text-input"
                class="form-control-label"
                style="font-weight: bold; font-size: 12px"
                >Dirección :</label
              >
              <p
                style="margin: 0"
                class="form-control"
                @click="copyField(solicitud.address)"
              >
                {{ solicitud.address }}
              </p>
            </div>

            <div
              class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
              style="margin-bottom: 10px"
            >
              <label
                for="example-text-input"
                class="form-control-label"
                style="font-weight: bold; font-size: 12px"
                >Latitud y Longitud :</label
              >
              <p
                class="form-control"
                @click="copyField(`${solicitud.lat}, ${solicitud.lng}`)"
              >
                {{ `${solicitud.lat}, ${solicitud.lng}` }}
              </p>
            </div>

            <div
              class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
              style="margin-bottom: 10px"
            >
              <label
                for="example-text-input"
                class="form-control-label"
                style="font-weight: bold; font-size: 12px"
                >Calle Principal :</label
              >
              <p
                class="form-control"
                @click="copyField(solicitud.callePrincipal)"
              >
                {{ solicitud.callePrincipal }}
              </p>
            </div>

            <div
              class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
              style="margin-bottom: 10px"
            >
              <label
                for="example-text-input"
                class="form-control-label"
                style="font-weight: bold; font-size: 12px"
                >Calle Secundaria :</label
              >
              <p
                class="form-control"
                @click="copyField(solicitud.calleSecundaria)"
              >
                {{ solicitud.calleSecundaria }}
              </p>
            </div>

            <div
              class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
              style="margin-bottom: 10px"
            >
              <label
                for="example-text-input"
                class="form-control-label"
                style="font-weight: bold; font-size: 12px"
                >Manzana :</label
              >
              <p
                style="margin: 0"
                class="form-control"
                @click="copyField(solicitud.manzana)"
              >
                {{ solicitud.manzana }}
              </p>
            </div>

            <div
              class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
              style="margin-bottom: 10px"
            >
              <label
                for="example-text-input"
                class="form-control-label"
                style="font-weight: bold; font-size: 12px"
                >Villa :</label
              >
              <p
                style="margin: 0"
                class="form-control"
                @click="copyField(solicitud.villa)"
              >
                {{ solicitud.villa }}
              </p>
            </div>

            <div
              class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
              style="margin-bottom: 10px"
            >
              <label
                for="example-text-input"
                class="form-control-label"
                style="font-weight: bold; font-size: 12px"
                >Referencia :</label
              >
              <p
                style="margin: 0"
                class="form-control"
                @click="copyField(solicitud.referencia)"
              >
                {{ solicitud.referencia }}
              </p>
            </div>
            <div
              class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
              style="margin-bottom: 10px"
              v-if="solicitud.plan !== null && solicitud.plan !== undefined"
            >
              <label
                for="example-text-input"
                class="form-control-label"
                style="font-weight: bold; font-size: 12px"
                >Plan solicitado :</label
              >
              <p
                style="margin: 0"
                class="form-control"
                @click="copyField(solicitud.plan.name)"
                v-if="solicitud.plan.name"
              >
                <span style="font-weight: 600">{{
                  solicitud.plan.name + " "
                }}</span>
                {{
                  solicitud.plan.description +
                  " $" +
                  solicitud.plan.price.toFixed(2)
                }}
              </p>
              <p
                style="margin: 0"
                class="form-control"
                @click="copyField(solicitud.plan.name)"
                v-else
              >
                <span style="font-weight: 600">{{ solicitud.plan }}</span>
              </p>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <label
                for="example-text-input"
                class="form-control-label"
                style="font-weight: bold; font-size: 12px"
                >Ingreso Manual:</label
              >
              <div style="display: flex; flex-direction: column; gap: 12px">
                <input
                  style="margin: 0"
                  class="form-control"
                  placeholder="Número de contrato"
                  v-model="contractNumber"
                />
                <button
                  class="btn btn-primary"
                  style="align-self: flex-end"
                  id="manualInputBtn"
                  :disabled="isLoading"
                  @click="
                    manualInput({
                      userId: solicitud.userId,
                      address: solicitud.address,
                      city: 'Ingresado Manualmente',
                      completed: 'false',
                      dni: solicitud.dni,
                      email: solicitud.email,
                      fullName: solicitud.displayName,
                      latitude: solicitud.lat,
                      longitude: solicitud.lng,
                      observation: 'Contrato rezagado',
                      phone: solicitud.phone,
                      status: 'Ordenado',
                      ventaId: this.contractNumber,
                    })
                  "
                >
                  Ingresar Manualmente
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      solicitudes: null,
      selectedIndex: null,
      contractNumber: null,
      isLoading: false,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getSolicitudes();
  },
  methods: {
    getSolicitudes() {
      this.loading = true;
      this.$https
        .post("/ventas/getLoadSolicitudVentas")
        .then((response) => {
          if (response.data.code === 200) {
            console.log(response.data.datos);
            this.solicitudes = response.data.datos;
            this.loading = false;
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          this.$toast.error("Ocurrió un error", {
            position: "top-right",
            max: 10,
          });
          this.loading = false;
        });
    },
    toggleDetails(index) {
      this.selectedIndex = this.selectedIndex === index ? null : index;
      this.contractNumber = null;
    },
    copyField(fieldValue) {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(fieldValue)
          .then(() => {
            this.$toast.success(`${fieldValue} copiado exitosamente.`, {
              position: "top-right",
              max: 10,
            });
          })
          .catch((err) => {
            console.error("No se pudo copiar el campo: ", err);
            this.$toast.error("Falla al copiar el campo.", {
              position: "top-right",
              max: 10,
            });
          });
      } else {
        console.error("Clipboard API not available.");
        this.$toast.error("Clipboard functionality not available.", {
          position: "top-right",
          max: 10,
        });
      }
    },
    copyPassword(fieldValue) {
      this.$https
        .get("/acceso/getLoginToken")
        .then((response) => {
          return response.data.token;
        })
        .then((token) => {
          this.$https
            .post(
              "/seguridad/getDesencryptar",
              { password: fieldValue },
              {
                headers: {
                  Authorization: token,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((response) => {
              if (navigator.clipboard) {
                navigator.clipboard
                  .writeText(response.data.passwordDesencriptado)
                  .then(() => {
                    this.$toast.success(`Contraseña copiada exitosamente.`, {
                      position: "top-right",
                      max: 10,
                    });
                  })
                  .catch((err) => {
                    console.error("No se pudo copiar el campo: ", err);
                    this.$toast.error("Falla al copiar el campo.", {
                      position: "top-right",
                      max: 10,
                    });
                  });
              } else {
                console.error("Clipboard API not available.");
                this.$toast.error("Clipboard functionality not available.", {
                  position: "top-right",
                  max: 10,
                });
              }
            });
        });
    },
    manualInput(contractData) {
      document.getElementById("manualInputBtn").innerHTML =
        '<i class="fas fa-spinner fa-pulse"></i>  Ingresando...';
      this.isLoading = true;
      if (this.contractNumber) {
        this.$https
          .get("/acceso/getLoginToken")
          .then((response) => {
            return response.data.token;
          })
          .then((token) => {
            this.$https
              .post("/ventas/VentasXtrim", contractData, {
                headers: {
                  Authorization: token,
                  "Content-Type": "application/json",
                },
              })
              .then((response) => {
                if (response.data.code === 200) {
                  this.$toast.success("Solicitud ingresada exitosamente", {
                    position: "top-right",
                    max: 10,
                  });
                } else {
                  this.$toast.error(response.data.message, {
                    position: "top-right",
                    max: 10,
                  });
                }
                this.isLoading = false;
                document.getElementById("manualInputBtn").innerHTML =
                  "Ingresar Manualmente";
              })
              .catch((error) => {
                console.log(error);
              });
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import "datatables.net-dt";
@import "datatables.net-responsive-dt";

.solicitudCard {
  //   border: 1px solid #ccc;
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.3s;
}

.solicitudCard.expanded {
  height: auto;
}

.solicitudCard-header {
  font-weight: bold;
  padding: 0;
  margin: 0;
}

.solicitudCard-body {
  display: block;
}

.form-control {
  background-color: #f7f7f7; /* Light grey background on hover */
}

.form-control:hover {
  background-color: #f0f0f0; /* Light grey background on hover */
  color: #888; /* Lighter text color on hover */
}

.form-control:active {
  background-color: #cccccc; /* Light grey background on hover */
  color: #6e6e6e; /* Lighter text color on hover */
}

.form-control-label {
  font-size: 14px !important;
}
.cardTitle {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>
