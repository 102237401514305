<template>
  <div>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-2 col-lg-3 col-xl-3"></div>
      <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">
        <div class="card" style="padding: 20px; align-items: center">
          <p
            class="mb-0"
            style="text-align: center; font-size: 16px; font-weight: 600"
          >
            Datos de Mi Patrocinador
          </p>

          <hr class="horizontal dark" />

          <div
            v-if="loading"
            style="
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 20px;
            "
          >
            <Skeletor :shimmer="true" circle size="80" />
            <Skeletor :shimmer="true" height="40vh" width="100%" />
          </div>

          <div v-else class="dataContainer">
            <v-lazy-image
              :src="photo"
              class="rounded-circle"
              style="background: white; width: 80px; align-self: center"
            />
            <p style="margin: 8px 0 8px 0; align-self: center; font-size: 16px">
              <span style="font-weight: 600; font-size: 16px">Código:</span>
              XP{{ this.multilevelNumber }}
            </p>
            <label
              for="example-text-input"
              class="form-control-label"
              style="font-weight: bold; font-size: 12px; margin-bottom: 4px"
              >Nombre:
            </label>
            <p
              style="margin: 0 0 8px 0; cursor: pointer"
              @click="copyField(datos.displayName)"
              class="form-control"
            >
              {{ datos.displayName }}
            </p>
            <label
              for="example-text-input"
              class="form-control-label"
              style="font-weight: bold; font-size: 12px; margin-bottom: 4px"
              >Teléfono:
            </label>
            <p
              style="margin: 0 0 8px 0; cursor: pointer"
              @click="copyField(datos.phone)"
              class="form-control"
            >
              {{ datos.phone }}
            </p>
            <label
              for="example-text-input"
              class="form-control-label"
              style="font-weight: bold; font-size: 12px; margin-bottom: 4px"
              >Correo:
            </label>
            <p
              style="margin: 0 0 8px 0; cursor: pointer"
              @click="copyField(datos.email)"
              class="form-control"
            >
              {{ datos.email }}
            </p>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-12 col-md-2 col-lg-3 col-xl-3"></div>
    </div>
  </div>
</template>

<script>
/*eslint-disable */

export default {
  name: "Patrocinador",
  data() {
    let datos = JSON.parse(this.$store.state.user);
    console.log("Losdatos", datos.userId);
    return {
      datos: datos,
      loading: true,
      photo: "/img/user.svg",
      //   userId: null,
      //   displayName: null,
      //   dni: null,
      //   email: null,
      //   phone: null,
    };
  },
  activated() {
    if (this.isMobile) {
      this.$eventBus.emit("activeShowMenu");
    }
  },
  methods: {
    loadPromoter() {
      this.loading = true;

      this.$https
        .post("/user/getUserIdDetalle", {
          userId: this.datos.userId,
        })
        .then((response) => {
          console.log("response", response);
          if (response.data.code == 200) {
            this.datos = response.data.datos[0].userIdFirstLevel;
            if (response.data.datos[0].userIdFirstLevel.photo !== "") {
              this.photo = response.data.datos[0].userIdFirstLevel.photo;
            }
            this.loading = false;
          } else {
            if (response.data.code == 401) {
              this.$toast.error(response.data.message, {
                position: "top-right",
                max: 10,
              });

              localStorage.removeItem("userData");
              this.$store.state.user = "";

              // Redirect to login page
              this.$router.push({ name: "login" });
            } else {
              this.loadPromoter();
            }
          }
        })
        .catch((error) => {
          this.loadPromoter();
        });
    },
    copyField(fieldValue) {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(fieldValue)
          .then(() => {
            this.$toast.success(`${fieldValue} copiado exitosamente.`, {
              position: "top-right",
              max: 10,
            });
          })
          .catch((err) => {
            console.error("No se pudo copiar el campo: ", err);
            this.$toast.error("Falla al copiar el campo.", {
              position: "top-right",
              max: 10,
            });
          });
      } else {
        console.error("Clipboard API not available.");
        this.$toast.error("Clipboard functionality not available.", {
          position: "top-right",
          max: 10,
        });
      }
    },
  },
  mounted() {
    this.loadPromoter();
  },
  computed: {
    multilevelNumber() {
      const numberAsString = this.datos.multiLevelNumber
        ? this.datos.multiLevelNumber.toString()
        : "";
      return numberAsString.padStart(8, "0");
    },
  },
};
</script>

<style scoped>
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.vs__search {
  height: 30px;
}

.dataContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin: 0 auto;
  width: 70%;
}

@media (max-width: 580px) {
  .dataContainer {
    width: 85%;
  }
}
</style>
