<template>
  <VueFinalModal
    v-model="showUploadModal"
    :esc-to-close="true"
    class="modal-container"
    content-class="modal-content3"
    @closed="closeModal"
  >
    <div
      style="display: flex; flex-direction: column; padding: 20px; height: 100%"
    >
      <div class="modal-header">
        <h5>
          Agregar archivo a
          <span class="text-capitalize">{{ activeType }}</span>
        </h5>
        <i
          @click="closeModal"
          class="fa fa-close"
          style="color: red; font-size: 20px; cursor: pointer"
        ></i>
      </div>
      <div
        class="modal-body"
        @dragover.prevent="onDragOver"
        @dragleave.prevent="onDragLeave"
        @drop.prevent="onFileDrop"
        :class="{ dragging: isDragging }"
      >
        <p v-if="!selectedFile" style="text-align: center; font-weight: 600">
          Arrastra el archivo o selecciona el archivo
        </p>
        <p style="text-align: center" v-else>{{ selectedFile.name }}</p>
        <button
          @click="triggerFileUpload"
          class="btn btn-primary"
          style="width: fit-content; align-self: center"
        >
          Seleccionar Archivo
        </button>
        <input
          type="file"
          ref="fileInput"
          style="display: none"
          @change="handleFileSelect"
        />
      </div>
      <div class="modal-footer">
        <button @click="closeModal" class="btn btn-danger">Cancelar</button>
        <button
          @click="uploadFile(activeType)"
          class="btn btn-primary"
          :disabled="!selectedFile || uploadDisabled"
          id="uploadBtn"
        >
          Subir
        </button>
      </div>
    </div>
  </VueFinalModal>
  <VueFinalModal
    v-model="showModal"
    :esc-to-close="true"
    @closed="closed"
    class="modal-container"
    content-class="modal-content3"
  >
    <button
      v-if="!isPdf"
      class="p-1 btn btn-link text-dark fixed-plugin-close-button"
      style="
        background-color: #333333;
        border-radius: 16px;
        border: solid 3px #dcdcdc;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        margin: 5px 0 10px 0;
        align-self: self-end;
        padding: 6px 10px !important;
        position: absolute;
        top: 12px;
        right: 12px;
        z-index: 10;
      "
      @click="closed"
    >
      <i class="fa fa-close" style="color: #dcdcdc"></i>
    </button>
    <button
      v-if="isPdf"
      class="p-1 btn btn-link text-dark fixed-plugin-close-button"
      style="
        background-color: #333333;
        border-radius: 16px;
        border: solid 3px #dcdcdc;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        margin: 5px 0 10px 0;
        align-self: self-end;
        padding: 6px 10px !important;
        position: absolute;
        bottom: 12px;
        right: 12px;
      "
      @click="closed"
    >
      <i class="fa fa-close" style="color: #dcdcdc"></i>
    </button>
    <!-- <iframe
        v-if="this.activeType !== 'plantillas'"
        :src="this.srcUrl"
        height="100%"
        width="100%"
      ></iframe>
      <img
        v-else
        :src="this.srcUrl"
        style="height: 100%; width: 100%; padding: 20px; object-fit: contain"
      /> -->
    <iframe v-if="isPdf" :src="srcUrl" height="100%" width="100%"></iframe>
    <div
      v-else-if="isImage"
      style="
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 20px;
        height: 100%;
        width: 100%;
      "
    >
      <img
        :src="srcUrl"
        style="height: calc(100% - 41px); width: 100%; object-fit: contain"
      />
      <button
        @click="downloadImage(srcUrl, resourceName)"
        class="btn btn-primary"
      >
        Descargar
      </button>
    </div>
    <video
      v-else-if="isVideo"
      controls
      style="height: 100%; width: 100%; object-fit: contain"
    >
      <source :src="srcUrl" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <div v-else>Unsupported file type.</div>
  </VueFinalModal>
  <div>
    <div class="foldersContainer">
      <div class="buttonsContainer">
        <button
          class="folder"
          :class="{ activeBtn: activeType === 'documentos' }"
          @click="loadResources('documentos')"
        >
          <i
            class="fa-solid fa-file text-success"
            :class="{ activeBtn: activeType === 'documentos' }"
          ></i>
          <p class="text" style="margin: 0">Documentos</p>
        </button>
        <button
          class="folder"
          :class="{ activeBtn: activeType === 'plantillas' }"
          @click="loadResources('plantillas')"
        >
          <i
            class="fa-solid fa-image text-success"
            :class="{ activeBtn: activeType === 'plantillas' }"
          ></i>
          <p class="text" style="margin: 0">Plantillas</p>
        </button>
        <button
          class="folder"
          :class="{ activeBtn: activeType === 'videos' }"
          @click="loadResources('videos')"
        >
          <i
            class="fa-solid fa-video text-success"
            :class="{ activeBtn: activeType === 'videos' }"
          ></i>
          <p class="text" style="margin: 0">Videos</p>
        </button>
      </div>
      <hr />
      <button
        @click="showUploadModal = true"
        style="width: fit-content; align-self: flex-end"
        class="btn btn-primary"
      >
        <i class="fa fa-upload"></i> Agregar +
      </button>
      <div
        v-if="activeType === 'plantillas' && !loading"
        class="archivesContainer"
      >
        <div v-if="pngs.length === 0">No hay Plantillas Disponibles</div>
        <div
          v-else
          v-for="png in pngs"
          :key="png.name"
          @click="clickedResource(png)"
          style="position: relative"
        >
          <!-- <button @click="downloadResource(png)" class="archive"> -->
          <button
            v-if="deletingItem !== png.name"
            class="delete-button"
            @click="deleteResource(png)"
          >
            <i class="fa fa-trash"></i>
          </button>

          <button v-else class="delete-button">
            <i class="fas fa-spinner fa-pulse"></i>
          </button>
          <button class="archive">
            <i class="fa-solid fa-image text-success"></i>
            <p style="font-size: 14px; margin: 0">{{ png.name }}</p>
          </button>
        </div>
      </div>
      <div
        v-if="activeType === 'documentos' && !loading"
        class="archivesContainer"
      >
        <div v-if="pdfs.length === 0">No hay Documentos Disponibles</div>
        <div
          v-else
          v-for="pdf in pdfs"
          :key="pdf.name"
          style="position: relative"
        >
          <button
            v-if="deletingItem !== pdf.name"
            class="delete-button"
            @click="deleteResource(pdf)"
          >
            <i class="fa fa-trash"></i>
          </button>
          <button v-else class="delete-button">
            <i class="fas fa-spinner fa-pulse"></i>
          </button>
          <button class="archive" @click="clickedResource(pdf)">
            <i class="fa-solid fa-file text-success"></i>
            <p style="font-size: 14px; margin: 0">{{ pdf.name }}</p>
          </button>
        </div>
      </div>
      <div v-if="activeType === 'videos' && !loading" class="archivesContainer">
        <div v-if="videos.length === 0">No hay Videos Disponibles</div>
        <div
          v-else
          v-for="video in videos"
          :key="video.name"
          @click="clickedResource(video)"
          style="position: relative"
        >
          <button
            v-if="deletingItem !== video.name"
            class="delete-button"
            @click="deleteResource(video)"
          >
            <i class="fa fa-trash"></i>
          </button>
          <button v-else class="delete-button">
            <i class="fas fa-spinner fa-pulse"></i>
          </button>
          <button class="archive">
            <i class="fa-solid fa-video text-success"></i>
            <p style="font-size: 14px; margin: 0">{{ video.name }}</p>
          </button>
        </div>
      </div>
      <!-- <i class="fas fa-spinner fa-pulse"></i> -->
      <div v-if="loading" class="archivesContainer">
        <div>
          <Skeletor
            :shimmer="true"
            class="skeletorClass"
            style="border-radius: 10px"
          />
        </div>
        <div>
          <Skeletor
            :shimmer="true"
            class="skeletorClass"
            style="border-radius: 10px"
          />
        </div>
        <div>
          <Skeletor
            :shimmer="true"
            class="skeletorClass"
            style="border-radius: 10px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable */

import "firebase/compat/storage";
import { VueFinalModal } from "vue-final-modal";
import "vue-final-modal/style.css";

export default {
  name: "recursos",
  components: {
    VueFinalModal,
  },
  data() {
    return {
      activeType: "plantillas",
      pngs: [],
      pdfs: [],
      videos: [],
      loading: true,
      srcUrl: null,
      showModal: false,
      extension: null,
      resourceName: null,
      showUploadModal: false, // To control modal visibility
      selectedFile: null, // Store the selected or dropped file here
      isDragging: false,
      uploadDisabled: false,
      deletingItem: null,
    };
  },
  activated() {
    if (this.isMobile) {
      this.$eventBus.emit("activeShowMenu");
    }
  },

  computed: {
    fileExtension() {
      return this.srcUrl ? this.srcUrl.split(".").pop().toLowerCase() : "";
    },
    isPdf() {
      return this.extension === "pdf";
    },
    isImage() {
      return ["png", "jpg", "jpeg", "gif"].includes(this.extension);
    },
    isVideo() {
      return ["mp4", "mov"].includes(this.extension);
    },
  },
  methods: {
    triggerFileUpload() {
      this.$refs.fileInput.click(); // Opens file dialog
    },
    handleFileSelect(event) {
      this.selectedFile = event.target.files[0]; // Store selected file
    },
    closeModal() {
      this.showUploadModal = false; // Hides the modal
      this.selectedFile = null; // Clears file selection
    },
    async uploadFile(type) {
      document.getElementById("uploadBtn").innerHTML =
        '<i class="fas fa-spinner fa-pulse"></i>  Subiendo...';
      this.uploadDisabled = true;
      if (this.selectedFile) {
        try {
          const storageRef = this.$appFirebase
            .storage()
            .ref(`${type}/${this.selectedFile.name}`);
          console.log("storageRef", storageRef);
          const uploadTaskSnapshot = await storageRef.put(this.selectedFile);
          console.log("uploadTaskSnapshot", uploadTaskSnapshot);

          // Retrieve the download URL once the upload completes
          const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL();

          console.log("File uploaded successfully! URL:", downloadURL);
          // Optional: Add the uploaded file to the list based on type
          const fileData = {
            name: this.selectedFile.name,
            url: downloadURL,
            ext: this.selectedFile.name.split(".").pop(),
          };
          if (type === "plantillas") {
            this.pngs.push(fileData);
          } else if (type === "documentos") {
            this.pdfs.push(fileData);
          } else if (type === "videos") {
            this.videos.push(fileData);
          }

          document.getElementById("uploadBtn").innerHTML = "Subir";
          this.$toast.success(
            "Archivo subido correctamente a " + this.activeType,
            {
              position: "top-right",
              max: 10,
            }
          );
          this.uploadDisabled = false;
          this.closeModal();
        } catch (error) {
          this.$toast.error(error, {
            position: "top-right",
            max: 10,
          });
          document.getElementById("uploadBtn").innerHTML = "Subir";
          this.uploadDisabled = false;
        }
      } else {
        this.$toast.error("Por favor primero seleccione un archivo", {
          position: "top-right",
          max: 10,
        });
        document.getElementById("uploadBtn").innerHTML = "Subir";
        this.uploadDisabled = false;
      }
    },
    onDragOver(event) {
      this.isDragging = true; // Sets the dragging state
    },
    onDragLeave(event) {
      this.isDragging = false; // Removes dragging state
    },
    onFileDrop(event) {
      this.isDragging = false; // Removes dragging state
      const droppedFiles = event.dataTransfer.files;
      if (droppedFiles.length) {
        this.selectedFile = droppedFiles[0]; // Set the dropped file
        console.log("Dropped file:", this.selectedFile);
      }
    },
    closed() {
      // this.$eventBus.emit("reiniciarFormVentaDashboard");
      this.showModal = false;
      this.srcUrl = null;
    },

    async loadResources(type) {
      this.loading = true;
      this.activeType = type;
      const storageRef = this.$appFirebase.storage().ref().child(type);
      const listResult = await storageRef.listAll();
      if (type === "plantillas") {
        console.log("listResult", listResult);
        // this.pngs = listResult.items;
        this.pngs = await Promise.all(
          listResult.items.map(async (item) => {
            console.log("El item", item);
            return {
              name: item.name,
              url: await item.getDownloadURL(),
              ext: item.name.split(".")[1],
            };
          })
        );
      } else if (type === "documentos") {
        // this.pdfs = listResult.items;
        this.pdfs = await Promise.all(
          listResult.items.map(async (item) => {
            return {
              name: item.name,
              url: await item.getDownloadURL(),
              ext: item.name.split(".")[1],
            };
          })
        );
      } else if (type === "videos") {
        // this.videos = listResult.items;
        this.videos = await Promise.all(
          listResult.items.map(async (item) => {
            return {
              name: item.name,
              url: await item.getDownloadURL(),
              ext: item.name.split(".")[1],
            };
          })
        );
      }
      this.loading = false;
    },
    async deleteResource(resource) {
      if (this.deletingItem) return; // Avoid multiple deletions at once
      this.deletingItem = resource.name;
      try {
        const storageRef = this.$appFirebase
          .storage()
          .ref()
          .child(this.activeType)
          .child(resource.name);
        await storageRef.delete();
        this.loadResources(this.activeType); // Refresh the resource list after deletion
        this.$toast.success(`${resource.name} eliminado correctamente`, {
          position: "top-right",
          max: 10,
        });
      } catch (error) {
        this.$toast.error(
          `Error al eliminar ${resource.name}: ${error.message}`,
          {
            position: "top-right",
            max: 10,
          }
        );
      } finally {
        // Reset deletingItem to null after deletion is done
        this.deletingItem = null;
      }
    },
    // async downloadResource(resource, resourceName) {
    //   const response = await fetch(resource.url);
    //   const blob = await response.blob();
    //   const url = window.URL.createObjectURL(blob);
    //   const a = document.createElement("a");
    //   a.href = url;
    //   console.log("el resource", resource);
    //   a.download = resourceName;
    //   document.body.appendChild(a);
    //   a.click();
    //   window.URL.revokeObjectURL(url);
    //   document.body.removeChild(a);
    // },
    async downloadResource(resource, resourceName) {
      // const response = await fetch(resource.url);
      // console.log("Un response entremedio", response);
      // const blob = await response.blob();
      console.log("MIME type:", blob.type);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      console.log("el resource", resource);
      a.download = resourceName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    },
    // async clickedResource(resource) {
    //   this.extension = resource.ext;
    //   this.srcUrl = resource.url;
    //   this.resourceName = resource.name;
    //   this.showModal = true;
    // },
    async clickedResource(resource) {
      console.log("El resource", resource);
      this.extension = resource.ext;
      this.srcUrl = resource.url;
      this.resourceName = resource.name;
      this.showModal = true;
      console.log(resource);
    },
    downloadImage(url, fileName) {
      const xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = function (event) {
        const blob = xhr.response;
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(link.href);
      };
      xhr.open("GET", url);
      xhr.send();
    },
  },
  mounted() {
    this.loadResources("documentos");
  },
};
</script>

<style scoped>
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.vs__search {
  height: 30px;
}
.delete-button {
  background: none;
  color: red;
  cursor: pointer;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  font-size: 18px;
  border: 2px solid red;
  background-color: rgb(241, 241, 241);
  top: -12px;
  right: -12px;
  position: absolute;
}
.delete-button:hover {
  color: white;
  border: 2px solid red;
  background-color: red;
}
.modal-body {
  border: 2px dashed #ccc;
  padding: 20px;
  margin: 20px 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
}

.dragging {
  border: 2px dashed #007bff;
  background-color: rgba(0, 123, 255, 0.1);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
</style>
