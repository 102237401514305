<template>
  <div class="card" style="margin-bottom: 10px">
    <div class="card-body">
      <div class="row">
        <div
          class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12"
          style="
            margin-bottom: 10px;
            justify-content: space-between;
            display: flex;
          "
        >
          <h5
            class="card-title mb-0 text-uppercase font-weight-bold"
            style="font-size: 12px"
          >
            VENTAS DIRECTAS
          </h5>
          <div
            v-if="!loading"
            @click="refreshTeam"
            style="cursor: pointer; position: relative; top: -5px"
          >
            <i
              style="font-size: 6px"
              class="fas fa-sync text-sm opacity-10"
            ></i>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-12">
          <div class="teamInfoContainer" v-if="loading">
            <Skeletor
              :shimmer="true"
              height="38"
              width="50%"
              style="margin-bottom: 0px"
            />
            <Skeletor
              :shimmer="true"
              height="20"
              width="40"
              style="margin-bottom: 0px"
            />
          </div>
          <div v-else class="teamInfoContainer">
            <div class="amountValue">
              <p class="font-weight-bolder priceCard">
                <!-- {{ cantidad.toFixed(2) }} -->
                <!-- <span
                  style="
                  font-size: 14px;
                  font-weight: 600;
                  margin: 0;
                  margin-right: 4px;
                  "
                  >$</span
                  > -->
                ${{ integerPart }}
              </p>
              <p class="decimals">{{ decimalPart }}</p>
            </div>
            <div class="teamCount">
              <p
                class="w-auto teamNumber"
                style="
                  font-size: 20px;
                  font-weight: 600;
                  margin: 0;
                  padding-right: 4px;
                "
              >
                {{ activeSalesQuantity }}/5
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  components: {},
  props: [
    "userId",
    "tokenAuth",
    "loading",
    "mySales",
    "activeSalesQuantity",
    "refreshTeam",
  ],
  data() {},
  computed: {
    cantidad() {
      return this.mySales;
    },
    integerPart() {
      return Math.trunc(this.cantidad).toString();
    },
    decimalPart() {
      let decimal = this.cantidad.toFixed(2).split(".")[1];
      return decimal;
    },
  },
  watch: {},
  mounted() {},
  methods: {
    referidos() {
      this.$toast.error("Funcionalidad no disponible por el momento", {
        position: "top-right",
        max: 10,
      });
    },
    refresh() {
      this.$eventBus.emit("reiniciarPrimerNivel");
    },
  },
};
</script>

<style lang="scss"></style>
