<template>
  <div v-if="step !== 4 && showTitle" class="align-items-center text-center">
    <p style="margin-bottom: 10px; margin-top: 10px; font-weight: bold">
      Paso {{ step }} de 2 - {{ textStep }}
    </p>
  </div>

  <form
    v-if="firstStep"
    role="form"
    autocomplete="off"
    @submit.prevent="onSubmitFirstStep"
  >
    <div
      class="row"
      v-if="!notSupport"
      style="display: flex; justify-content: center"
    >
      <div style="width: 400px">
        <div
          class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 alert alert-primary"
          role="alert"
          style="
            margin: 0px;
            font-size: 14px;
            font-weight: bold;
            line-height: 18px;
            text-align: center;
          "
        >
          Necesitamos comprobar si en la ubicación donde deseas instalar XTRIM
          tenemos cobertura de nuestra red, por favor indícanos la dirección o
          coordenadas donde deseas instalar
        </div>
        <div class="switchContainer">
          <button
            type="button"
            class="btn btn-primary"
            @click="selectAddressType('address')"
            :class="{ active: addressType === 'address' }"
          >
            Dirección
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="selectAddressType('coordinates')"
            :class="{ active: addressType === 'coordinates' }"
          >
            Coordenadas
          </button>
        </div>
        <div
          class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          style="margin-bottom: 10px"
          v-if="addressType === 'coordinates'"
        >
          <label
            for="example-text-input"
            class="form-control-label"
            style="font-weight: bold; font-size: 12px"
            >Coordenadas del domicilio (*):</label
          >

          <input
            v-model="latLngInput"
            placeholder="Ej: -2.1201908, -79.8676519"
            @input="setLatLng()"
            required
            :readonly="isDisabled"
            class="form-control col-lg-12"
          />
        </div>
        <div
          class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          style="margin-bottom: 10px"
          v-else
        >
          <label
            for="example-text-input"
            class="form-control-label"
            style="font-weight: bold; font-size: 12px"
            >Dirección del domicilio (*):</label
          >
          <GMapAutocomplete
            :value="autocomplete"
            placeholder="Dirección domiciliaria"
            @place_changed="getAddressData($event)"
            required
            :readonly="isDisabled"
            class="form-control col-lg-12"
          >
          </GMapAutocomplete>
        </div>

        <div
          class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          style="margin-bottom: 10px"
        >
          <label
            for="example-text-input"
            class="form-control-label"
            style="font-weight: bold; font-size: 12px"
            >Indique la ubicación de su domicilio (*):</label
          >

          <GMapMap
            @click="mark"
            :center="center"
            :zoom="16"
            map-type-id="terrain"
            style="width: 100%; height: 250px"
            :options="{
              zoomControl: true,
              mapTypeControl: true,
              scaleControl: true,
              streetViewControl: true,
              rotateControl: true,
              fullscreenControl: true,
              disableDefaultUi: true,
            }"
          >
            <GMapMarker
              :key="index"
              v-for="(m, index) in markers"
              :position="m.position"
              :clickable="true"
              :draggable="true"
              @click="center = m.position"
              @dragend="handleMarkerDrag"
            />
          </GMapMap>
        </div>

        <div
          class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          align="center"
        >
          <button
            type="submit"
            id="btn_solicitar"
            class="btn btn-primary"
            style="width: 100%"
            :disabled="isDisabled"
          >
            Siguiente
            <i style="margin-left: 10px" class="fas fa-arrow-circle-right"></i>
          </button>
        </div>
      </div>
    </div>

    <!-- <div v-else class="row"> -->
    <div v-else class="row">
      <div class="formContainer">
        <div
          class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          align="center"
        >
          <svg
            width="100"
            height="100"
            viewBox="-20 0 190 190"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M38.155 140.475 48.988 62.1108l43.881 4.946 18.568 23.955-8.041 57.1092L38.155 140.475zM84.013 94.0018 88.827 71.8068l-34.781-3.5-9.854 67.1502L98.335 142.084l6.542-45.2752-20.864-2.807zM59.771 123.595C59.394 123.099 56.05 120.299 55.421 119.433 64.32 109.522 86.05 109.645 92.085 122.757 91.08 123.128 86.59 125.072 85.71 125.567 83.192 118.25 68.445 115.942 59.771 123.595zM76.503 96.4988 72.837 99.2588l-5.515-6.642L59.815 96.6468l-3.029-5.069L63.615 88.1508l-4.526-5.452L64.589 79.0188 68.979 85.4578 76.798 81.5328 79.154 86.2638l-7.047 3.783 4.396 6.452z"
              fill="#4a4a4a"
            />
          </svg>

          <div
            class="alert alert-primary"
            role="alert"
            style="margin: 0px; font-size: 12px"
          >
            <p style="text-align: center; font-size: 14px; margin: 0px">
              Tu navegador no soporta la API de geolicación. Por favor intente
              con el navegador Chrome, Brave, Mozilla u otro.
            </p>
          </div>
        </div>
      </div>
    </div>
  </form>

  <form
    v-if="secondStep"
    role="form"
    autocomplete="off"
    @submit.prevent="onSubmit"
    style="display: flex; justify-content: center"
  >
    <div class="row formContainer">
      <div
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        style="margin-bottom: 10px"
      >
        <label
          for="example-text-input"
          class="form-control-label"
          style="font-weight: bold; font-size: 12px"
          >Nombres y apellidos</label
        >
        <input
          required
          placeholder="Nombres y apellidos"
          class="form-control col-lg-12"
          type="text"
          autocomplete="off"
          v-model="displayName"
        />
      </div>

      <div
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        style="margin-bottom: 10px"
      >
        <label
          for="example-text-input"
          class="form-control-label"
          style="font-weight: bold; font-size: 12px"
          >Nro. Celular (*):</label
        >

        <MazPhoneNumberInput
          type="tel"
          default-country-code="EC"
          style="width: 100%"
          no-flags
          no-example
          required
          show-code-on-list
          size="sm"
          v-model="phoneNumber"
          @update="results = $event"
          :translations="{
            countrySelector: {
              placeholder: 'Código',
              error: 'Elija un pais',
            },
            phoneInput: {
              placeholder: 'Nro. celular',
              example: 'Ejemplo:',
            },
          }"
        />
      </div>

      <div
        style="
          justify-content: space-between;
          display: flex;
          margin: 20px 0 0 0;
        "
      >
        <button @click="atras" class="btn btn-secondary" :disabled="isDisabled">
          <i style="margin-right: 10px" class="fas fa-arrow-circle-left"></i>
          Atrás
        </button>

        <button
          type="submit"
          id="btn_submit"
          class="btn btn-primary"
          :disabled="isDisabled"
        >
          Enviar Solicitud
        </button>
      </div>
    </div>
  </form>

  <div v-if="thirdStep" class="resultContainer">
    <p
      style="
        color: #722079;
        font-weight: 700;
        font-size: 18px;
        padding: 0 0 16px 0;
        text-align: center;
        margin: 0;
      "
    >
      ¡Tu solicitud se envió exitosamente!
    </p>

    <p
      style="
        color: #333333;
        font-weight: 600;
        font-size: 16px;
        padding: 10px 20px 0 20px;
        text-align: center;
        margin: 0 0 10px 0;
      "
    >
      En breve un asesor se contactará contigo para continuar la contratación
      del Mejor Internet del Ecuador
    </p>
    <p
      style="
        color: #333333;
        font-weight: 500;
        font-size: 14px;
        padding: 10px 20px 0 20px;
        text-align: center;
        margin: 10px 0 0 0;
        max-width: 370px;
        text-align: center;
      "
    >
      Ante cualquier duda o problema, no dudes en contactarte por
      <span style="color: #19b251; font-weight: 700">Whatsapp</span>

      <!-- con
      <span style="font-weight: 700">Servicio Al Cliente </span> -->
    </p>
  </div>
</template>

<script>
/*eslint-disable */

import PlanCard from "../components/PlanCard.vue";
import ArgonSwitch from "../components/ArgonSwitch.vue";

export default {
  name: "FormReferir",
  components: { ArgonSwitch, PlanCard },
  props: ["promoCode", "tokenAuth", "phoneReferrer", "displayNamePromoter"],
  data() {
    return {
      isDisabled: false,
      displayName: "",
      dni: "",
      dniRespaldo: "",
      step: 1,
      phoneNumber: null,
      firstStep: true,
      secondStep: false,
      thirdStep: false,
      fourthStep: false,
      results: null,
      hasPhone: false,
      email: "",
      textStep: "Ubicación de domicilio",
      center: {},
      markers: [],
      geocoder: null,
      loadMap: true,
      plusCode: "",
      notSupport: false,
      city: "",
      autocomplete: "",
      emailRespaldo: "",
      phoneRespaldo: "",
      idVenta: "",
      latLngInput: "",
      useCoordinates: false,
      addressType: "address", // Default to 'Dirección'
      codigoDactilar: "",
      callePrincipal: "",
      calleSecundaria: "",
      manzana: "",
      villa: "",
      referencia: "",
      referenciaDactilar: "",
      identityVerified: false,
      dniExists: false,
      plan: null,
      plans: null,
      isGponAvailable: false,
      isLoading: false,
      showTitle: true,
    };
  },
  methods: {
    getAddressData(request) {
      this.autocomplete = request.formatted_address;

      let ubicacion = request.geometry.location;
      this.center = {
        lat: parseFloat(request.geometry.location.lat()),
        lng: parseFloat(request.geometry.location.lng()),
      };

      this.markers = [
        {
          position: ubicacion,
        },
      ];

      this.geocoder
        .geocode({ location: this.center })
        .then((response) => {
          if (response.results[0]) {
            console.log("entro 2");

            this.plusCode = response.results[0].formatted_address;
            this.autocomplete = response.results[1].formatted_address;
            this.city = response.results[0].address_components[2].long_name;
          }
        })
        .catch((e) => console.log("Geocoder failed due to: " + e));
    },
    selectAddressType(type) {
      this.addressType = type;
      if (type === "coordinates") {
        // Clear address-related fields when switching to coordinates
        this.autocomplete = "";
      } else {
        this.latLngInput = "";
      }
    },
    setLatLng() {
      const values = this.latLngInput
        .split(",")
        .map((coord) => parseFloat(coord.trim()));

      if (values.length !== 2 || values.some(isNaN)) {
        console.error(
          'Invalid coordinates format. Expected format: "2.1201908,79.8676519"'
        );
        return;
      }

      const [lat, lng] = values;
      this.center = {
        lat,
        lng,
      };
      this.markers = [
        {
          position: {
            lat,
            lng,
          },
        },
      ];
      this.geocoder
        .geocode({ location: this.center })
        .then((response) => {
          if (response.results[0]) {
            this.plusCode = response.results[0].formatted_address;
            this.autocomplete = response.results[1].formatted_address;
            this.city = response.results[0].address_components[2].long_name;
          }
        })
        .catch((e) => console.log("Geocoder failed due to: " + e));
    },
    loadMapa() {
      if (this.loadMap) {
        this.loadMap = false;

        if (!("geolocation" in navigator)) {
          this.$toast.error(
            "La geolocalización no está soportado para este navegador",
            {
              position: "top-right",
              max: 10,
            }
          );

          this.notSupport = true;

          return;
        }

        // get position
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            this.center = {
              lat: parseFloat(pos.coords.latitude),
              lng: parseFloat(pos.coords.longitude),
            };

            this.markers = [
              {
                position: {
                  lat: parseFloat(pos.coords.latitude),
                  lng: parseFloat(pos.coords.longitude),
                },
              }, // Along list of clusters
            ];

            this.geocoder
              .geocode({ location: this.center })
              .then((response) => {
                if (response.results[0]) {
                  console.log("entro 3");

                  this.plusCode = response.results[0].formatted_address;
                  this.autocomplete = response.results[1].formatted_address;
                  this.city =
                    response.results[0].address_components[2].long_name;
                }
              })
              .catch((e) => console.log("Geocoder failed due to: " + e));
          },
          (err) => {
            this.$toast.error(err.message, {
              position: "top-right",
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          }
        );
      }
    },

    instanceMap() {
      this.geocoder = new google.maps.Geocoder();

      setTimeout(() => this.loadMapa(), 2000);
    },
    handleMarkerDrag(e) {
      this.center = {
        lat: parseFloat(e.latLng.lat()),
        lng: parseFloat(e.latLng.lng()),
      };

      this.addressType = "coordinates";
      this.autocomplete = "";
      this.latLngInput = e.latLng.lat() + ", " + e.latLng.lng();

      this.geocoder
        .geocode({ location: this.center })
        .then((response) => {
          if (response.results[0]) {
            console.log("entro 4");

            this.plusCode = response.results[0].formatted_address;
            this.autocomplete = response.results[1].formatted_address;
            this.city = response.results[0].address_components[2].long_name;
          }
        })
        .catch((e) => console.log("Geocoder failed due to: " + e));
    },

    mark(event) {
      this.center = {
        lat: parseFloat(event.latLng.lat()),
        lng: parseFloat(event.latLng.lng()),
      };
      this.addressType = "coordinates";
      this.autocomplete = "";

      this.latLngInput = event.latLng.lat() + ", " + event.latLng.lng();

      this.markers = [
        {
          position: {
            lat: parseFloat(event.latLng.lat()),
            lng: parseFloat(event.latLng.lng()),
          },
        }, // Along list of clusters
      ];

      this.geocoder
        .geocode({ location: this.center })
        .then((response) => {
          if (response.results[0]) {
            console.log("entro 5");

            this.plusCode = response.results[0].formatted_address;
            this.city = response.results[0].address_components[2].long_name;
            this.autocomplete = response.results[1].formatted_address;
          }
        })
        .catch((e) => console.log("Geocoder failed due to: " + e));
    },

    onSubmit(event) {
      event.preventDefault();
      document.getElementById("btn_submit").innerHTML =
        '<i class="fas fa-spinner fa-pulse"></i>  Enviando...';

      this.isDisabled = true;

      const jsonData = {
        displayNamePromoter: this.displayNamePromoter,
        phonePromoter: this.phoneReferrer,
        lat: this.center.lat,
        lng: this.center.lng,
        displayNameReferral: this.displayName,
        phoneReferral: this.phoneNumber,
        promoCode: this.promoCode,
      };

      console.log(jsonData);

      this.$https
        .post("/ventas/getAddLeadMasivos", jsonData)
        .then((response) => {
          // document.getElementById("btn_submit").innerHTML =
          //   "Solicitar servicio";
          document.getElementById("btn_submit").innerHTML =
            '<i class="fas fa-spinner fa-pulse"></i>  Solicitando servicio...';
          this.isDisabled = false;
          if (response.data.code == 200) {
            this.isSuccessful = true; /// con este mando a la siguiente vista

            this.thirdStep = true;
            this.firstStep = false;
            this.secondStep = false;
            this.textStep = "¡Tu información se envió exitosamente!";
            this.step = 3;
            this.showTitle = false;

            this.$eventBus.emit("reiniciarListadoVentas");
          } else {
            this.$toast.error(response.data.message, {
              position: "top-right",
              max: 10,
            });
            document.getElementById("btn_submit").innerHTML =
              "Enviar Solicitud";
            this.isDisabled = false;

            if (response.data.code == 401) {
              localStorage.removeItem("userData");
              this.$store.state.user = "";

              this.$router.push({ name: "auth-login" });
            }
          }
        })
        .catch((error) => {
          document.getElementById("btn_submit").innerHTML = "Enviar Solicitud";
          this.isDisabled = false;

          this.$toast.error("Ocurrió un error inesperado", {
            position: "top-right",
            max: 10,
          });
        });
    },
    atras() {
      if (this.step === 2) {
        this.step = 1;
        this.firstStep = true;
        this.secondStep = false;
        this.thirdStep = false;
        this.fourthStep = false;
        this.textStep = "Ubicación de domicilio";
      } else if (this.step === 3) {
        this.step = 2;
        this.firstStep = false;
        this.secondStep = true;
        this.thirdStep = false;
        this.textStep = "Información general";
      }
    },

    closeModal() {
      this.$eventBus.emit("reiniciarFormVenta");
      this.$eventBus.emit("closeModalVenta");
    },

    onSubmitFirstStep(event) {
      event.preventDefault();

      this.isDisabled = true;

      document.getElementById("btn_solicitar").innerHTML =
        '<i class="fas fa-spinner fa-pulse"></i>  Comprobando cobertura';
      this.$https
        .post(
          "https://us-central1-xtrim-referidos.cloudfunctions.net/consultas/api/v1/ventas/getCobertura",
          {
            latitude: this.center.lat,
            longitude: this.center.lng,
          }
        )
        .then((response) => {
          document.getElementById("btn_solicitar").innerHTML =
            'Siguiente <i  style="margin-left: 10px;" class="fas fa-arrow-circle-right"></i>';
          this.isDisabled = false;
          if (response.data.code == 200) {
            console.log("El super response", response);
            this.isGponAvailable = response.data.isGponAvailable;
            this.isDisabled = false;
            this.step = 2;
            this.firstStep = false;
            this.secondStep = true;
            this.thirdStep = false;
            this.textStep = "Información General";
          } else {
            if (response.data.code == 405) {
              this.$swal({
                title: response.data.message,
                icon: "warning",
                showCancelButton: false,
                confirmButtonText: "Entendido",
                focusConfirm: true,
                allowEscapeKey: false,
                allowOutsideClick: false,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            } else {
              this.$toast.error(response.data.message, {
                position: "top-right",
                max: 10,
              });

              if (response.data.code == 401) {
                localStorage.removeItem("userData");
                this.$store.state.user = "";

                this.$router.push({ name: "auth-login" });
              }
            }
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.$toast.error("Ocurrio un error " + error, {
            position: "top-right",
            max: 10,
          });
        });
    },

    onSubmitSecondStep(event) {
      event.preventDefault();
      this.step = 3;
      this.firstStep = false;
      this.secondStep = false;
      this.thirdStep = true;
      this.fourthStep = false;
      this.textStep = "Información General";
    },

    cargarScript() {
      // As an instance method inside a component
      this.$loadScript(
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyD57jTsIwzvDmNZ5I2zh1YkqKfOm7HWwpA"
      )
        .then(() => {
          // Script is loaded, do something
          this.instanceMap();
        })
        .catch(() => {
          // Failed to fetch script
          this.cargarScript();
        });
    },
    checkPhone() {
      if (this.phoneNumber == "") {
        this.phoneRespaldo = "";
      } else if (this.phoneNumber != this.phoneRespaldo) {
        document.getElementById("btn_submit").innerHTML =
          '<i class="fas fa-spinner fa-pulse"></i>  Comprobando celular, espere';

        this.isDisabled = true;

        this.$https
          .post("/profile/checkUserBan/", {
            tokenAuth: this.tokenAuth,
            dato: this.phoneNumber,
          })
          .then((response) => {
            document.getElementById("btn_submit").innerHTML =
              "Solicitar servicio";
            this.isDisabled = false;

            if (response.data.code == 200) {
              this.phoneRespaldo = this.phoneNumber;
            } else {
              this.phoneNumber = "";
              this.phoneRespaldo = "";

              this.$toast.error(response.data.message, {
                position: "top-right",
                max: 10,
              });
            }
          })
          .catch((error) => {
            this.phoneNumber = "";

            this.$toast.error("Ocurrió un error inesperado", {
              position: "top-right",
              max: 10,
            });
          });
      }
    },
  },
  computed: {
    validAddress() {
      console.log("this.autocomplete", this.autocomplete);
      console.log("this.latLngInput", this.latLngInput);
      if (this.addressType === "coordinates") {
        return this.latLngInput;
      } else {
        return this.autocomplete;
      }
    },
  },
  watch: {
    results(newValue) {
      if (newValue.isValid) {
        this.phoneNumber = newValue.e164;
        this.hasPhone = true;

        // this.checkPhone();
      } else {
        this.hasPhone = false;
      }
    },
    displayName() {
      this.displayName = this.displayName.toUpperCase();
    },
  },
  mounted() {
    this.cargarScript();
  },
  created() {},
  beforeUnmount() {},
};
</script>

<style scoped>
.switchContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 16px 0 10px 0;
  gap: 12px;
}

.title {
  position: absolute;
  top: 0;
}
.switchContainer button.active {
  background-color: #7d0c7e !important;
  color: white !important;
}

.switchContainer button {
  background-color: white !important;
  color: #7d0c7e;
}

.formContainer {
  max-width: 700px;
  width: 100%;
}

@media (min-width: 1200px) {
  .formContainer {
    max-width: 700px;
    width: 100%;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  .formContainer {
    max-width: 700px;
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .formContainer {
    max-width: 600px;
    width: 100%;
  }
}

@media (min-width: 100px) and (max-width: 767px) {
  .formContainer {
    width: auto;
  }
}

.resultContainer {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.plans-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  flex: 1 1 calc(50% - 2rem);
}

.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: #7d0c7e;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
