<template>
  <div class="container" style="margin-bottom: 10px; margin-top: -20px">
    <Skeletor
      v-if="loading"
      :shimmer="true"
      height="100vh"
      width="100%"
      style="margin-bottom: 0px"
    />
    <div v-else>
      <PromoterList :list="list" />
    </div>
  </div>
</template>

<script>
import PromoterList from "../../components/PromoterList.vue";

export default {
  components: {
    PromoterList,
  },
  data() {
    const datos_json = JSON.parse(this.$store.state.user);
    return {
      list: [],
      tokenAuth: datos_json.tokenAuth,
      userId: datos_json.userId,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getTotalTree();
  },
  methods: {
    getTotalTree() {
      this.$https
        .post(`/team/getTotalTree`, {
          tokenAuth: this.tokenAuth,
          userId: this.userId,
        })
        .then((response) => {
          if (response.data.code == 200) {
            this.loading = false;
            console.log("Entró");
            this.list = response.data.data.children[0];
          } else {
            this.$toast.error(response.data.message, {
              position: "top-right",
              max: 10,
            });

            if (response.data.code == 401) {
              localStorage.removeItem("userData");
              this.$store.state.user = "";

              // Redirect to login page
              this.$router.push({ name: "login" });
            }
          }
        })
        .catch((error) => {
          this.$toast.error("Ocurrió un error inesperado", error, {
            position: "top-right",
            max: 10,
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import "datatables.net-dt";
@import "datatables.net-responsive-dt";

.solicitudCard {
  //   border: 1px solid #ccc;
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.3s;
}

.solicitudCard.expanded {
  height: auto;
}

.solicitudCard-header {
  font-weight: bold;
  padding: 0;
  margin: 0;
}

.solicitudCard-body {
  display: block;
}

.form-control {
  background-color: #f7f7f7; /* Light grey background on hover */
}

.form-control:hover {
  background-color: #f0f0f0; /* Light grey background on hover */
  color: #888; /* Lighter text color on hover */
}

.form-control:active {
  background-color: #cccccc; /* Light grey background on hover */
  color: #6e6e6e; /* Lighter text color on hover */
}

.form-control-label {
  font-size: 14px !important;
}
.cardTitle {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>
