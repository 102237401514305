<template>
  <table class="table">
    <tbody>
      <tr class="borderTable">
        <td
          :colspan="
            datasource.children && datasource.children.length
              ? datasource.children.length * 2
              : null
          "
          class="borderTable"
          style="padding: 0"
        >
          <div
            class="chartNode"
            style="min-width: 9rem"
            :style="
              datasource.nivel === 1
                ? 'border: 3px solid #2A93D5 !important'
                : datasource.nivel === 2
                ? 'border: 3px solid #F3CC23 !important'
                : datasource.nivel === 3
                ? 'border: 3px solid #EF5350 !important'
                : datasource.nivel === 4
                ? 'border: 3px solid #02B875 !important'
                : 'border: 3px solid #920071 !important'
            "
            :id="datasource.id"
            @click.stop="handleClick(datasource)"
          >
            <slot :node-data="datasource">
              <div class="treeCard">
                <div class="chartContent treeText" v-if="this.adminProp">
                  <p style="margin: 0 0.5rem; font-size: 10px">
                    {{ datasource.title }}
                  </p>
                </div>
                <p style="margin: 0 0.5rem">
                  {{ nameFormat }}
                </p>
                <p class="salesNumber">
                  {{ datasource.ventasPeriodo }}
                </p>
              </div>
            </slot>
          </div>
        </td>
      </tr>
      <template v-if="datasource.children && datasource.children.length">
        <tr class="chartLines">
          <td :colspan="datasource.children.length * 2" style="padding: 0">
            <div class="chartDownLine"></div>
          </td>
        </tr>
        <tr class="chartLines">
          <td class="chartRightLine"></td>
          <template v-for="n in datasource.children.length - 1" v-bind:key="n">
            <td class="chartLeftLine chartTopLine"></td>
            <td class="chartRightLine chartTopLine"></td>
          </template>
          <td class="chartLeftLine"></td>
        </tr>
        <tr class="nodes">
          <td
            colspan="2"
            v-for="child in datasource.children"
            :key="child.id"
            style="padding: 0"
          >
            <node :datasource="child" :handle-click="handleClick">
              <template
                v-for="slot in Object.keys($slots)"
                v-slot:[slot]="scope"
              >
                <slot :name="slot" v-bind="scope" />
              </template>
            </node>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "node",
  props: {
    datasource: Object,
    handleClick: Function,
  },
  inject: ["adminProp"],
  data() {
    return {
      isDragging: false,
    };
  },
  created() {
    this.clg;
  },
  methods: {
    handleMouseDown() {
      this.isDragging = false; // Reset the flag when mouse button is pressed
    },
    handleMouseUp() {
      if (!this.isDragging) {
        // Trigger click event only if not dragging
        this.handleClick(this.datasource);
      }
    },
    handleMouseMove() {
      this.isDragging = true; // Set the flag when mouse is moved
    },
  },
  computed: {
    // nameFormat() {
    //   let displayName = this.datasource.displayName
    //     ? this.datasource.displayName.toLowerCase()
    //     : "";
    //   let array = displayName.trimEnd().split(" ");
    //   let formattedName = "";

    //   if (array.length === 2) {
    //     formattedName = array[1] + " " + array[0];
    //   } else if (array.length === 3) {
    //     formattedName = array[2] + " " + array[0];
    //   } else if (array.length === 4) {
    //     formattedName = array[2] + " " + array[0];
    //   }

    //   array = formattedName.split(" ");
    //   formattedName = "";

    //   for (let i = 0; i < array.length; i++) {
    //     if (array[i]) {
    //       formattedName += array[i][0].toUpperCase() + array[i].slice(1) + " ";
    //     }
    //   }

    //   return formattedName.trim();
    // },
    nameFormat() {
      let displayName = this.datasource.displayName
        ? this.datasource.displayName.toLowerCase()
        : "";
      let array = displayName
        .trimEnd()
        .split(" ")
        .filter((name) => name !== "");

      // List of words to exclude
      const excludeWords = [
        "de los",
        "de las",
        "de",
        "la",
        "del",
        "las",
        "los",
        "de",
        "de la",
        "de las",
        "de los",
      ];

      // Remove excluded words
      array = array.filter(
        (part) => !excludeWords.includes(part.toLowerCase())
      );

      let formattedName = "";

      // Proceed with the rest of your formatting logic
      if (array.length === 1) {
        formattedName = array[0];
      } else if (array.length === 2) {
        formattedName = array[1] + " " + array[0];
      } else if (array.length === 3) {
        formattedName = array[2] + " " + array[0];
      } else if (array.length === 4) {
        formattedName = array[2] + " " + array[0];
      } else {
        formattedName = array[2] + " " + array[0];
      }

      array = formattedName.split(" ");
      formattedName = "";

      for (let i = 0; i < array.length; i++) {
        if (array[i]) {
          formattedName += array[i][0].toUpperCase() + array[i].slice(1) + " ";
        }
      }
      return formattedName.trim();
    },
  },
};
</script>

<style>
.table {
  margin-bottom: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  border-collapse: separate;
}

.chartNode {
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  margin: 0 1px 2px 1px;
  max-width: "200px";
  border: 1px solid #b5b3b5 !important;
  text-align: center;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  border-radius: 0.35rem;
}
.chartNode:hover {
  box-shadow: 0 0 5px #b5b3b5 !important;
  cursor: default;
  z-index: 20;
}

.chartTitle {
  text-align: center;
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1.25rem;
  overflow: hidden;
  white-space: nowrap;
  background: #b5b3b5 !important;
  color: white;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.chartContent {
  box-sizing: border-box;
  width: 100%;
  height: 1.25rem;
  font-size: 0.75rem;
  line-height: 1rem;
  border: #b5b3b5 !important;
  text-align: center;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  background: white;
  color: black;
  overflow: hidden;
  white-space: nowrap;
}

.chartLines {
  height: 1.25rem;
}

.chartTopLine {
  border-top-color: #b5b3b5 !important;
  border-top-style: solid;
  border-top-width: 2px;
}

.chartRightLine {
  border-right-color: #b5b3b5 !important;
  border-right-style: solid;
  border-right-width: 1px;
}

.chartLeftLine {
  border-left-color: #b5b3b5 !important;
  border-left-style: solid;
  border-left-width: 1px;
}
</style>
