<template>
  <div class="card" style="margin-bottom: 10px">
    <div class="card-body">
      <div class="row" style="position: relative">
        <div
          class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12"
          style="
            margin-bottom: 10px;
            justify-content: space-between;
            display: flex;
          "
        >
          <h5
            class="card-title mb-0 text-uppercase font-weight-bold"
            style="font-size: 12px"
          >
            Saldo actual
          </h5>
          <div
            v-if="!this.loading"
            @click="refreshTeam"
            style="cursor: pointer; position: relative; top: -5px"
          >
            <i
              style="font-size: 6px"
              class="fas fa-sync text-sm opacity-10"
            ></i>
          </div>
        </div>

        <div class="col-xl-7 col-lg-7 col-md-7 col-7 col-sm-7">
          <Skeletor
            v-if="this.loading"
            :shimmer="true"
            height="34"
            width="100%"
            style="margin-top: 10px"
          />
          <div v-else class="amountValue">
            <p class="font-weight-bolder priceCard">
              <!-- {{ cantidad.toFixed(2) }} -->
              <!-- <span
                style="
                  font-size: 26px;
                  font-weight: 600;
                  margin: 0;
                  margin-right: 4px;
                "
                >$</span> -->
              ${{ integerPart }}
            </p>
            <p class="decimals">{{ decimalPart }}</p>
          </div>
        </div>
        <Skeletor
          v-if="this.loading"
          :shimmer="true"
          height="10"
          width="50%"
          style="
            padding: 5px 15px;
            margin: auto;
            display: flex;
            position: absolute;
            left: 10px;
            top: 20px;
          "
        />
        <div
          v-else
          class=""
          style="
            width: auto;
            padding: 5px 15px;
            margin: auto;
            display: flex;
            position: absolute;
            left: -6px;
            top: 10px;
            font-size: 10px;
          "
        >
          Disponible: $ {{ this.balance.toFixed(2) }}
        </div>
        <button
          @click="retirar()"
          class="btn btn-primary btn-sm"
          type="button"
          style="
            width: auto;
            padding: 5px 15px;
            margin: auto;
            display: flex;
            position: absolute;
            right: 0;
            bottom: -10px;
          "
        >
          Retirar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  components: {},
  props: [
    "userId",
    "tokenAuth",
    "loading",
    "availableAmount",
    "refreshTeam",
    "balance",
  ],
  data() {
    return {};
  },
  computed: {
    integerPart() {
      return Math.trunc(this.availableAmount).toString();
    },
    decimalPart() {
      let decimal = this.availableAmount.toFixed(2).split(".")[1];
      return decimal;
    },
  },
  watch: {},
  mounted() {},
  methods: {
    retirar() {
      this.$eventBus.emit("showModalRetiro");
    },

    refresh() {
      this.$eventBus.emit("reiniciarSaldos");
    },
  },
};
</script>

<style lang="scss"></style>
