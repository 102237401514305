<template>
  <div
    class="row"
    style="
      backdrop-filter: blur(10px);
      background-color: rgba(0, 0, 0, 0.8) !important;
      bottom: 0;
      left: 0;
      position: fixed;
      text-align: center;
      width: 100%;
      z-index: 101;
      padding: 10px 15px;
      margin: 0px;
    "
  >
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"></div>

    <div
      class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"
      style="display: flex"
    >
      <img style="width: 45px; margin: auto" src="/img/xtrimamarillo.svg" />
    </div>

    <div class="col-10 col-sm-10 col-md-10 col-lg-6 col-xl-6">
      <p
        style="
          font-size: 20px;
          margin-bottom: 10px;
          margin-top: 15px;
          font-weight: bold;
          color: #fff;
          text-align: left;
        "
      >
        XTRIMPRO es mejor en PWA
      </p>
      <p
        style="
          font-size: 14px;
          margin-bottom: 10px;
          color: #fff;
          text-align: left;
        "
      >
        Instala el PWA de XTRIMPRO en tu {{ device }} para utilizarlo como una
        aplicación sin la necesidad de que ingreses a un navegador.
      </p>
    </div>

    <div
      class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3"
      style="display: flex"
    >
      <div align="center" style="margin: auto">
        <button
          style="margin-bottom: 10px; margin-right: 10px"
          class="btn btn-primary btn-md"
          @click="installPWA"
        >
          Instalar app
        </button>

        <button
          style="
            margin-bottom: 10px;
            background-color: #f0f2f5 !important;
            color: black !important;
            border: 2px solid white !important;
          "
          class="btn btn-md btn-secondary"
          @click="dismissPrompt"
        >
          Ahora no
        </button>
      </div>
    </div>

    <div class="col-12 col-sm-12 col-md-12 col-lg-1 col-xl-1"></div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["installEvent"],
  data() {
    const ua = navigator.userAgent;

    let device = "";

    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      //es tablet
      device = "tablet";
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      //es mobile
      device = "celular";
    } else {
      //es destopk
      device = "computadora";
    }

    return {
      device: device,
    };
  },
  created() {},
  beforeMount() {},
  mounted() {},
  methods: {
    dismissPrompt() {
      this.$eventBus.emit("ocultarPWA");
    },

    installPWA() {
      this.installEvent.prompt();
      this.installEvent.userChoice.then((choice) => {
        this.dismissPrompt(); // Hide the prompt once the user's clicked
        if (choice.outcome === "accepted") {
          // Do something additional if the user chose to install
        } else {
          // Do something additional if the user declined
        }
      });
    },
  },
};
</script>
