<template>
  <div class="container" style="margin-bottom: 10px">
    <div class="statContainer">
      <div class="nextStats">
        <div class="card stat">
          <p class="title" style="margin-bottom: 0">Ventas Histórico</p>
          <div class="dateContainer">
            <div class="date-input">
              <label class="labelText" for="desde">Desde:</label>
              <input
                class="form-control"
                type="date"
                id="desde"
                v-model="desde"
                @change="getResumen"
              />
            </div>
            <div class="date-input">
              <label class="labelText" for="hasta">Hasta:</label>
              <input
                class="form-control"
                type="date"
                id="hasta"
                v-model="hasta"
                @change="getResumen"
              />
            </div>
          </div>
          <div class="historicalDate">
            <div class="dateSpan">
              <p class="dateSpanText" style="margin-bottom: 0">
                Desde: {{ formatDate(this.desde) }}
              </p>
              <p class="dateSpanText" style="margin-bottom: 0">
                Hasta: {{ formatDate(this.hasta) }}
              </p>
            </div>
            <Skeletor v-if="loading" height="32" width="50" style="" />
            <p v-else class="number" style="margin-bottom: 0">
              {{ this.ventasHistorico }}
            </p>
          </div>
        </div>
        <div class="card stat">
          <p class="title" style="margin-bottom: 0">Ventas Mes Actual</p>

          <p class="number" style="margin-bottom: 0">{{ this.ventasMes }}</p>
        </div>
        <div class="card stat">
          <p class="title" style="margin-bottom: 0">Total Distribuidores</p>
          <Skeletor
            v-if="loadingReport"
            height="36"
            width="80"
            style="align-self: flex-end"
          />
          <p v-else class="number" style="margin-bottom: 0">
            {{ this.usuariosRegistrados }}
          </p>
        </div>
      </div>
      <div class="card" style="padding: 20px">
        <p style="margin-bottom: 0">
          Gráfico de Nuevos Distribuidores Semanalmente desde el 29/02/2024
        </p>
        <Skeletor v-if="loadingReport" height="100%" width="100%" style="" />
        <BarChart v-else :chartData="this.weekleyData" :styles="styles" />
      </div>
    </div>
    <PromoterSummaryCard
      v-for="(sale, index) in sortedSalesSummaryArray"
      :key="index"
      :sale="sale"
    ></PromoterSummaryCard>
  </div>
</template>

<script>
import BarChart from "../../components/BarChart";
import PromoterSummaryCard from "../../components/PromoterSummaryCard.vue";

export default {
  props: ["userId", "tokenAuth"],
  components: { BarChart, PromoterSummaryCard },
  data() {
    const firstDayOfMonth = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    )
      .toISOString()
      .split("T")[0];
    const hasta = new Date().toISOString().split("T")[0];
    return {
      loading: true,
      loadingReport: true,
      weekleyData: {
        labels: [],
        datasets: [{ data: [] }],
      },
      ventasMes: 0,
      ventasHistorico: 0,
      usuariosRegistrados: 0,
      desde: firstDayOfMonth,
      hasta: hasta,
      monthSales: [],
      salesSummaryArray: [],
      plans: [],
    };
  },
  computed: {
    styles() {
      return { height: "450px" };
    },
    sortedSalesSummaryArray() {
      return [...this.salesSummaryArray].sort((a, b) => b.sales - a.sales);
    },
  },
  watch: {},
  mounted() {
    this.getResumen();
    this.getReport();
  },
  methods: {
    getResumen() {
      this.loading = true;
      this.$https
        .post("/reporte/getReportVentasPeriodo", {
          tokenAuth: this.tokenAuth,
          desde: this.formatDate(this.desde),
          hasta: this.formatDate(this.hasta),
        })
        .then((response) => {
          this.ventasMes = response.data.totalVentasMesActual;
          this.ventasHistorico = response.data.totalVentasPorFecha;
          this.loading = false;
          this.monthSales = response.data.totalDetalleVentasDesdeHasta;
          this.plans = response.data.planes;

          // Create a lookup map from planId to plan name
          const planLookup = this.plans.reduce((acc, plan) => {
            acc[plan.planId] =
              plan.name +
              " " +
              plan.description +
              " " +
              `${plan.isGponAvailable ? "GPON" : "HFC"}` +
              " - $" +
              plan.price;
            return acc;
          }, {});

          // Process monthSales to aggregate sales by userId and replace planId with plan name
          const salesSummary = this.monthSales.reduce((acc, sale) => {
            const { userId, refers, planId } = sale;

            if (!acc[userId]) {
              acc[userId] = {
                userId,
                refers,
                sales: 0,
                plansNames: [], // Initialize an empty array for plan names
              };
            }
            acc[userId].sales += 1;
            acc[userId].plansNames.push(planLookup[planId]); // Add plan name to the array

            return acc;
          }, {});

          // Convert salesSummary object to an array
          this.salesSummaryArray = Object.values(salesSummary);
        })
        .catch((error) => {
          console.error("Error:", error);
          this.$toast.error("Ocurrió un error", {
            position: "top-right",
            max: 10,
          });
          this.loading = false;
        });
    },
    getReport() {
      this.loadingReport = true;
      this.$https
        .post("/reporte/getReportFrond", {
          tokenAuth: this.tokenAuth,
        })
        .then((response) => {
          this.usuariosRegistrados = response.data.userRegister - 8;
          let labels = [];
          let data = [];
          response.data.datos.sort(
            (a, b) => new Date(a.fecha_inicio) - new Date(b.fecha_inicio)
          );
          response.data.datos.forEach((dato) => {
            labels.push(
              new Date(dato.fecha_inicio)
                .toLocaleString("es-AR")
                .split(",")[0] +
                " - " +
                new Date(dato.fecha_fin).toLocaleString("es-AR").split(",")[0]
            );
            data.push(dato.UserNuevos);
          });
          this.weekleyData = {
            labels,
            datasets: [
              {
                data,
                label: "Nuevos Distribuidores",
                backgroundColor: "#920171",
              },
            ],
          };

          this.loadingReport = false;
        })
        .catch((error) => {
          console.error("Error:", error);
          this.$toast.error("Ocurrió un error", {
            position: "top-right",
            max: 10,
          });
          this.loadingReport = false;
        });
    },
    formatDate(date) {
      const d = date.split("-");
      const day = d[2];
      const month = d[1];
      const year = d[0];
      return `${day}/${month}/${year}`;
    },
  },
};
</script>

<style lang="scss">
@import "datatables.net-dt";
@import "datatables.net-responsive-dt";

.container {
  display: flex;
  gap: 12px;
  flex-direction: column;
}

.stat {
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  color: #333333;
  .title {
    align-self: self-start;
    font-size: 18px;
    font-weight: 600;
  }
  .number {
    align-self: self-end;
    font-size: 28px;
    font-weight: 800;
  }
}

@media (max-width: 550px) {
  .stat {
    width: 100%;
  }

  .date-input {
    width: 100%;
  }
}

.dateSpan {
  display: flex;
  flex-direction: column;
}

.dateSpanText {
  font-size: 12px;
  font-weight: 500;
  color: #7c7c7c;
}

.historicalDate {
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.statContainer {
  display: flex;
  gap: 12px;
  //   flex-wrap: wrap;
}

@media (max-width: 1200px) {
  .statContainer {
    flex-direction: column;
  }
}

.dateContainer {
  display: flex;
  gap: 12px;
  align-self: flex-start;
}

.labelText {
  font-size: 14px;
  margin: 0;
}

.date-input {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: start;
}

.nextStats {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
</style>
