<template>
  <VueFinalModal
    v-model="showModal"
    :esc-to-close="true"
    class="modal-container"
    content-class="modal-content"
  >
    <div class="modal__content">
      <div style="display: flex; flex-direction: column; gap: 12px">
        <div style="display: flex; flex-direction: column">
          <label>Seleccionar Plan Vendido: </label>
          <select
            v-model="selectedPlan"
            class="form-select"
            placeholder="Seleccionar plan"
            required
          >
            <option
              v-for="(plan, index) in plans"
              :key="index"
              selected
              :value="plan.planId"
            >
              {{
                plan.name +
                " " +
                (plan.isGponAvailable ? "GPON" : "HFC") +
                " " +
                plan.description +
                " - $" +
                plan.price.toFixed(2)
              }}
            </option>
          </select>
        </div>
        <div style="display: flex; justify-content: space-between">
          <button
            class="btn btn-danger"
            style="align-self: flex-end"
            id="terminateButton"
            :disabled="isTerminating"
            @click="endContract()"
          >
            <i class="fa-solid fa-trash text-icon-white"></i>
          </button>
          <button
            class="btn btn-primary"
            style="align-self: flex-end"
            id="activateButton"
            :disabled="isActivating"
            @click="activateContract()"
          >
            Activar Contrato
          </button>
        </div>
      </div>
    </div>
  </VueFinalModal>
  <div class="card" style="margin-bottom: 10px">
    <div class="card-body">
      <div class="row">
        <div class="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11">
          <p style="margin: 10px; font-weight: bold">
            <i style="margin-right: 10px" class="fas fa-list"></i> LISTADO DE
            TODAS LAS VENTAS DE XTRIM
          </p>
        </div>

        <div
          class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1"
          align="center"
          style="display: flex"
        >
          <Skeletor
            v-if="loading"
            :shimmer="true"
            height="20"
            width="100%"
            style="margin: auto"
          />
          <div v-else @click="refresh">
            <i
              style="cursor: pointer; margin: auto"
              class="fas fa-sync text-sm opacity-10"
            ></i>
          </div>
        </div>
      </div>

      <div
        v-if="loading"
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        style="padding: 0px"
      >
        <div class="row">
          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <Skeletor :shimmer="true" height="40" width="50%" />
          </div>

          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <Skeletor
              :shimmer="true"
              height="10"
              pill
              width="30%"
              style="margin-bottom: 10px"
            />
            <Skeletor :shimmer="true" height="40" width="100%" />
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <table class="table tablesorter">
              <thead class="thead-light">
                <tr>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                </tr>
                <tr>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                </tr>
                <tr>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
            <Skeletor :shimmer="true" height="10" pill width="80%" />
          </div>

          <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <div class="row">
              <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <Skeletor :shimmer="true" height="10" width="100%" />
              </div>

              <div
                class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"
                align="center"
              >
                <Skeletor :shimmer="true" circle size="30" />
              </div>

              <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <Skeletor :shimmer="true" height="10" width="100%" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="notAllow" class="row">
        <div
          class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          align="center"
        >
          <img style="width: 100px" src="/img/caution.svg" />

          <p style="margin-top: 10px; font-size: 14px">
            Tu rol no te permite utilizar la siguiente funcionalidad.
          </p>
        </div>
      </div>

      <DataTable
        v-else
        :columns="columns"
        :options="options"
        :data="transactions"
        class="table tablesorter"
        width="100%"
      >
        <thead>
          <tr>
            <th>Fecha/Hora</th>
            <th>ID Venta</th>
            <th data-priority="2">Distribuidor</th>
            <th data-priority="1">Nombre Completo</th>
            <th>DNI</th>
            <th>Celular</th>
            <th>Email</th>
            <th data-priority="3">Estado</th>
          </tr>
        </thead>
      </DataTable>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-bs5";
import "datatables.net-responsive";
import $ from "jquery";
import { VueFinalModal } from "vue-final-modal";
import "vue-final-modal/style.css";

DataTable.use(DataTablesCore);

export default {
  components: {
    DataTable,
    VueFinalModal,
  },
  props: ["userId", "tokenAuth", "idAdmin"],
  data() {
    return {
      loading: true,
      isActivating: false,
      isTerminating: false,
      transactions: [],
      notAllow: false,
      showModal: false,
      selectedPlan: null,
      ventaId: null,
      plans: [],
      columns: [
        { data: "dateFormated" },
        { data: "ventaId" },
        { data: "refers" },
        { data: "fullName" },
        { data: "dni" },
        { data: "phone" },
        { data: "email" },
        // {
        //   data: "status",
        // },
        {
          data: null,
          render: function (data, type, row, meta) {
            if (data.status === "Ordenado") {
              return `<div>
                <button type="button"  class="btn btn-primary btn-sm toggle-activate" data-venta-id="${data.ventaId}">
                  <p style="color: white; font-weight: 600; font-size: 12px; margin: 0; width: max-content; ">Activar</p>
                </button>
              </div>`;
            } else {
              return data.status;
            }
          },
        },
      ],
      options: {
        responsive: true,
        ordering: false,
        columnDefs: [
          { responsivePriority: 1, targets: 0 },
          { responsivePriority: 2, targets: -1 },
        ],
        lengthMenu: [
          [7, 15, 25, 35, 50, -1],
          [7, 15, 25, 35, 50, "All"],
        ],
        pageLength: 7,
        language: {
          search: "Buscar:",
          sInfo:
            "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
          sInfoEmpty:
            "Mostrando registros del 0 al 0 de un total de 0 registros",
          searchPlaceholder: "Ingrese algún dato",
          zeroRecords: "Lo sentimos, no se encontraron resultados",
          processing: "Buscando. Espere por favor...",
          sLengthMenu: "Mostrar _MENU_ registros",
          oPaginate: {
            sFirst: "Primero",
            sLast: "Último",
            sNext: "Siguiente",
            sPrevious: "Anterior",
          },
        },
      },
    };
  },
  computed: {},
  watch: {
    plans(newPlans) {
      if (newPlans.length > 0) {
        this.selectedPlan = newPlans[0].planId;
      }
    },
  },
  mounted() {
    $(document).off("click", ".consultar");
    $(document).off("click", ".aprobar");

    $(document).on("click", ".aprobar", (evt) => {
      const id = $(evt.target).data("id");
      const estado = $(evt.target).data("estado");

      this.cambiarEstado(id, estado);
    });

    $(document).on("click", ".consultar", (evt) => {
      const data = $(evt.target).data("id");

      console.log(data);
      this.consultarStatus(data);
    });

    const vm = this;
    $(document).ready(function () {
      $(document).on("click", ".toggle-activate", function () {
        const venta = $(this).data("venta-id");
        vm.showModal = true;
        vm.ventaId = venta;
      });
    });

    this.getListado();
    this.fetchPlans();
  },
  methods: {
    fetchPlans() {
      this.isLoading = true;
      this.$https
        .post(`/planes/loadAllPlanes`)
        .then((response) => {
          if (response.data.code == 200) {
            this.plans = response.data.planes.sort((a, b) => {
              return parseInt(a.price) - parseInt(b.price);
            });
          } else {
            this.plans = [];
          }
        })
        .catch((error) => {
          this.$toast.error("No encontramos planes disponibles", {
            position: "top-right",
            max: 10,
          });
          console.log(error);
          this.isLoading = false;
        });
    },
    cambiarEstado(id, estado) {
      this.$swal({
        html: '<div class="row"><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div><div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"><div class="loader"> </div></div><div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7"><p style="margin:0px; font-size:16px; text-align:center; font-weight:bold">Cambiando estado</p></div><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div></div>',
        showCloseButton: false,
        showCancelButton: false,
        focusConfirm: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        position: "top",
      });

      this.$https
        .post("/administracion/updateVenta/", {
          tokenAuth: this.tokenAuth,
          id: id,
          estado: estado,
          userId: this.userId,
          idAdmin: this.idAdmin,
        })
        .then((response) => {
          this.$swal.close();

          if (response.data.code == 200) {
            this.refresh();
            this.$toast.success(response.data.message, {
              position: "top-right",
              max: 10,
            });
          } else {
            this.refresh();
            this.$toast.error(response.data.message, {
              position: "top-right",
              max: 10,
            });

            if (response.data.code === 401) {
              localStorage.removeItem("userData");
              this.$store.state.user = "";

              // Redirect to login page
              this.$router.push({ name: "login" });
            }
          }
        })
        .catch((error) => {
          this.$toast.error("2) Ocurrió un error inesperado: " + error, {
            position: "top-right",
            max: 10,
          });
        });
    },

    consultarStatus(id) {
      this.$swal({
        html: '<div class="row"><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div><div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"><div class="loader"> </div></div><div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7"><p style="margin:0px; font-size:16px; text-align:center; font-weight:bold">Consultando</p></div><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div></div>',
        showCloseButton: false,
        showCancelButton: false,
        focusConfirm: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        position: "top",
      });

      this.$https
        .post("/ventas/consultarVenta/", { tokenAuth: this.tokenAuth, id: id })
        .then((response) => {
          this.$swal.close();

          if (response.data.code == 200) {
            this.refresh();
            this.$toast.success(response.data.message, {
              position: "top-right",
              max: 10,
            });
          } else {
            this.refresh();
            this.$toast.error(response.data.message, {
              position: "top-right",
              max: 10,
            });

            if (response.data.code === 401) {
              localStorage.removeItem("userData");
              this.$store.state.user = "";

              // Redirect to login page
              this.$router.push({ name: "login" });
            }
          }
        })
        .catch((error) => {
          this.$toast.error("2) Ocurrió un error inesperado: " + error, {
            position: "top-right",
            max: 10,
          });
        });
    },

    refresh() {
      $(document).off("click", ".consultar");
      this.$eventBus.emit("reiniciarListadoVentasAdmin");
    },

    getListado() {
      this.$https
        .post("/ventas/AllVentas", { tokenAuth: this.tokenAuth })
        .then((response) => {
          if (response.data.code == 200) {
            this.loading = false;
            this.transactions = response.data.ventas;
            console.log("All ventas", response.data.ventas);
          } else {
            this.loading = true;
            if (response.data.code == 401) {
              localStorage.removeItem("userData");
              this.$store.state.user = "";

              this.$router.push({ name: "login" });
            }
          }
        })
        .catch((error) => {
          this.getListado();
        });
    },
    endContract() {
      this.isTerminating = true;
      document.getElementById("terminateButton").innerHTML =
        '<i class="fas fa-spinner fa-pulse"></i> Terminando Contrato';
      this.$https
        .get("/acceso/getLoginToken")
        .then((response) => {
          return response.data.token;
        })
        .then((token) => {
          this.$https
            .post(
              "/ventas/UpdateVentasXtrim",
              {
                ventaId: new String(this.ventaId),
                status: "Terminado",
              },
              {
                headers: {
                  Authorization: token,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((response) => {
              this.$toast.success(response.data.message, {
                position: "top-right",
                max: 10,
              });
              this.isActivating = false;
              document.getElementById("terminateButton").innerHTML =
                '<i class="fa-solid fa-trash text-icon-white"></i>';
              this.showModal = false;
              this.ventaId = null;
              this.plans = this.plans;
              this.getListado();
            })
            .catch((error) => {
              this.isActivating = false;
              document.getElementById("terminateButton").innerHTML =
                '<i class="fa-solid fa-trash text-icon-white"></i>';
              console.log(error);
              this.$toast.error(error, {
                position: "top-right",
                max: 10,
              });
            });
        });
    },
    activateContract() {
      this.isActivating = true;
      document.getElementById("activateButton").innerHTML =
        '<i class="fas fa-spinner fa-pulse"></i> Activando Contrato';
      this.$https
        .get("/acceso/getLoginToken")
        .then((response) => {
          return response.data.token;
        })
        .then((token) => {
          this.$https
            .post(
              "/ventas/UpdateVentasXtrim",
              {
                ventaId: new String(this.ventaId),
                status: "Activo",
                planId: this.selectedPlan,
              },
              {
                headers: {
                  Authorization: token,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((response) => {
              this.$toast.success(response.data.message, {
                position: "top-right",
                max: 10,
              });
              this.isActivating = false;
              document.getElementById("activateButton").innerHTML =
                "Activar Contrato";
              this.showModal = false;
              this.ventaId = null;
              this.plans = this.plans;
              this.getListado();
            })
            .catch((error) => {
              this.isActivating = false;
              document.getElementById("activateButton").innerHTML =
                "Activar Contrato";
              console.log(error);
              this.$toast.error(error, {
                position: "top-right",
                max: 10,
              });
            });
        });
    },
  },
};
</script>

<style lang="scss">
@import "datatables.net-dt";
@import "datatables.net-responsive-dt";
</style>
